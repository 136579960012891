import React, { createContext, useReducer } from "react";
import _ from "lodash";

const initialState = {
  cartCount: 0,
  logo: "",
};

const store = createContext(initialState);

const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "sitedetails":
        //console.log("action:", JSON.stringify(action), "old state:" , state)
        //const newState = action.stat;// do something with the action
        //var newState = _.extend(state, action.state);

        //console.log("theme New State:", newState);
        return {...state , "logo" : action.state.logo};

      /* case 'setcartcount' : 

      console.log("old state:", state);
      state.cartCount = action.count;
        return state; */
      default:
        return state;
      //throw new Error();
    }
  }, initialState);

  return (
    <Provider value={{ state, dispatch }}>
      <>
        {/* {console.log("state:", JSON.stringify(state))} */}
        {children}
      </>
    </Provider>
  );
};

export { store, StateProvider };
