import React, { Component, Fragment } from "react";
import {
    Grid,
    Message,
    Checkbox,
    Card,
    Loader,
    Icon,
    Dropdown,
    Accordion,
    Menu,
} from "semantic-ui-react";
import { gql } from "apollo-boost";
import { profileClientWithoutSession, client } from "../config";
import CategoryItem from "./CategoryItem";

import _ from "lodash";

import { withTranslation } from "react-i18next";
import CategoryMenu from "./CategoryMenu";


class CategoryListing extends Component {
    constructor(props) {
        super(props);
        this.t = this.props.t;

        var sortOptions = [
            //{ value: 'select', text: 'select' },
            { value: "LowToHigh", text: this.t("lowtohigh.label") },
            { value: "HighToLow", text: this.t("hightolow.label") },
        ];
        var sortOptions1 = [
            //{ value: 'select', text: 'select' },
            { value: 10, text: "10" },
            { value: 50, text: "50" },
            { value: 100, text: "100" },
            { value: 0, text: "All" },
        ];

        this.state = {
            // Result set
            products: [],
            categories: [],
            subcategories: [],
            subcategory: {},
            allbrands: [],
            allpackages: [], // weight
            allprices: [],
            activecategory: "",
            // Filters - Selected
            filteredCategories: "All",
            filteredSubCategories: "",
            filteredBrands: "",
            filteredPackages: "",

            filterProducts: [],

            tempFilterCategories: [],

            tempfilteredSubCategories: [],
            tempfilteredbrands: [],
            tempfilteredpackages: [],
            packnames: "",

            tokenId: "",
            relatedproducts: [],
            scode: "",
            sqty: "",
            sprice: "",
            mrpprice: "",
            cartCount: 0,
            isButtonDisabled: 0,
            msg: "",
            menuItems: [],
            selected1: 10,
            loading: false,
            // For side bar
            animation: "overlay",
            direction: "left",
            dimmed: false,
            visible: false,
            ActiveIndex: -1,
            isOpened: false,
            DDSelectedValue: "LowToHigh",
            DDoptions: sortOptions,
            DDoptions1: sortOptions1,
            selected: "LowToHigh",
            selected1: "5",
            mobileCategory: false
        };

        this.getCartCount = this.getCartCount.bind(this);
        this.handleClickMobile = this.handleClickMobile.bind(this);
    }

    handleClickMobile() {
        this.setState((state) => ({ isOpened: !state.isOpened }));
    }

    componentDidMount() {
      
        var category = 'All';

        //console.log("parameters", this.props.match);

        if (this.props.match && this.props.match.params.id)
            category = this.props.match.params.id;
            
        this.loadProductCategory();
            
        this.setState(  { filteredCategories: category, msg: " ", loading: true } ,
           this.loadCat
        );
       // this.setState({ filteredCategories: "All", msg: " ", loading: true });

        //console.log("Options:" + JSON.stringify(options));
        //this.getCartCount();
       
    }

    loadCat=() => {
        this.loadResults(
        this.state.filteredCategories,
        this.state.filteredSubCategories,
        this.state.filteredBrands,
        this.state.filteredPackages,
    )}


    loadProductCategory() {
        client
            .query({
                query: gql`
          {
            getProductCategories {
              name
              subcategories {
                name
              }
            }
            GetActiveBrand {
              id
              name
            }
          }
        `,
            })
            .then((result) => {
                //console.log('resp:' + JSON.stringify(result))
                this.setState({
                    categories: result.data.getProductCategories,
                    allbrands: result.data.GetActiveBrand,
                });
            })
            .catch((error) => {
                console.log("error:" + error);
            });
    }

    loadResults(
        filterCategories,
        filterSubCategories,
        filterBrands,
        filterPackages
    ) {
        // console.log('filtered categ' + filterCategories);
        // console.log('filtered sub categ' + filterSubCategories);
        // console.log('brand filter' + filterBrands);
        // console.log('weight filter' + filterPackages);
        client
            .query({
                query: gql`
          query (
            $category: String
            $subcategory: String
            $brand: String!
            $weight: String!
          ) {
            Category: getActiveproductcategory {
              id
              name
              image
            }

            GetAllProductsByCategory(
              category: $category
              subcategory: $subcategory
              brand: $brand
              weight: $weight
            ) {
              id
              name
              image
              subcategory
              code
              discountedprice
              offerpercentage
              mrpprice
              category
              subcategory
              brand
              status
              weight
              uom
            }
          }
        `,
                variables: {
                    category:   filterCategories,
                    subcategory: filterSubCategories,
                    brand: filterBrands,
                    weight: filterPackages,
                },
            })
            .then((result) => {
				const data = result.data.GetAllProductsByCategory;

                if (this.state.allpackages.length == 0) {
                    const packname = _.uniqBy(
                        result.data.GetAllProductsByCategory,
                        "weight"
                    );
                    this.setState({
                        allpackages: packname,
                    });
                }

				if(this.state.DDSelectedValue === "LowToHigh")
				{
					data.sort( (elm1, elm2) => elm1.discountedprice - elm2.discountedprice );
				}

                this.setState({ loading: false });
				this.setState({
                    products: data,
                    filterProducts: result.data.GetAllProductsByCategory
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    loadSubCategory(parentCategory) {
        client
            .query({
                query: gql`
          query ($category: String) {
            GetChildSubCategory(category: $category) {
              id
              name
            }
          }
        `,
                variables: { category: parentCategory },
            })
            .then((result) => {
                var subcategory = this.state.subcategory;
                var catg = this.state.categories;

                subcategory[parentCategory] = result.data.GetChildSubCategory;

                //   console.log('Sub category :' + JSON.stringify(subcategory))

                this.setState({ subcategory, categories: catg });
            })
            .catch((err) => {
                console.log(
                    "error : failed to load subcategory : " + parentCategory + err
                );
            });
    }
    RenderSubCategory(categoryname) {
        // console.log('categoryname' + categoryname)
        client
            .query({
                query: gql`
          query ($category: String) {
            GetChildSubCategory(category: $category) {
              id
              name
            }
          }
        `,
                variables: { category: categoryname },
            })
            .then((result) => {
                // console.log('sub' + JSON.stringify(result.data.GetChildSubCategory))
                //  this.setState({subcategories: result.data.GetChildSubCategory});
                // console.log('subcategories' + JSON.stringify(subcategories))
                {
                    /* <Card>
                             {result.data.GetChildSubCategory.map(subcategory => (
                               <Card.Content className="">
                                 <Checkbox
                                   label={subcategory.name}
                                   id={subcategory.name}
                                   onChange={this.handleSubCategoryChange}
                                 />
                               </Card.Content>
                             ))}
                           </Card> */
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    handleSubCategoryChange = (e, { checked, label }) => {
        var selectedCat = this.state.tempfilteredSubCategories;
        if (checked) selectedCat.push(label);
        else {
            selectedCat = _.remove(
                this.state.tempfilteredSubCategories,
                function (n) {
                    return n != label;
                }
            );
        }
        var categ = null;
        categ = selectedCat.join(",");
        this.setState({
            tempfilteredSubCategories: selectedCat,
            filteredSubCategories: categ,
        });

        //var categor = "All";

        //if (this.state.tempFilterCategories.length > 0)
        //categor = this.state.tempFilterCategories.join(',')

        this.loadResults(
            this.state.filteredCategories,
            categ,
            this.state.filteredBrands,
            this.state.filteredPackages
        );
    };

    HandleSubCatChange = (SelectedCat, categoryStr) =>
    {
        this.setState({
            tempfilteredSubCategories: SelectedCat,
            filteredSubCategories: categoryStr
        });

        this.loadResults(
            this.state.filteredCategories,
            categoryStr,
            this.state.filteredBrands,
            this.state.filteredPackages
        );
    };



    handleCategoryChange = (e, { checked, label }) => {
        var selectedCat = this.state.tempFilterCategories;
        if (checked) selectedCat.push(label);
        else {
            selectedCat = _.remove(
                this.state.tempFilterCategories,
                function (n) {
                    return n != label;
                }
            );
        }
        var categ = null;


        categ = selectedCat.join(",");


        if (selectedCat.length === 0)
            categ = "All";

        this.setState({
            tempFilterCategories: selectedCat,
            filteredSubCategories: categ,
        });

        this.loadResults(
            categ,
            this.state.tempFilterSubCategories,
            this.state.filteredBrands,
            this.state.filteredPackages
        );
    };

    sortbychange = (e, { value }) => {
        var prod = this.state.products;
		this.setState({DDSelectedValue: value});
        // console.log(prod.length)
        var myOrderedArray;
        if (value == "HighToLow") {
            myOrderedArray = _.sortBy(prod, ["type", "mrpprice"]).reverse();
        } else myOrderedArray = _.sortBy(prod, (o) => o.mrpprice);

        this.setState({ filterProducts: myOrderedArray });
    };

    // sortbyshow = (e, { value }) => {

    //   var newItems= [];

    //   if (value != 0) newItems = this.state.products.slice(0, value);
    //   else newItems = this.state.products;

    //   this.setState({filterProducts :  newItems})

    // }

    handleBrandChange = (e, { checked, label }) => {
        var selectedBrand = this.state.tempfilteredbrands;
        if (checked) selectedBrand.push(label);
        else {
            selectedBrand = _.remove(this.state.tempfilteredbrands, function (n) {
                return n != label;
            });
        }
        var selectedBrands = selectedBrand.join(",");
        this.setState({
            tempfilteredbrands: selectedBrand,
            filteredBrands: selectedBrands,
        });

        this.loadResults(
            this.state.filteredCategories,
            this.state.tempfilteredSubCategories.join(","),
            selectedBrands,
            this.state.filteredPackages
        );
    };
    handlePackagesChange = (e, { checked, label }) => {
        var selectedPack = this.state.tempfilteredpackages;
        if (checked) selectedPack.push(label);
        else {
            selectedPack = _.remove(this.state.tempfilteredpackages, function (n) {
                return n != label;
            });
        }
        var selectedPacks = selectedPack.join(",");
        this.setState({
            tempfilteredpackages: selectedPack,
            filteredPackages: selectedPacks,
        });

        this.loadResults(
            this.state.filteredCategories,
            this.state.filteredSubCategories,
            this.state.filteredBrands,
            selectedPacks
        );
    };
    getCartCount() {
        profileClientWithoutSession
            .query({
                query: gql`
          query {
            getOrderCount
          }
        `,
            })
            .then((res) => {
                this.onCartCountChange(res.data.getOrderCount);
            });
    }

    onCartCountChange(num) {
        //console.log("numb", num);
        this.setState({
            cartCount: num,
        });
    }

    handleCategoryHeaderClick = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndex } = this.state;
        const newIndex = activeIndex === index ? "" : index;

        this.setState({ activecategory: newIndex });
    };

    handleDimmedChange = (e, { checked }) => this.setState({ dimmed: checked });

    handleDirectionChange = (direction) => () =>
        this.setState({ direction, visible: false });

    renderCartItems = (products) => (
        <>
            {products.map((product) => (
                <CategoryItem key={product.code} product={product} />
            ))}

            {products.length == 0 && (
                <div className="noproduct">
                <Message>
                    <Message.Header />
                    <p>{this.t("noProductAvail.label")}</p>
                </Message>
                </div>
            )}
        </>
    );


    clearFilters = () =>
    {
        this.setState({
            filteredCategories: "All",
            filteredSubCategories: "",
            filteredBrands: "",
            filteredPackages: "",
            categories: [],
            loading: true
        });


        this.loadProductCategory();

        this.loadResults(
            "All",
            "",
            "",
            ""
        );
    }

    renderCategories = (activecategory)=>(
        <div className="category-accordian">
        <p className="title">Categories <span className="categoryclear"> <Icon name="delete" onClick={(e) => {

          this.clearFilters();

        }}></Icon> </span> </p>



        <div className="accord-block">
            <Accordion fluid styled>
                {this.state.categories.map((category, index) => {
                    return (
                        <Fragment key={category.name}>
                            <Accordion.Title
                                active={activecategory === category.name}
                                index={category.name}
                                onClick={this.handleCategoryHeaderClick}
                            >
                                <span className="cat-text">{category.name}</span>
                                <Icon
                                    name={
                                        activecategory === category.name
                                            ? "minus"
                                            : "plus"
                                    }
                                    className="cat_icon"
                                />
                            </Accordion.Title>

                            <Accordion.Content
                                active={activecategory === category.name}
                            >
                                {category.subcategories.map((subcat, index) => {
                                    return (
                                        <Card.Content key={subcat.name}>
                                            <Checkbox
                                                label={subcat.name}
                                                id={subcat.name}
                                                onChange={this.handleSubCategoryChange}
                                            />
                                        </Card.Content>
                                    );
                                })}
                            </Accordion.Content>
                        </Fragment>
                    );
                })}
            </Accordion>
        </div>
    </div>);

    render() {
        const { products, activecategory, isOpened, filterProducts } = this.state;


        return (
            <Grid className="no-padding-margin">
                <Grid.Row>
                    <Grid.Column
                        width={16}
                        className="no-padding-margin"
                        only="tablet computer"
                    >
                        <div className="seven-container categorylist">
                            <div className="left-block">

                                {/* {this.renderCategories(activecategory)} */}
                                <CategoryMenu 
                                    Categories={this.state.categories} 
                                    SubCatFilter={this.state.tempfilteredSubCategories} 
                                    HandleSubCategoryChange={this.HandleSubCatChange} 
                                    ClearCat={this.clearFilters}
                                />
                               
                            </div>

                            <div className="right-block">
                                {this.state.loading ? (
                                    <Grid>
                                        <Grid.Row>
                                            <Loader active inline="centered" />
                                        </Grid.Row>
                                    </Grid>
                                ) : (
                                    <div className="category-width">
                                        <Grid columns={3} padded>
                                            <div className="sorting-drop">
                                                <span className="sort-left">
                                                    <b>{this.t("sort.label")} </b>&nbsp;
                                                    <span>
                                                        <Dropdown

                                                            options={this.state.DDoptions}
                                                            defaultValue={this.state.DDSelectedValue}
                                                            onChange={this.sortbychange}
                                                        />
                                                    </span>
                                                </span>
                                                <span className="sort-right">
                                                </span>
                                            </div>
                                        </Grid>
                                        <Grid columns={4} padded>
                                            {this.renderCartItems(filterProducts)}
                                        </Grid>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>



                <Grid.Row className=" no-padding-margin">

                    <Grid.Column width={16} only="mobile" className="no-padding-margin">
                        <Grid.Row>
                            <Grid.Column mobile={16} className="no-padding-margin">
                                {this.state.loading == true && (
                                    <Grid>
                                        <Grid.Row>
                                            <Loader active inline="centered" />
                                        </Grid.Row>
                                    </Grid>
                                )}
                                {this.state.loading == false && (
                                    <>
                                        <div className="category-width">
                                            <Grid columns={2} padded>

                                                <div className="mobile-category">
                                                    <Icon size="small" name="th large" onClick={() => this.setState({ mobileCategory: !this.state.mobileCategory })} />
                                                </div>
                                                <div className="sorting-drop">
                                                    <span className="sort-left">
                                                        <b>{this.t("sort.label")} </b>&nbsp;
                                                        <span>
                                                            <Dropdown

                                                                options={this.state.DDoptions}
                                                                defaultValue={this.state.DDSelectedValue}
                                                                onChange={this.sortbychange}
                                                            />
                                                        </span>
                                                    </span>
                                                   
                                                </div>

                                               
                                            </Grid>

                                           
                            
                                            {
       
                                                this.state.mobileCategory
                                                && 
                                                <CategoryMenu 
                                                    Categories={this.state.categories} 
                                                    SubCatFilter={this.state.tempfilteredSubCategories} 
                                                    HandleSubCategoryChange={this.HandleSubCatChange} 
                                                    ClearCat={this.clearFilters}
                                                />
                                                

                                            }
                                           
                                           

                                            {/* <Grid columns={4} padded>
                                                {this.renderCartItems(products)}
                                            </Grid> */}
                                        </div>
                                        <Grid columns={2} padded>
                                            {this.renderCartItems(products)}
                                        </Grid>
                                    </>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withTranslation()(CategoryListing);
