import React from "react";
import {
    Grid,
    Image,
    Icon,
    Dropdown,
    Breadcrumb,
    Label,
    Menu
} from "semantic-ui-react";
import { gql } from "apollo-boost";
import SearchProduct from "../../Search";

import _ from "lodash";
import { withTranslation } from "react-i18next";
import { Consumer, Context } from "../../Context";


import config, { profileClientWithoutSession, client, profileclient } from "../../config";
import { Link } from "react-router-dom";

import menuIcon from "../../../resources/menu-icon.svg";

const sections = [
    {
        key: "Home",
        content: "Home",
        link: true
    }, {
        key: "PDL",
        content: "Products",
        link: true
    }
];

class TopHeader extends React.Component { // t = null;

    constructor(props) {
        super(props);

        //this.mobileCloseButton = React.createRef()

        this.state = {
            count: this.props.count ? this.props.count : 0,
            cartCount: this.props.count ? this.props.count : 0,
            noheader: this.props.noheader ? this.props.noheader : false,
            imgURL: localStorage.getItem("imgurl"),
            SiteDetails: {},
            options: [],
            UserName: "",
            menu: "Home"
        };

        this.t = this.props.t;
        this.i18n = this.props.i18n;

        // this.GetI18n = this.GetI18n.bind(this);
        // this.logout = this.logout.bind(this);
    }

    static contextType = Context;

    componentDidMount() {
        var t = localStorage.getItem("token");
        if (t != null) {
            this.getDrugCount();
            this.GetUserName();
        }
        this.GetSiteDetails();
        this.GetLanguages();
        //
    }

    GetUserName() {
        profileclient.query({
            query: gql`
				query
				{
					profile
					{
						name
					}
				}
			`}).then((result) => {
                let Name = result.data.profile.name;
                if (Name !== undefined && Name !== null) {
                    this.setState({ UserName: Name });
                }
            })
    };

    GetLanguages() {
        client.query({
            query: gql`
      query
      {
        getLanguagues {
          name
          code
        }
      }
      `}).then(result => {
                const optns = result.data.getLanguagues;

                if (optns !== undefined && optns !== null) {
                    let options = optns.map(opt => {
                        return { key: opt.code, text: opt.name, value: opt.code };
                    });


                    // console.log(options);

                    this.setState({ langOptions: options });
                }

            }).catch(err => console.log(err));
    }

    ChangeLanguage(value) {
        localStorage.setItem("langPref", value);
        this.i18n.changeLanguage(value);
        this.setState({});
    }

    getDrugCount() {
        profileClientWithoutSession.query({
            query: gql`
          query {
            getOrderCount
          }
        `}).then((res) => {
                const data = res.data.getOrderCount;
                if (data !== null && data !== undefined) {

                    this.setState({ cartCount: data });
                    this.context.SetCartCount(data);
                    // dispatch({type:"setcartcount", count : data});
                }
            }).catch((e) => {
                console.log(e);
            });
    }

    // logout() {
    // localStorage.removeItem("token");
    // window.location.href = "/login";
    // }

    cartCount(count) {
        return (
            <Label circular color='red' floating>
                <Context.Consumer>{
                    (ctx) => {
                        //console.log("tp ctx:", ctx);
                        return ctx.cartCount;
                    }

                }
                </Context.Consumer>
            </Label>
        )
    }

    GetSiteDetails() { /*theme {
							primarycolor
							secondarycolor
							bgcolor
							badgecolor
							errorcolor
							bordercolor
							linkcolor
							textcolor
							menucolor
						}
						logo
						icon
						locale
						currency
						dateformat
						title
						shortname
						description
						homepagecontent
						*/

        client.query({
            query: gql`
				query
				{
					getWebsiteDetails
					{
						name
            			businesscontactno
					}
				}
			`}).then((result) => {
                let details = result.data.getWebsiteDetails;

                // console.log("Details:", details);

                if (details !== undefined && details !== null) {
                    this.setState({ SiteDetails: details });
                }
            }).catch((error) => {
                console.log(error);
            });
    };

    HandleClick = (e, /*{ name }*/
    ) => { } // this.setState({ activeItem: name })

    closeMenu() {
        const elm = document.getElementById("menu-btn");
        elm.checked = false;
    };

    render() {
        const renderLogo = (
            <Link to="/" style={{ display: "block" }} onClick={
                (e) => this.setState({ "menu": "home" })}>
                <Image src={
                    config.apiurl + 'logo'
                }
                    className="app-logo" />
            </Link>
        );

        return (
            <Grid className="header-container">
                <Grid.Row className="header-a" only="tablet computer">

                    <Grid.Column computer={16}
                        className="top">
                        <div className="header-div seven-container ">
                            <div to="/profile" className="left-header">
                                {
                                    localStorage.getItem("token") ? <Link to="/profile">
                                        <p className='welcome-text'>
                                            <b>{
                                                this.t("welcomeuser.label")
                                            }
                                                {
                                                    this.state.UserName
                                                } </b>
                                        </p>
                                    </Link> : <Link to="/">
                                        <p className='welcome-text'>
                                            <b>{
                                                this.t("welcomeuser.label")
                                            }
                                                {
                                                    this.state.SiteDetails.name
                                                } </b>
                                        </p>
                                    </Link>
                                } </div>

                            <div className="right-header">
                                {/* <div className="vertical-splitter"></div> */}
                                {/*<div className="header-item">
									<Icon name="map marker alternate" />
									<p>{this.t("contact.label")}</p>
								</div> 
								<div className="header-item">
									<Icon name="info circle" />
									<p>{this.t("needhelp.label")}</p>
								</div>
								<div className="header-item">
									<Icon name="user outline" />
									<p>{this.t("signreg.label")}</p>
								</div> */}

                                {
                                    localStorage.getItem("token") ? <div className="header-item design-header">
                                        <Link to="/profile">
                                            <Icon name="user outline" /> {
                                                this.t("profile.label")
                                            } </Link>
                                    </div> : <Link to="/login" className="header-item">
                                        <Icon name="user outline" />
                                        <p>{
                                            this.t("signreg.label")
                                        }</p>
                                    </Link>
                                }

                                {/* <div className="vertical-splitter-2 mr-1"></div> */}

                                <div className="header-item">
                                    <Dropdown placeholder="Select Language" icon='language'
                                        defaultValue={
                                            localStorage.getItem('langPref')
                                        }
                                        options={
                                            this.state.langOptions
                                        }
                                        onChange={
                                            (e, data) => this.ChangeLanguage(data.value)
                                        } />
                                </div>
                            </div>
                        </div>
                    </Grid.Column>
                    <div className="horizontal-line1"></div>
                </Grid.Row>

                {
                    this.state.noheader ? null : <>
                        <Grid.Row className="second" only="tablet computer">
                            <Grid.Column width={16}>
                                <div className="seven-container header-block">

                                    <div className="logo-container">
                                        {renderLogo} </div>

                                    <div className="search-container">
                                        <SearchProduct />
                                    </div>

                                    <div className="third-block">

                                        {
                                            (this.state.SiteDetails.businesscontactno != null && this.state.SiteDetails.businesscontactno.length > 0) && <> {/*  <div className="vertical-splitter-2 mr-1"></div> */}

                                                <div className="item">
                                                    <Icon name="call" size="large" />
                                                    <div className="content">
                                                        <a href={
                                                            "tel:" + this.state.SiteDetails.businesscontactno
                                                        }>
                                                            <p className="text">
                                                                {
                                                                    this.t("callus.label")
                                                                }</p>
                                                            <p>{
                                                                this.state.SiteDetails.businesscontactno
                                                            }</p>
                                                        </a>
                                                    </div>
                                                </div>

                                                <div className="vertical-splitter-2 mr-1"></div>
                                            </>
                                        }
                                        {/* <div className="vertical-splitter-2 mr-1"></div> */}

                                        {/* <div className="item">
												<Icon name="heart outline" />
											</div> */}


                                        <div className="item">
                                            <div className="content mr-1">
                                                {/* <Link to={"/cart"}> */}
                                                <Link to={"/cart"} onClick={(e) => this.setState({ "menu": "" })}>
                                                    <p className="text">

                                                        {
                                                            this.t("shoppingcart.label")
                                                        }</p>
                                                </Link>
                                                {/* </Link> */}
                                                {/* <p>{Round("0.00", 2)}</p> */} </div>
                                            {/* <Icon name="shopping bag" /> */}
                                            <Link to={"/cart"} onClick={(e) => this.setState({ "menu": "" })}
                                                className="cart-icon">

                                                <Icon size="big" name="shopping bag" />  {
                                                    this.cartCount()
                                                }

                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row only="tablet computer"
                            style={
                                {
                                    padding: 0,
                                    margin: 0
                                }
                            }>
                            <Grid.Column width={16}>
                                <div className="seven-container menus">

                                    <Menu secondary>
                                        <Menu.Item onClick={
                                            (e) => this.setState({ "menu": "home" })
                                        } active={this.state.menu === 'home'} as={Link} to={"/"} >
                                            {this.t("home.label")}
                                        </Menu.Item>
                                        {/*  <Link to={"/"}
                                    className="menu-items">Categories</Link> */}
                                        <Menu.Item onClick={
                                            (e) => this.setState({ "menu": "products" })
                                        } active={this.state.menu === 'products'}
                                            as={Link} to={"/products"}
                                        >
                                            {/* <Link to={"/products"}
                                            onClick={
                                                (e) => this.setState({"menu": "products"})
                                            }
                                        > */}
                                            {this.t("products.label")}
                                            {/*  </Link> */}
                                        </Menu.Item>
                                    </Menu>
                                    {/* <Link to={"/"}
                                    className="menu-items">Special Offers</Link> */} </div>
                            </Grid.Column>
                        </Grid.Row>

                        <div className="horizontal-line1"></div>
                    </>
                }


                <Grid.Row only="mobile" className="mobile-header">
                    <div className="left-container">
                        <div className="bar-icon"></div>
                        <header class="header header-mobile">
                            <div className="one">
                                <input class="menu-btn" type="checkbox" id="menu-btn" ref={this.mobileCloseButton} />
                                <label class="menu-icon" for="menu-btn">
                                    <span class="navicon"></span>
                                </label>
                                <ul class="menu">
                                    <li>
                                        <Link to={"/"}
                                            onClick={
                                                (e) => {
                                                    this.setState({ "menu": "home" });
                                                    this.closeMenu();
                                                }
                                            }
                                            className={
                                                "menu-items " + (
                                                    this.state.menu === "home" ? "active" : ""
                                                )
                                            }>{this.t("home.label")}</Link>
                                    </li>
                                    <li>
                                        <Link to={"/products"}
                                            onClick={
                                                (e) => {
                                                    this.setState({ "menu": "products" });
                                                    this.closeMenu();
                                                }
                                            }
                                            className={
                                                "menu-items " + (
                                                    this.state.menu === "products" ? "active" : ""
                                                )
                                            }>{this.t("products.label")}</Link>
                                    </li>

                                </ul>
                            </div>
                        </header>

                        <div className="logo">
                            {renderLogo
                            }</div>
                    </div>

                    <div className="right-container">
                        <div className="profile">
                            <Link to="/profile"
                                onClick={
                                    this.HandleClick
                                }>
                                <Icon name="user outline" /> {/* <strong>{this.t("profile.label")}</strong> */} </Link>
                        </div>
                        <div className="item">

                            <Link to={"/cart"}
                                className="cart-icon">

                                <Icon size="big" name="shopping bag" /> {this.cartCount(this.state.cartCount)}

                                {/* {  <Consumer> {
                                                (ctx) => this.cartCount(ctx.cartCount)
                                            } </Consumer> }   */}
                            </Link>
                        </div>
                        <div className="header-item">
                            <Dropdown placeholder="Select Language" icon='language'
                                defaultValue={
                                    localStorage.getItem('langPref')
                                }
                                options={
                                    this.state.langOptions
                                }
                                onChange={
                                    (e, data) => this.ChangeLanguage(data.value)
                                } />
                        </div>
                    </div>
                </Grid.Row>
                {
                    this.props.SearchBar === false ? null : <Grid.Row only="mobile">
                        <Grid.Column width={16}
                            className="search-container">
                            <SearchProduct />
                            {/* <Breadcrumb className="mobile-breadcrumb">
                                <Breadcrumb.Section>
                                    <Link to="/"><Icon name="home" /></Link>
                                </Breadcrumb.Section>
                                <Breadcrumb.Divider icon="right angle"></Breadcrumb.Divider>
                                <Breadcrumb.Section>Product Listing</Breadcrumb.Section>
                            </Breadcrumb> */}
                            {
                                this.props.IsBreadcrumb ? <div className="bread-crumb">
                                    {/* <Breadcrumb icon="right angle" sections={sections} /> */}
                                    <Breadcrumb>
                                        <Breadcrumb.Section>
                                            <Link to="/"><Icon name="home" /></Link>
                                        </Breadcrumb.Section>
                                        <Breadcrumb.Divider icon="right angle"></Breadcrumb.Divider>
                                        <Breadcrumb.Section>{this.t("porductlisting.label")}</Breadcrumb.Section>
                                    </Breadcrumb>
                                </div> : null
                            } </Grid.Column>
                    </Grid.Row>
                } </Grid>
        );
    }
}

export default withTranslation()(TopHeader);
