
import React from 'react'

import { withRouter } from 'react-router-dom'


const LayoutHeader = props => {
  if (props.location.pathname === '/register') return null
  if (props.location.pathname === '/login') return null
  if (props.location.pathname === 'forgotpassword') return null
  return (
    <>
      {props.children}

    </>
  )
}

export default withRouter(LayoutHeader)
