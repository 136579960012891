import React from "react";
import { Grid, Input, Header, Icon, Button, Image, Breadcrumb, Segment } from "semantic-ui-react";
import { profileclient } from "../config";
import { Round } from "../Helpers";
import { Context } from "../Context";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import _map from "lodash/map";
import _each from "lodash/each";
import _remove from "lodash/remove";

import { GetDateFormatted, FromString, GetTimeFormatted, DateToString } from "../Helpers/date";
import gql from "graphql-tag";

import NumberInput from "semantic-ui-react-numberinput";

class ShoppingCart extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			token: localStorage.getItem("token"),
			CouponCode: "",
			imgurlprefix: localStorage.getItem("imgurl") + '/images/product/',
			deliveryscheduleslots: null,
			cartCount: 0,
			cartList: [],
			cartLength: 0,
			configdetails: null,
			transportCharge: "",
			unloadingCharge: "",
			mobilenumber: "",
			totalCharges: 0,
			shippingCharges: 0.0,
			discount: 0,
			payment: false,
			errors: {},
			copuondiscount: 0,
		};
		this.t = props.t;
	};

	static contextType = Context;

	componentDidMount() {
		this.GetCartCount();
		this.getExistingOrders();
		// this.getDeliverySlots();
		this.GetCartDetails();
	};

	GetCartCount() {
		profileclient.query({
			query: gql`
				query
				{
					getOrderCount
				}
			`,
		}).then((res) => {
			this.setState({ cartCount: res.data.getOrderCount });
			this.setCartCount(res.data.getOrderCount);
		});
	};

	getExistingOrders() {
		//newly added to check orderid exists or not for cart back btn
		profileclient
			.query({
				query: gql`
			  query {
				getFinalOrders {
				  id
				}
			  }
			`,
			})
			.then((res) => {
				if (res.data) {
					const index = res.data.getFinalOrders.findIndex(
						(obj) => obj.id === localStorage.getItem("orderid")
					);

					if (index == -1) localStorage.setItem("orderid", "");
				}
			});
		//end of
	};


	GetCartDetails() {
		profileclient.query({
			query: gql`
				query
				{
					getOrderDetails {
						_id
						name
						phonenumber
						productcode
						qty
						price
						mrpprice
						offerpercentage
						quantity
						image
						tax {
						  CGSTR
						  SGSTR
						}
						status
						weight
						uom
						cweight
					}
				}
			`
		}).then((result) => {
			const cartresult = result.data.getOrderDetails;

			this.setState({
				isLoading: false, cartList: cartresult, cartLength: cartresult.length
			});

			this.HandleItemChange(cartresult);
		}).catch(err => {
			console.log(err);
		})
	};

	HandleItemChange(cartItems, code) {
		let items = [];
		let _cItems = cartItems ? cartItems : this.state.cartList;

		items = _cItems.map(item => {
			let _temp = { productcode: item.productcode, qty: item.qty };
			return _temp;
		});

		this.HandleCalculation(this.state.couponCode, this.state.DDSelectedValue == "Yes", items);
	};

	HandleCalculation(code, unloading, items) {

		this.setState({ "couponmsg": "" });

		profileclient
			.query({
				query: gql`
			  query($order: OrderPriceCalculation) {
				ordersumcoupon(orders: $order) {
				  items {
					name
					value
					type
					ref
				  }
				  code
				  message
				}
			  }
			`,
				variables: {
					order: {
						copuoncode: code,
						items: items,
						pincode: this.state.pincode ? this.state.pincode : "",
						unloading: unloading,
					},
				},
			})
			.then((res) => {
				if (!res.data.ordersumcoupon)
					return;

				//   console.log("Coupon Calc Result");
				//   console.log(res.data.ordersumcoupon);

				const sumcalc = res.data.ordersumcoupon;

				if (sumcalc.message !== "coupon is not valid ") {
					var calculatedResult = res.data.ordersumcoupon.items;

					//console.log("Order Sum :",)

					var result = {};

					var rewardsApplied = [];
					var rewardAppliedTotal = 0;
					_map(calculatedResult, (row, i) => {
						if (row.name == "Reward") {
							rewardsApplied.push({
								name: row.type,
								value: row.value,
								ref: row.ref,
							});

							rewardAppliedTotal += row.value;
						} else {
							result[row.name] = row.value;
						}
					});

					this.setState({
						rewards: rewardsApplied,
						rewardTotal: rewardAppliedTotal,
						totalCharges: result.Total,
						shippingCharges: result.Shipping,
						discount: result.Discounted,
						copuondiscount: result.CopuonDiscount,
						transportCharge: result.Transport,
						unloadingCharge: result.Unloading,
						orderTotal: result.OrderTotal,
						weight: result.Weight,
						couponmsg: res.data.ordersumcoupon.message,
						//couponCode: res.data.ordersumcoupon,
                        couponErrMsg: ""
					});
				}
				else {
					this.setState({ couponErrMsg: this.t("invalidcoupon.label") });
				}
			});
	}

	setCartCount(number) {
		this.context.SetCartCount(number);
	};

	CheckoutProcess() {
		const items = _map(this.state.cartList, (item) => {
			return {
				productcode: item.productcode,
				qty: item.qty,
				negotiateddiscount: "0",
			};
		});

		let order = {

			items,
			referralcode: "",
			couponcode: this.state.couponCode,
			rewards: [],
			ordervalue: {
				subtotal: this.state.orderTotal.toFixed(2),
				discount: this.state.discount,
				shipping: this.state.shippingCharges,
				ordertotal: this.state.totalCharges,
				transportcharge: this.state.transportCharge,
				unloadingcharge: this.state.unloadingCharge,
			},
			delivery: this.state.deliveryschedule ? orderdeliverysch : null,
			balance: this.state.totalCharges,
		};

		this.context.SetCheckOutData(order);
		// this.props.history.push("/checkout");
		this.props.ScreenEvnt(2);
		
	};

	RemoveCartItems(id) {
		//console.log(id);
		profileclient.mutate({
			mutation: gql`
				mutation($id: String!){
					deleteSingleItem(id: $id)
				}
			`,
			variables: {
				"id": id
			}
		}).then(res => {
			if (res.data) {
				let items = this.state.cartList;

				_remove(items, (item) => {
					return item._id == id;
				});

				this.setState({
					cartLength: items.length,
					cartCount: items.length,
				});

				this.setCartCount(items.length);

				this.HandleItemChange(items);
			}
		})
	};

	HandleCount(count, index) {
		if (count !== null && count > 0) {
			// debugger;
			const crtItem = this.state.cartList;
			crtItem[index].qty = count;

			this.setState({ cartList: crtItem });
			this.HandleItemChange(crtItem);
		}
	};

	render() {
        // console.log(this.state);
        const coupDiscount = this.state.copuondiscount ? this.state.copuondiscount : 0;

		const {
			cartCount, cartList, cartLength
		} = this.state;


		return (
			<div>

				{this.state.cartLength == 0 && (
					<Segment className="text centered cardEmpty">
						{" "}
						<h4 className="text centered">{this.t("yourCartEmpty.label")}</h4>
						<span>
							{this.t("continueShopping.label")}{" "}

							<Link to="/products">{this.t("continueshopping.label")}</Link>
						</span>
					</Segment>
				)}

				{this.state.payment === false && this.state.cartLength > 0 && (
					<div>
						<Grid className="cart-container seven-container">
							<Grid.Column
								mobile={16}
								tablet={16}
								computer={16}
								className="cart-info"
							>
								<Grid>

									<Grid.Row>

										<Grid.Column width={16} style={{ padding: 0 }} >
											<Breadcrumb>
												<Breadcrumb.Section><label className="bread-label active">1.{this.t("shoppingcart.label")}</label></Breadcrumb.Section>
												<Breadcrumb.Divider icon="right angle"></Breadcrumb.Divider>
												<Breadcrumb.Section><label className="bread-label">2.{this.t("checkout.label")}</label></Breadcrumb.Section>
												<Breadcrumb.Divider icon="right angle"></Breadcrumb.Divider>
												<Breadcrumb.Section><label className="bread-label">3.{this.t("ordercomplete.label")}</label></Breadcrumb.Section>
											</Breadcrumb>
										</Grid.Column>

									</Grid.Row>

									<div className="horizontal-line only-mobile"></div>

									<Grid.Row only="computer tablet">
										<Grid.Column computer={10} tablet={10} mobile={16}>
											<Grid>
												{
													cartList && cartList.length > 0 && (
														cartList.map((cart, index) => {
															
															return (
																<React.Fragment>
																	<Grid.Row className="cart-items-web" key={index}>
																		<Grid.Column width={4}>
																			{(cart.image != null && cart.image.length > 0) &&
																				<div className="image-frame">
																					<Image width={120} src={this.state.imgurlprefix + cart.image[0]} style={{ margin: "0 auto" }} />
																				</div>
																			}
																		</Grid.Column>

																		<Grid.Column width={12} className="cart-details">
																			<Grid>
																				<Grid.Row className="cart-item-details">
																					<Grid.Column width={7}>
																						<span className="cart-name">{cart.name}</span>
																					</Grid.Column>
																					<Grid.Column width={4}>
																						<div className="cart-qnt">
																							<NumberInput
																								valueType="integer"
																								defaultValue={1}
																								minValue={1}
																								value={cart.qty}
																								size="mini"
																								onChange={(count) => this.HandleCount(count, index)}
																							/>
																						</div>
																					</Grid.Column>
																					<Grid.Column width={5}>
																						<div className="price-block">

																							<div className="price">{Round((cart.price * cart.qty), 2)}</div>

																							<div className="amount">
																								<label>MRP:</label>
																								<span className="mrp">{Round(cart.mrpprice, 2)} </span>
																								<span className="offer">({cart.offerpercentage} % {this.t("off.label")})</span>
																							</div>

																							<div className="ourprice">
																								<label>Our price</label>
																								<span className="amount">{Round(cart.price, 2)}</span>
																							</div>


																						</div>
																					</Grid.Column>
																				</Grid.Row>
																			</Grid>

																		</Grid.Column>
																	</Grid.Row>
																	<div className="cart-item-delete">
																		<div className="icons-item">
																			<span onClick={() => this.RemoveCartItems(cart._id)}>
																				Remove
																				<Icon name="delete" />
																			</span>
																		</div>
																		<div className="horizontal-line1"></div>
																	</div>
																</React.Fragment>
															);
														})
													)
												}
											</Grid>
										</Grid.Column>

										<Grid.Column computer={6} tablet={6} mobile={16} className="order-summary-web">
											<div className="summary-container">
												<Header as="h3" className="order-header">
													{this.t("Summery.label")}
												</Header>

												<div className="horizontal-line1"></div>

												<Grid>

													<Grid.Row className="order-total">
														<Grid.Column width={10} className="key">
															{this.t("total.label")}
														</Grid.Column>

														<Grid.Column width={6} className="value ">
															<span>
																{Round(this.state.orderTotal, 2)}{" "}
															</span>
														</Grid.Column>
													</Grid.Row>

                                                    <span style={{ color: "#aab0c1" }} >{this.t("applycoupon.label")}</span>

													<Grid.Row className="coupon-line">
														<Grid.Column width={10}>
															<div className="coupon-field">
																<Input value={this.state.Coupon} onChange={(e, { value }) => this.setState({ couponCode: value })} placeholder="Coupon Code" />
																<div className="coupon-icon" onClick={() => this.HandleItemChange(null, this.state.couponCode)}>
																	<Icon name="chevron right" />
																</div>
															</div>
															<span className="indiv-error-message">{this.state.couponErrMsg}</span>
														</Grid.Column>
														<Grid.Column width={6}>
															<div className="coupon-amount">{Round(this.state.copuondiscount, 2)}</div>
														</Grid.Column>
													</Grid.Row>


													<div className="horizontal-line"></div>


                                                    <Grid.Row className="order-total">
                                                        <Grid.Column width={10} className="key">
                                                            {" "}
                                                            {this.t("yourSaving.label")}
                                                        </Grid.Column>
                                                        <Grid.Column width={6} className="value">
                                                            {Round(
                                                                this.state.discount + this.state.rewardTotal + coupDiscount,
                                                                2
                                                            )}
                                                        </Grid.Column>
                                                    </Grid.Row>

                                                    <Grid.Row className="summary-block mtop-20">
														<Grid.Column width={10} className="key order-tot-key">
															{this.t("orderTotal.label")}
														</Grid.Column>
														<Grid.Column width={6} className="value order-tot-value">
															{Round(this.state.totalCharges, 2)}
														</Grid.Column>
													</Grid.Row>


												</Grid>

												<div className="checkout-btn">
													<Button animated onClick={this.CheckoutProcess.bind(this)}>
														<Button.Content visible>{this.t("checkout.label")}</Button.Content>
														<Button.Content hidden>
															<Icon name='arrow right' />{this.t("checkout.label")}
														</Button.Content>
													</Button>
												</div>

											</div>

										</Grid.Column>
									</Grid.Row>

									{
										cartList && cartList.length > 0 && (
											cartList.map((cart, index) => {
												return (
													<Grid.Row className="cart-items" key={index} only="mobile">
														{/* <div className=""> */}
														<Grid.Column computer={8} tablet={8} mobile={8}>
															{(cart.image != null && cart.image.length > 0) &&
																<div className="image-frame">
																	<Image width={120} src={this.state.imgurlprefix + cart.image[0]} style={{ margin: "0 auto" }} />
																</div>
															}
														</Grid.Column>

														<Grid.Column computer={8} tablet={8} mobile={8}>

															<div className="cart-detail">
																<span className="cart-name">{cart.name}</span>

																<div className="cart-qnt">
																	<NumberInput
																		valueType="integer"
																		defaultValue={1}
																		minValue={1}
																		value={cart.qty}
																		size="mini"
																		onChange={(count) => {
																			cart.qty = count;
																			this.setState({});
																		}}
																	/>
																</div>

																<div className="price">{Round(cart.price, 2)}</div>

																<div className="amount">
																	<label>MRP:</label>
																	<span className="mrp">{Round(cart.mrpprice, 2)} </span>
																	<span className="offer">({cart.offerpercentage}% {this.t("off.label")})</span>
																</div>
															</div>

														</Grid.Column>
														{/* </div> */}

													</Grid.Row>
												);
											})
										)
									}


								</Grid>
							</Grid.Column>

							<Grid.Column
								mobile={16}
								className="cart-summary"
								only="mobile"
							>
								{/* <Segment> */}
								<Grid only="mobile">

									<Grid.Row className="order-summary">

										<Grid.Column width={16} className="order-block">
											<Header as="h3" className="order-header">
												{this.t("orderSummery.label")}
											</Header>

											<div className="horizontal-line1"></div>



											<Grid className="info">
												<Grid.Row className="order-total">
													<Grid.Column width={10} className="key">
														{this.t("total.label")}
													</Grid.Column>

													<Grid.Column width={6} className="value ">
														<span>
															{Round(this.state.orderTotal, 2)}{" "}
														</span>
													</Grid.Column>

												</Grid.Row>

                                                <Grid.Row>
													<Grid.Column width={10}>
														<div className="coupon-field">
															<Input value={this.state.couponCode} onChange={(e, { value }) => this.setState({ couponCode: value })} placeholder="Coupon Code" />
															<div className="coupon-icon" onClick={() => this.HandleItemChange(null, this.state.couponCode)}>
																<Icon name="chevron right" />
															</div>
														</div>
													</Grid.Column>
													<Grid.Column width={6}>
														<div className="coupon-amount">{Round(this.state.copuondiscount, 2)}</div>
													</Grid.Column>
												</Grid.Row>

                                                <div className="horizontal-line"></div>

												<Grid.Row className="order-total">
													<Grid.Column width={10} className="key">
														{" "}
														{this.t("yourSaving.label")}
													</Grid.Column>
													<Grid.Column width={6} className="value">
														{Round(
															this.state.discount + this.state.rewardTotal + coupDiscount,
															2
														)}
													</Grid.Column>
												</Grid.Row>


												<Grid.Row className="summary-block">
													<Grid.Column width={10} className="key">
														{this.t("orderTotal.label")}
													</Grid.Column>
													<Grid.Column width={6} className="value">
														{Round(this.state.totalCharges, 2)}
													</Grid.Column>
												</Grid.Row>

												<Grid.Row>
													<Grid.Column width={16}>

													</Grid.Column>
												</Grid.Row>

											</Grid>
											{
												<div>
													<Button animated onClick={this.CheckoutProcess.bind(this)}>
														<Button.Content visible>{this.t("checkout.label")}</Button.Content>
														<Button.Content hidden>
															<Icon name='arrow right' />{this.t("checkout.label")}
														</Button.Content>
													</Button>
												</div>
											}
										</Grid.Column>
									</Grid.Row>
									<span className="general-message">
										{this.state.errors["OrderAdded"]}
									</span>
									<span className="indiv-error-message">
										{this.state.errors["OrderFailed"]}
									</span>
								</Grid>
							</Grid.Column>
						</Grid>
					</div>
				)}
				{this.state.payment == true && (
					<>
						<Payment orderId={this.state.orderId} />
					</>
				)}
			</div>
		);
	};

};

export default withTranslation()(ShoppingCart);