import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Responsive } from 'semantic-ui-react'

import NavbarChildren from './NavbarChildren'
import NavbarDesktop from './NavbarDesktop'
import NavbarMobile from './NavbarMobile'


import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { Link,withRouter } from 'react-router-dom'

import _ from 'lodash';
import { gql } from 'apollo-boost';


import {config} from '../../config';


const client = new ApolloClient({
    link: ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.map(({ message, locations, path }) =>
                    console.log(
                        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                    ),
                );
            if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        new HttpLink({
            uri: config.apiurl + 'api/',
            credentials: 'same-origin'
        })
    ]),
    cache: new InMemoryCache()
});

export default class NavBar extends Component {
  static propTypes = {
    children: PropTypes.node,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    //uploadButton: PropTypes.arrayOf(PropTypes.object),
  }

  state = {
    visible: false
    ,
    menuItems : this.props.menuItems,

    uploadButton : [
      {
        as: 'a',
        content: 'Upload Prescription',
        href: '/upload',
        icon: 'file alternate outline',
        className: 'upload'
        },
    ]
  
  };

  componentDidMount()
{

  //console.log("Menu items:" + JSON.stringify( this.props.menuItems ))
    /* client.query({
        query: gql`   {
            getAllCategories{
              name,
              id
           
            }
          }`
    }).then(result => {
      const mappedCat = result.data.getAllCategories.map(ans => ({ ...ans, key: ans.id }));
      const catlist =mappedCat.map(cl =>({
            "as": 'a',
            "content": cl.name,
            "href": '/Products/'+ cl.name
      }) );

    //console.log("after");
   // console.log(catlist);

    this.setState({menuItems : catlist});
      // this.setState({ catresults: mapcats })
    }).catch(e => {
        console.log(e);
    }) */
}


  

  handlePusher = () => {
    const { visible } = this.state

    if (visible) this.setState({ visible: false })
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  render() {
    const { children } = this.props
    const { visible, uploadButton } = this.state

    return (
      <div>
        <Responsive {...Responsive.onlyMobile}>
          <NavbarMobile
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            menuItems={this.props.menuItems}
            uploadButton={uploadButton}
            visible={visible}
          >
            <NavbarChildren>{children}</NavbarChildren>
          </NavbarMobile>
        </Responsive>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <NavbarDesktop menuItems={this.props.menuItems} uploadButton={uploadButton} />
          <NavbarChildren>{children}</NavbarChildren>
        </Responsive>
      </div>
    )
  }
}

