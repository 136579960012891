import React, { Component } from 'react'
import { Grid, Header, Table, List } from 'semantic-ui-react'
import gql from 'graphql-tag'
import _map from 'lodash/map'
import { profileclient, config } from '../../config'
import { Round } from '../../Helpers'
import { withTranslation } from 'react-i18next'

class OrderDetail extends Component {
  constructor (props) {
    super(props)

    this.state = {
      item: this.props.item,
      token: localStorage.getItem('token'),
      files: []
    }

    this.t = this.props.t

    console.log('Id :' + JSON.stringify(this.state.item))
  }

  componentDidMount () {

    // var pres = this.state.item.prescription;
    // if ( pres && pres.id )
    // {
    // 		profileclient.query({
    // 			query: gql`
    // 				query ($Id:String){
    // 				getPrescriptionFilesById(id:$Id)
    // 						{
    // 							name,
    // 							path
    // 					}
    // 				}`,
    // 				"variables":{
    // 					"Id" : pres.id
    // 				}
    // 	}).then(res=>{

    // 		this.setState({"files": res.data.getPrescriptionFilesById});

    // 	})

    //	}
  }

  renderPrescriptionsList () {
    const prescriptionList = _map(this.state.files, file => {
      return <List.Item><a href={config.apiurl + `download?file=${file.path}&token=${this.state.token}`} target="_blank">{file.name}</a></List.Item>
    })

    return prescriptionList
  }

  renderRewardsList () {
    const rewardsList = _map(this.state.item.rewards, reward => {
      return <Table.Row>
        <Table.Cell>

        </Table.Cell>

        <Table.Cell>

        </Table.Cell>
        <Table.Cell>

        </Table.Cell>
        <Table.Cell>

        </Table.Cell>

        <Table.Cell textAlign='right'>
          {reward.name} points(-)
        </Table.Cell>
        <Table.Cell textAlign='right'>
          {Round(reward.value, 2)}
        </Table.Cell>

      </Table.Row>
    })

    return rewardsList
  }

  renderOrderList () {
    var num = 1
    const addressList = _map(this.state.item.items, item => {
      return <Table.Row>
        <Table.Cell textAlign='center'> {num++}
          {/* <Image src={ productSmallImage } className='tiny' /> */}
        </Table.Cell>
        <Table.Cell>
          <p className="product">
          </p>
          <p className="company">  {this.t('productName.label')}: {item.productname} , {this.t('productCode.label')} : {item.productcode}</p>
        </Table.Cell>
        <Table.Cell textAlign='right'><p> {Round(item.mrpprice, 2)}</p> {/* <p> Offer Price Rs. {Round(item.price,2)}</p> */} </Table.Cell>
        <Table.Cell textAlign='right'><p>{Round(item.price, 2)}</p> {/* <p> Offer Price Rs. {Round(item.price,2)}</p> */} </Table.Cell>
        <Table.Cell textAlign='right'>{item.qty}</Table.Cell>
        <Table.Cell textAlign='right'><span className="item-total">{this.t('currency.label')} {Round((item.price * item.qty), 2)}</span></Table.Cell>
      </Table.Row>
    })

    return addressList
  }

  render () {
    const item = this.state.item
    return (

		 	<Grid className='order-detail'>
        <Header as='h2'>
          <span className="order-no">{this.t('ordernum.label')} : {item.number}</span>
          <span className="status">{item.status}</span>
          {item.status == 'Payment' && <a className="ui positive button" href={'/Payment/' + item.id}>{this.t('makePayment.label')}</a>}

          <a style={{ float: 'right', fontSize: 12 }} onClick={this.props.showOrderDetail}>{this.t('back.label')}</a>
        </Header>
        <Grid.Row className='info'>
          <Grid.Column width={8} className='contact'>
            <div className="delivery-address">
              <strong>{this.t('deliveryAddress')}</strong>
              <span>{item.shipto.Line1 && <span>{item.shipto.Line1},</span>}
                {item.shipto.Line2 && <span>{item.shipto.Line2},</span>}
                <br />{item.shipto.Street && <span>{item.shipto.Street},</span>}
                {item.shipto.Area && <span>{item.shipto.Area},</span>}
                <br />{item.shipto.LandMark && <span>{item.shipto.LandMark},</span>}
                <br />{item.shipto.City}
                <br />{item.shipto.State}
                <br />{item.shipto.PinCode} </span>
            </div>
            {item.courier && <div>
              <strong>{this.t('courier.label')}</strong>
              <br />
              <span>{item.courier}</span>
            </div>}
          </Grid.Column>
          <Grid.Column width={8} className='basic right aligned'>
            <div className="order-value">{this.t('currency.label')} {Round(item.ordervalue.ordertotal, 2)}</div>
            {(item.status == 'OutForDelivery' || item.status == 'Delivered') && <div className="invoice"><a href={config.apiurl + 'profileinvoice?token=' + localStorage.getItem('token') + '&id=' + item.id} target="_blank" >{this.t('downloadInvoice.label')}</a></div>}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className='data'>
          <Table>
            <Table.Header>
              <Table.Row textAlign='center'>
                <Table.HeaderCell width={1}>{this.t('serialNumber.label')}</Table.HeaderCell>
                <Table.HeaderCell width={3}>{this.t('product.label')}</Table.HeaderCell>
                <Table.HeaderCell textAlign='right' width={3}>{this.t('mrp.label')} ({this.t('currency.label')})</Table.HeaderCell>
                <Table.HeaderCell textAlign='right' width={3}>{this.t('offerprice.label')}({this.t('currency.label')})</Table.HeaderCell>
                <Table.HeaderCell textAlign='right' width={2}>{this.t('quantity.label')}</Table.HeaderCell>
                <Table.HeaderCell textAlign='right' width={3}>{this.t('totalCost.label')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>

              {this.renderOrderList()}
              {this.renderRewardsList()}

            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell />
                <Table.HeaderCell textAlign='right' />
                <Table.HeaderCell textAlign='right' />
                <Table.HeaderCell textAlign='right'><strong>{this.t('total.label')}({this.t('currency.label')})</strong></Table.HeaderCell>
                <Table.HeaderCell textAlign='right'><span className="order-total">{this.t('currency.label')}  {Round(item.ordervalue.ordertotal, 2)}</span></Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>

          {this.state.files.length > 0 && <div>
            <strong>Prescription(s)</strong>
            <List ordered>
              {this.renderPrescriptionsList()}
            </List>

          </div>}

        </Grid.Row>

      </Grid>

    )
  }
}

export default withTranslation()(OrderDetail)
