import moment from 'moment'
import dayjs from "dayjs";


var format = null
export function DateFromString (date) {
    return dayjs(date);
}
export function GetDateFormatted (dateString) {

    var date = DateFromString(dateString);
    if ( !format )
        format = localStorage.getItem("dateformat") //+ " hh:mm:ss";

   return date.format(format);
}

export function GetTimeFormatted (dateString) {
  var date = DateFromString(dateString)
  return date.format('hh:mm A')
}

export function FromString (value, format) {
  //console.log('Format:', format, 'Date:', value)
  return moment(value, format)
}

export function DateToString (date) {
  return date ? moment(date).toISOString() : null
}

export function ReportWebDataRocksFormat () {
  // console.log("Date", localStorage.getItem("dateformat").replace("DD", "dd").replace("YYYY", "yyyy").replace("mm", "MM") );

  // console.log("Date", localStorage.getItem("dateformat").replace("dd", "DD").replace("yyyy", "YYYY").replace("mm", "MM"));
  return localStorage.getItem('dateformat').replace('dd', 'DD').replace('yyyy', 'YYYY').replace('mm', 'MM')
}

export function GetReportDateFormatted (dateString) {
  var date = DateFromString(dateString)

  /* if ( !format )
    format = localStorage.getItem("dateformat") //+ " hh:mm:ss"; */

  // var reportFormat = format.replaceAll("/", "-")
  // return date.format(format);
  return date.format('YYYY-MM-DD')
}
