import React, { Component } from 'react'

import { gql } from 'apollo-boost'

import CategoryListing from '../Categories/CategoryListing'
// import {Sidebar, Menu ,Icon,divider} from 'semantic-ui-react'

import { client } from '../config'
export default class Home extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isUploadImgModalOpen: false,
      menuItems: [],
      banners: [],
      title: ''

    }
  }

  componentDidMount () {
    client.query({
      query: gql`
            {
            getimagePrefixURL,
          
            }`
    }).then(resp => {
      localStorage.setItem('imgurl', resp.data.getimagePrefixURL)
    })
      .catch(e => {
        console.log('error:', e)
      })

    this.GetWebsiteDetails()
  }

  GetWebsiteDetails () {
    client.query({
      query: gql`
            query
            {
                getWebsiteDetails {
                    logo
                    icon
                    locale
                    currency
                    dateformat
                    title
                    name
                    shortname
                    description
                }
            }
          `
    }).then((result) => {
      const details = result.data.getWebsiteDetails
      if (details !== 0 && details !== null && details !== '') {
        this.setState({ Title: details.title })
        document.getElementById('sitetitle').innerText = details.title
      }
    }).catch(err => console.log(err))
  }

  render () {
    return <div>
      <CategoryListing></CategoryListing>
    </div>
  }
}
