/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import Detail from './CompDetail'

import { withRouter } from "react-router-dom";

class ProductDetail extends Component {
  constructor (props) {
    super(props)
    // eslint-disable-next-line react/prop-types
    const match = props.match

    this.state = {
      // eslint-disable-next-line react/prop-types
      matchcode: match.params.id
    }
  }

  componentDidMount () {
    this.setState({ matchcode: this.props.match.params.id })
  }

  render () {
    const { matchcode } = this.state
    /*  let detailscomp

    detailscomp = <Detail matchcode={matchcode} /> */

    return <Detail matchcode={matchcode} />
  }
}

export default withRouter(ProductDetail);