import React from 'react'
import {
  Grid,
  Header,
  Button,
  Form,
  Image
} from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom'
import gql from 'graphql-tag'
import config, { accountClient, client } from '../../config'

import { triggerPushSubscribe } from '../../Helpers/sendsubscribe'

import { withTranslation } from 'react-i18next'

class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
      imgURL: localStorage.getItem('imgurl'),
      msgValidate: '',
      SiteDetails: {}
    }

    this.t = this.props.t
  }

  componentDidMount () {
    this.GetSiteDetails()
  }

  // eslint-disable-next-line no-tabs
  GetSiteDetails () {
    client.query({
      query: gql`
				query
				
				{
					getWebsiteDetails
					{
						theme {
							primarycolor
							secondarycolor
							bgcolor
							badgecolor
							errorcolor
							bordercolor
							linkcolor
							textcolor
							menucolor
						}
						logo
						icon
						locale
						currency
						dateformat
						title
						name
						shortname
						description
						homepagecontent
    					businesscontactno
					}
				}
			`
    }).then((result) => {
      const details = result.data.getWebsiteDetails
      if (details !== undefined && details !== null) {
        this.setState({ SiteDetails: details })
      }
    }).catch((error) => {
      // console.log(error)
      this.setState({ msgValidate: this.t('somethingWrong.error') })
    })
  }

  handleValidation () {
    const fields = this.state.fields
    const errors = {}
    let formIsValid = true

    if (!fields.password || fields.password.trim().length === 0) {
      formIsValid = false
      errors.password = this.t('error.login.password')
    } else if (fields.password.trim().length < 6) {
      formIsValid = false
      errors.password = this.t('error.login.passwordLength')
    }

    // Mobile
    if (!fields.phone || fields.phone.trim().length === 0) {
      formIsValid = false
      errors.phone = this.t('error.login.mobileNumber')
    } else {
      var phoneno = /^\d{10}$/
      if (!phoneno.test(fields.phone)) {
        formIsValid = false
        errors.phone = this.t('error.login.mobileNumberInvalid')
      }
    }
    this.setState({ errors: errors })
    return formIsValid
  }

  handleChange (field, e) {
    const fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
  }

  loginform (e) {
    e.preventDefault()
    this.setState({ msgValidate: '' })
    if (this.handleValidation()) {
      this.loginTest()
    } else {
      // alert("Form has errors.")
    }
  }

  loginTest () {
    accountClient
      .mutate({
        mutation: gql`
          mutation($signin: SignInInput) {
            signIn(customer: $signin) {
              id
              number
              verified,
              payload
            }
          }
        `,
        variables: {
          signin: {
            phonenumber: this.state.fields.phone,
            password: this.state.fields.password
          }
        }
      })
      .then(t => {
        if (t.data.signIn.verified === 'false') {
          localStorage.setItem('mobNum', this.state.fields.phone)
          localStorage.setItem('payload', t.data.signIn.payload)
          this.props.history.push('/verify')
          return
        }

        localStorage.setItem('token', t.data.signIn.id)

        // initalize push subscription

        triggerPushSubscribe()

        // debugger;

        var path = '/'

        const redirectPath = localStorage.getItem('path')

        if (redirectPath) {
          const url = window.location.href
            .replace('/Login', '')
            .replace('/login', '')

          // console.log('url : ' + url)

          const urlPart = redirectPath.replace(url, '')

          path = urlPart

          localStorage.removeItem('path')

          // this.props.history.push(urlPart);
        }

        // debugger;
        // eslint-disable-next-line react/prop-types
        this.props.history.push(path)
        // setTimeout(() => this.props.history.push(path), 2000)
      })
      .catch(e => {
        // var err = e.graphQLErrors
        // var errs = ''
        // if (err && (err != null) && (err.length > 0)) {
        //   // debugger;
        //   for (var i = 0; i < err.length; i++) {
        //     errs += err[i].message
        //   }
        // }
        // this.setState({ msgValidate: errs })

        // debugger;
        const error = e.graphQLErrors[0].message

        if (error !== null && error !== undefined && (error.includes('Invalid User name or password') || error.includes('is not matching'))) {
          this.setState({ msgValidate: this.t('invalidUsername.error') })
        } else {
          this.setState({ msgValidate: this.t('somethingWrong.error') })
        }
      })
  }

  render () {
    const renderLogo = (
      <Link to="/">
        <Image src={
          config.apiurl + 'logo'
        }
        className="login-logo" />
      </Link>
    )
    return (
      <div>
        {/* <TopHeader noheader="true" SearchBar="false"></TopHeader> */}
        <Grid centered style={{ height: '100%' }} className='account-container'>
          <Grid.Row className="login-left-right">
            <Grid.Column columns={8} className="loginDiv">
              {renderLogo}
            </Grid.Column>
            <Grid.Column columns={8}
              className='section login loginDiv  login-border'
              style={{ backgroundColor: '#ffffff' }}
            >
              <div className='error-message'> {this.state.msgValidate} </div>

              <Form
                size='large'
                name='loginform'
                onSubmit={this.loginform.bind(this)}
                style={{ fontFamily: 'roboto_medium' }}
              >
                <Header
                  as='h2'
                  className='ui header'
                  style={{ color: '#1C3448' }}
                >
                  {this.t('login.label')}{' '}
                </Header>
                <p className='indiv-error-message'>
                  {this.state.errors.phone}
                </p>
                <Form.Input
                  placeholder={this.t('mobileNumber.label')}
                  refs='phone'
                  // label={this.t('mobileNumber.label')}
                  icon='user'
                  className='required'
                  iconPosition='left'
                  onChange={this.handleChange.bind(this, 'phone')}
                  value={this.state.fields.phone}
                />

                <p className='indiv-error-message'>
                  {this.state.errors.password}
                </p>
                <Form.Input
                  placeholder={this.t('password.label')}
                  refs='password'
                  icon='lock'
                  // label={this.t('password.label')}
                  iconPosition='left'
                  type='password'
                  className='required'
                  onChange={this.handleChange.bind(this, 'password')}
                  value={this.state.fields.password}
                />
                <Button
                  fluid
                  size='large'
                  type='submit'
                >
                  {this.t('login.label')}
                </Button>
                <Grid className='actions'>
                  <Grid.Column className='left floated'>&nbsp;
                    <Link to={'/forgotpassword'}>
                      {this.t('forgotPassword.label')}?
                    </Link>
                  </Grid.Column>
                </Grid>

                <div className='text center aligned footer'>
                  {this.t('newto.label')} {this.state.SiteDetails.title} ? {' '}
                  <Link to={'/register'}>{this.t('createAccount')}</Link>
                </div>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}
export default withTranslation()(withRouter(Login))
