import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { Container, Image, Menu } from 'semantic-ui-react'

const NavbarDesktop = ({ menuItems, uploadButton }) => {
  var mapcats = []

  if (menuItems && menuItems.length > 0) { mapcats = _.slice(menuItems, 0, 6) }

  return (

    <Menu className='main-menu'>
  	<Container>
        {_.map(mapcats, item => <Menu.Item {...item} />)}

        {/*  <Menu.Menu position='right' className='upload-container'>
        { _.map(uploadButton, item => <Menu.Item {...item} />)}
      </Menu.Menu> */}
      </Container>
    </Menu>
  )
}

NavbarDesktop.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  uploadButton: PropTypes.arrayOf(PropTypes.object)
}

export default NavbarDesktop
