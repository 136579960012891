import React, { Component } from 'react'
import { Form, Button, Grid, Segment, Header,Image, Message } from 'semantic-ui-react'
import ApolloClient from 'apollo-boost'
import gql from 'graphql-tag'
import { Link, withRouter } from 'react-router-dom'

import TopHeader from '../../Home/TopHeader'
import Footer from '../../Home/Footer'
import { config, profileclient, client, profileClientWithoutSession, accountClient } from '../../config'
import { withTranslation } from 'react-i18next'

import ReCAPTCHA from "react-google-recaptcha";

class ForgotPassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
      msgValidate: '',
      captchakey: "",
      Recaptcha: ""
    }

    this.t = this.props.t
  }

  componentDidMount()
  {
    client.query({
        query: gql`
        {getCaptchaKey}
        `,
      }).then(resp=>{
  
        this.setState({captchakey : resp.data.getCaptchaKey});
  
      }).catch(err=>{})
  };

  handleValidation () {
    const fields = this.state.fields
    const errors = {}
    let formIsValid = true

    // Mobile
    if (!fields.phone || fields.phone.trim().length == 0) {
      formIsValid = false
      errors.phone = this.t('error.login.mobileNumber')
    } else {
      var phoneno = /^\d{10}$/
      if (!phoneno.test(fields.phone)) {
        formIsValid = false
        errors.phone = this.t('error.login.mobileNumberInvalid')
      }
    }
    this.setState({ errors: errors })
    return formIsValid
  }

  handleChange (field, e) {
    const fields = this.state.fields
    fields[field] = e.target.value
    this.setState({ fields })
  }

  forgotPwdform (e) {
    e.preventDefault()
    if (this.handleValidation()) {
      this.generateForgotPwd()
    } else {
      // alert("Form has errors.")
    }
  }

  generateForgotPwd () {

    console.log(this.state.Recaptcha);

    accountClient.mutate({
      mutation: gql`
          mutation($forgotPwd:ForgotPasswordInput)
          {
           forgotPwd(forgot:$forgotPwd)
          
         }
     
          `,
      variables: {
        forgotPwd: {
          phonenumber: this.state.fields.phone,
          ReCaptcha: this.state.Recaptcha
        }
      }
    })
      .then(t => {
        const phoneNum = this.state.fields.phone
        localStorage.setItem('payload', t.data.forgotPwd)
        localStorage.setItem('phonenumber', phoneNum)
        this.props.history.push('/forgotpasswordverify')
      }).catch(e => {
        var err = e.graphQLErrors
        var errs = ''
        if (err != null & err.length > 0) {
          // debugger;
          for (var i = 0; i < err.length; i++) {
            errs += err[i].message
          }
        }
        this.setState({ msgValidate: errs })
      })
  }

  handleRecapcha = (value) => 
  {
    //   console.log(value);
    //   console.log(typeof(value));
    //   this.setState({ Recaptcha: value });
    this.setState({Recaptcha: value});
  }

  render () {

    console.log(this.state);
    const renderLogo = (
      <Link to="/">
        <Image src={
          config.apiurl + 'logo'
        }
        className="verify-logo"/>
      </Link>
    )
    return (
      <div className='forgot-password'>
        {/* <TopHeader noheader="true"></TopHeader> */}
        <Grid centered style={{ height: '100%' }} className='account-container forgot'>
        <Grid.Row  className="login-left-right">
				<Grid.Column columns={8} className="loginDiv">
					{renderLogo}
				</Grid.Column>
          <Grid.Column  columns={8} className="loginDiv section login login-border">
            <div className="top-error">
              <p className="error-message">
              {this.state.msgValidate}
              </p>
            </div>
            <Form size='large' name="loginform" onSubmit={this.forgotPwdform.bind(this)} style={{ fontFamily: 'roboto_medium' }}>
              <Header as='h2' className='ui header forgot-header' style={{ color: '#1C3448' }} >{this.t('forgotPassword.label')} </Header>
              <span className="indiv-error-message">{this.state.errors.phone}</span>
              <Form.Input placeholder={this.t('mobileNumber.label')}
                refs="phone"
                // label={this.t('mobileNumber.label')}
                className="required"
                onChange={this.handleChange.bind(this, 'phone')}
                value={this.state.fields.phone}
              />

                {
                    this.state.captchakey.length > 0
                    ?
                        <ReCAPTCHA
                            sitekey={this.state.captchakey}
                            onChange={this.handleRecapcha}
                        />
                    :
                        null
                }

              <Button fluid size='large'
                style={{ backgroundColor: '#669900', color: '#FFFFFF' }} type="submit">
                {this.t('continue.label')}
              </Button>

            </Form>
          </Grid.Column>
          </Grid.Row>
        </Grid>
        {/* <Footer></Footer> */}
      </div>
    )
  }
}
export default withTranslation()(withRouter(ForgotPassword))
