import Script from 'react-load-script'

import React, {Component} from "react";
import {
    Segment,
    Header,
    Form,
    Radio,
    Button,
    Message,
    Dimmer,
    Loader
} from 'semantic-ui-react'
import {profileclient} from "../config";
import {gql} from 'apollo-boost';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";

class Payment extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderId: this.props.orderId,
            scriptLoaded: false,
            paymentOption: 'cod',
            paymentReq: null,
            orderSuccess: false,
            paymenttype: [],
            loading: true,
            paymentOption: null
        }

        this.t = this.props.t;
        // console.log("orderid:"+ this.props.orderId);
        // console.log("orderid:"+ this.state.orderId);
        this.getPaymentOptions();
    }

    getPaymentOptions() {


        profileclient.query({query: gql `query{
		getPaymentOptions
	  }`}).then(resp => {
			//   console.log(resp);
            this.setState({"paymenttype": resp.data.getPaymentOptions, loading:false})
        }).catch(err => console.log("err:", err));
    }

    handleChange = (e, {value}) => { // console.log("Payment:" + value);
        

        this.setState({paymentOption: value});

        if (value === "onliner") {


            if ( this.state.paymentOption === null)

            {
                            this.setState({"loading":true});


            

            
            profileclient.query({
                query: gql `query ($orderId:String, $providerName:String)
          {
            getPaymentRequest(orderId: $orderId,provider:$providerName) {
              key
              txnid
              amount
              productinfo
              firstname
              email
              phone
			  name
              logo
              theme
            }
          
          }`,
                variables: {
                    "orderId": this.state.orderId,
                    "providerName": "Razorpay"
                }

            }).then(res => {

                this.setState({paymentReq: res.data.getPaymentRequest, loading: false});
            });
            }
        }
    }

    render() {
        return <div id="paymentarea">
            {
            this.state.orderSuccess == false && <Segment>
                <Header as='h2'>
                    {
                    this.t("selectPaymentMethod.label")
                }</Header>
                <Form> {
                    this.state.paymenttype.map(item => <> {
                        item === "Razorpay" && <Form.Field>
                            <Radio label={
                                    this.t("onlinePayment.label")
                                }
                                name='payment'
                                value='onliner'
                                checked={
                                    this.state.paymentOption === 'onliner'
                                }
                                onChange={
                                    this.handleChange
                                }/>
                        </Form.Field>
                    }
                        {
                        item === "COD" && <Form.Field>
                            <Radio label={
                                    this.t("cashOnDelivery.label")
                                }
                                name='payment'
                                value='cod'
                                checked={
                                    this.state.paymentOption === 'cod'
                                }
                                onChange={
                                    this.handleChange
                                }/>
                        </Form.Field>
                    } </>)
                }
                    <div className='aligned payment-area-btn'>
                        <Button type='submit'
                            onClick={
                                this.handlePayment
                        }>
                            {
                            this.t("proceed.label")
                        }</Button>
                    </div>
                </Form>
            </Segment>
        }

            <Dimmer active={
                    this.state.loading
                }
                inverted>
                <Loader inverted>
                    {
                   this.t("loading.label")
                } </Loader>
            </Dimmer>

            {
            this.state.orderSuccess && <Message>
                <Message.Header>{
                    this.t("orderedSuccessfully.label")
                }</Message.Header>
                <p> {
                    this.t("orderSuccessMessage.label")
                }
                    {/* <a href="/">{this.t("home.label")}</a> <a href="/profile">{this.t("profile.label")}</a> */}
                   <p  className="payment-home"> <Link to="/">
                        {
                        this.t("home.label")
                    } </Link><Link to="profile" className="payment-profile">
                        {
                        this.t("profile.label")
                }</Link></p>
                </p>
            </Message>
        }


            <Script attributes={
                    {id: "razorpay"}
                }
                url="https://checkout.razorpay.com/v1/checkout.js"
                onCreate={
                    this.handleScriptCreate.bind(this)
                }
                onError={
                    this.handleScriptError.bind(this)
                }
                onLoad={
                    this.handleScriptLoad.bind(this)
                }/>
        </div>
    }

    handlePayment = () => { // console.log("Payment type:" + this.state.paymentOption);
        if (this.state.paymentOption == "onliner") {
            this.handleRazor();
        } else if (this.state.paymentOption == "cod") {
            this.handleCOD();
        }
        // localStorage.setItem("orderid", "");

    }

    handleScriptCreate() {
        this.setState({scriptLoaded: false})
    }


    handleScriptError() {
        this.setState({scriptError: true})
    }

    handleScriptLoad() {
        this.setState({scriptLoaded: true})

        // console.log("loaded");


    }

    handleRazor() {
        var requestData = this.state.paymentReq;

        // console.log(requestData);

        var currency = localStorage.getItem("currency").split('/')[1];

        // console.log("Currency:", currency);

        var handle = this;

        var options = {
            "key": requestData.key, // Enter the Key ID generated from the Dashboard
            "amount": requestData.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": currency,
            "name": requestData.name,
            // "description": "Test Transaction",
            "image": localStorage.getItem("imgurl") + "/logos" + requestData.logo,
            "order_id": requestData.txnid, // This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature)

                handle.handleUpdatePayment({
                    "status": true,
                    "mode": "online",
                    "paymentOption": "online",
                    "provider": "Razorpay",
                    "paymentref": JSON.stringify(response),
                    "comments": "",
                    "paymentStatus": "Paid"
                });
            },
            "prefill": {
                "name": requestData.firstname,
                "email": requestData.email,
                "contact": requestData.mobile
            },
            /*  "notes": {
                "address": "Razorpay Corporate Office"
            }, */
            "theme": {
                "color": requestData.theme
            }
        };

        var rzor = new Razorpay(options);

        rzor.on('payment.failed', function (response) {
            handle.updateOrderPayment({
                "status": false,
                "mode": "online",
                "provider": "Razorpay",
                "paymentOption": "online",
                "paymentref": JSON.stringify(response),
                "comments": "",
                "paymentStatus": "Failed"
            })
        });
        rzor.open();

    }

    handleUpdatePayment(paymentinfo) {
        profileclient.mutate({
            mutation: gql `
          mutation ($orderId:UpdateOrderPayment)
            { 
	            updateOrderPayment(order: $orderId)
            }`,
            variables: {
                "orderId": {
                    "id": this.state.orderId,
                    "type": paymentinfo.paymentOption,
                    "status": paymentinfo.status,
                    "mode": paymentinfo.mode,
                    "paymentref": paymentinfo.paymentref,
                    "comments": "",
                    "paymentStatus": paymentinfo.paymentStatus,
                    "provider": paymentinfo.provider
                }

            }
        }).then(res => { // console.log(res);
            this.setState({
                orderSuccess: res.data.updateOrderPayment.length > 0
            })


        }).catch(e => {

            //console.log(e);
        });
    }

    handleCOD() {

        this.handleUpdatePayment({
            "status": true,
            "mode": "",
            "paymentOption": "COD",
            "paymentref": "",
            "comments": "",
            "paymentStatus": "Pending"
        });

    }

}

export default withTranslation()(Payment);
