import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n.use(Backend).use(initReactI18next)

// const defaultLang = navigator.language;

let defaultLang = "";
const langPref = localStorage.getItem("langPref");

if(langPref !== undefined && langPref !== null)
    defaultLang = langPref;
else
    defaultLang = navigator.language;



i18n.init({
  lng: defaultLang,
  backend: {
    loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
  },
  fallbackLng: 'en-US',
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  },
  react: {
    wait: true
  }
})

export default i18n
