import React from "react";

export const Context = React.createContext();

class AppContextProvider extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            cartCount: 0,
            CheckOutAddress: {},
            CheckOutData: {},
            SetCartCount: this.SetCount,
            SetCheckOutAddress: this.SetCheckOutAddress,
            SetCheckOutData: this.SetCheckOutData,
        };
    }

    SetCount = (value) =>
    {
            this.setState({cartCount: value});
     
    }

	

    SetCheckOutAddress = (address) => {
        this.setState({CheckOutAddress: address});
    }

    SetCheckOutData = (data) => {
        this.setState({CheckOutData: data});
    }

    render()
    {
        return(
            <Context.Provider value={this.state}>
                {this.props.children}
            </Context.Provider>
        )
    }
};

export default AppContextProvider;

export const Consumer = Context.Consumer;
