import { profileclient } from '../config'
import gql from 'graphql-tag'

export function checkTriggerPushSubscribe () {
  if (localStorage.getItem('token')) triggerPushSubscribe()
}

export function triggerPushSubscribe () {
  var id = null

  var sub = null
  // debugger;

  if (sessionStorage) {
    id = sessionStorage.getItem('id')

    try {
      sub = JSON.parse(sessionStorage.getItem('sub'))
    } catch (e) {
      console.log(e)
    }
  }

  if (id == null) {
    var fid = new window.Fingerprint().get()
    sessionStorage.setItem('id', fid)
  }

  if (sub) {
    var subInp = {
      number: sessionStorage.getItem('id'),
      subscription: sub
    }

    // console.log("sub", subInp);

    profileclient
      .mutate({
        mutation: gql`
          mutation($push: SubscribeInput) {
            pushNotificationSubscribe(sub: $push)
          }
        `,
        variables: {
          push: subInp
        }
      })
      .then((resp) => console.log('resp', resp))
      .catch((err) => console.log('error', err))
  }
}

export function triggerPushUnSubscribe (callback) {
  var id = null

  var sub = null

  if (sessionStorage) id = sessionStorage.getItem('id')

  if (id == null) {
    callback && callback()
    return
  }

  var subInp = {
    number: id
    // subscription: sub
  }

  // console.log("sub", subInp);

  profileclient
    .mutate({
      mutation: gql`
        mutation($push: UnSubscribeInput) {
          pushNotificationUnSubscribe(sub: $push)
        }
      `,
      variables: {
        push: subInp
      }
    })
    .then((resp) => {
      console.log('resp', resp)
      callback & callback()
    })
    .catch((err) => {
      console.log('error', err)
      callback & callback()
    })
}
