
import React, { Component } from 'react'
import { Segment } from 'semantic-ui-react'
import Payment from './index'

export default class OrderPayment extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor (props) {
    super(props)

    console.log('OrderId:', this.props.match.params.id)
  }

  render () {
    return (
      <div>
        <Segment>
          {/*  <TopHeader/> */}
          <Payment orderId={this.props.match.params.id} />
          {/*       <Footer/> */}
        </Segment>
      </div>
    )
  }
}
