/* eslint-disable no-unused-vars */
import React, { Component } from 'react'

import { Grid, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

import _ from 'lodash'

import { withTranslation } from 'react-i18next'
import { client } from '../../config'
import { gql } from 'apollo-boost'
import DOMPurify from 'dompurify'
class Footer extends Component { /* static propTypes = {
    //children: PropTypes.node,
    menuItems: PropTypes.arrayOf(PropTypes.object),
    //uploadButton: PropTypes.arrayOf(PropTypes.object),
    } */
  constructor (props) {
    super(props)

    // console.log("Categories:"+ JSON.stringify(this.props.menuItems))

    this.state = {
      Categories: [],
      content: null
    }

    this.t = this.props.t
    this.GetContent('footer')
  }

  GetContent (name) {
    client.query({
      query: gql`
            
            query {
                getSiteContentByName(name:"${name}"){
                    id
                    name,
                    content
                }  
            }
            `
    }).then(result => {
      const cont = result.data.getSiteContentByName

      this.setState({ content: cont })
    })
  }

  componentDidMount () {
    // this.timerID = setInterval(
    // () => {
    // console.log(this.props.menuItems);
    // if (this.props.menuItems.length>0){
    // //debugger;
    // this.setState({Categories : this.props.menuItems});
    // clearInterval(this.timerID);
    // }},
    // 1000
    // );
  }

  render () {
    //console.log(this.state.content)
    return (
      <Grid className="footer-container">

        <Grid.Row>
          <Grid.Column width={16}>
            <div className="footer-content">
              {this.state.content && this.state.content.length > 0 && <div dangerouslySetInnerHTML={
                {
                  __html: DOMPurify.sanitize(this.state.content[0].content, {
                    USE_PROFILES: {
                      html: true
                    }
                  })
                }
              }></div>}
            </div>
          </Grid.Column>
        </Grid.Row>

        {/* <Grid.Row className="two" only="tablet computer"> */}
        <Grid.Row className="two">
          <Grid.Column width={16}
            className="copyright">
            <p> {
              this.t('copyrights.header')
            }
                            &copy; {
              new Date().getFullYear() + ' ' + this.t('copyRights.label')
            }, {' '}
            {/* <br /> */}
            {
              this.t('rights.label')
            } </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default withTranslation()(Footer)
