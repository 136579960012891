import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'

// const url = 'http://localhost:8090/'

const url = 'https://api.eeasytrade.net/'

export const config = {
  apiurl: url,
  morecnt: 5
}

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  }
}

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    new HttpLink({
      uri: config.apiurl + 'api/',
      // uri: 'http://localhost:8080/api/',
      credentials: 'same-origin'
    })
  ]),
  name: 'web',
  version: '1.0',
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
})

export const accountClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    new HttpLink({
      uri: config.apiurl + 'account/',
      // uri: 'http://localhost:8080/api/',
      credentials: 'same-origin'
    })
  ]),
  cache: new InMemoryCache()
})

const prolink = new HttpLink({
  // uri: 'http://localhost:8080/profile',
  uri: config.apiurl + 'profile',
  credentials: 'same-origin'
})
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authentication: token || null
    }
  }
})

export const profileclient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }

      // this for handling token expired
      if (
        networkError &&
        networkError.statusCode &&
        networkError.statusCode == 401
      ) {
        // console.log('url :' + window.location);
        localStorage.setItem('path', window.location.href)

        localStorage.removeItem('token')

        window.location.href = window.location.origin + '/login'
      }
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    authLink.concat(prolink)
  ]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
})

export const profileClientWithoutSession = new ApolloClient({
  link: authLink.concat(prolink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
})

export default config
