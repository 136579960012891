import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import App from './App'

import { init } from '../src/components/Helpers/pushnotification'

import { StateProvider } from './store'

const render = (Component) => {
  ReactDOM.render(

    <AppContainer>
      <StateProvider>
        <Component />
      </StateProvider>
    </AppContainer>,

    document.getElementById('root')
  )
}

render(App)

init()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', () => { render(App) })
}
