export function Round (number, decimalplaces) {
  if (!number) { number = 0.00 }

  var currency = localStorage.getItem('currency')

  if (currency) {
    var localescurrency = currency.split('/')
    return number.toLocaleString(localescurrency[0], {
      maximumFractionDigits: decimalplaces,
      style: 'currency',
      currency: localescurrency[1]
    })
  }

  return number.toLocaleString('en-IN', {
    maximumFractionDigits: decimalplaces,
    style: 'currency',
    currency: 'INR'
  })
}

export function DownloadFile (dataurl, filename) {
  var a = document.createElement('a')
  a.href = window.URL.createObjectURL(dataurl)
  a.setAttribute('download', filename)
  a.target = '_blank'
  a.click()
  return false
}

export function isLoggedIn () {
  var token = localStorage.getItem('token')

  if (!token) {
    window.location.url = '/login'
  }
}
