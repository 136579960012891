import React, { useState, useEffect, useContext } from "react";
import { withTranslation } from "react-i18next";
import { profileclient, config, client } from "../../config";
import {
    Grid, Dropdown, Button, Icon, Form, Input, Modal, Loader, Image, Accordion
} from "semantic-ui-react";
import noimg from "resources/no-image.png";

import gql from "graphql-tag";

import { Round, DownloadFile } from "../../Helpers";

import { GetDateFormatted } from "../../Helpers/date";
import NumberInput from "semantic-ui-react-numberinput";
import { Link } from "react-router-dom";
import { Context } from "../../Context";
import { Popup } from "semantic-ui-react";
import OrderItem from "./OrderItems";

const ClosedOrders = ["Delivered", "Returned", "Cancelled"];

const ReturnOrderFlags = ["OutForDelivery", "Delivered", "Cancelled", "Returned", "ReturnInit", "ReturnAssigned", "ReturnPickup", "ReturnCompleted", "ReturnAccepted"];

const OutDeliveredFlags = ["OutForDelivery", "Delivered", "Returned"];

const ReasonOptions = [
    { text: "Damaged", value: "Damaged" },
    { text: "Wrong Item", value: "WrongItem" },
    { text: "Other", value: "Other" }
];


const ReturnStatusOptions = [
    {
        id: "Placed",
        value: "Placed",
        text: "Placed",
        title: "Placed"
    },
    {
        id: "Accepted",
        value: "Accepted",
        text: "Accepted",
        title: "Accepted"
    },
    {
        id: "Processing",
        value: "Processing",
        text: "Processing",
        title: "Processing"
    },
    {
        id: "OutForDelivery",
        value: "OutForDelivery",
        text: "Out For Delivery",
        title: "Delivery"
    },
    {
        id: "Delivered",
        value: "Delivered",
        text: "Delivered",
        title: "Delivered"
    },

    {
        id: "ReturnInit",
        value: "ReturnInit",
        text: "Initiated",
        title: "Initiated"
    },
    {
        id: "ReturnAssigned",
        value: "ReturnAssigned",
        text: "Assigned",
        title: "Assigned"
    },
    {
        id: "ReturnPickup",
        value: "ReturnPickup",
        text: "Picked Up",
        title: "Picked Up"
    },
    {
        id: "ReturnAccepted",
        value: "ReturnAccepted",
        text: "Accepted",
        title: "Accepted"
    }, {
        id: "ReturnCompleted",
        value: "ReturnCompleted",
        text: "Completed",
        title: "Completed"
    },
];

const ReturnDateStatus = [
    {
        id: "ReturnAssigned",
        value: "ReturnAssigned",
        text: "Assigned",
        title: "Assigned"
    },
    {
        id: "ReturnPickup",
        value: "ReturnPickup",
        text: "Picked Up",
        title: "Picked Up"
    },
    {
        id: "ReturnAccepted",
        value: "ReturnAccepted",
        text: "Accepted",
        title: "Accepted"
    }, {
        id: "ReturnCompleted",
        value: "ReturnCompleted",
        text: "Completed",
        title: "Completed"
    },
];

const ReturnStatusObjet = {
    ReturnInit: "ReturnInit",
    ReturnAssigned: "ReturnAssigned",
    ReturnPickup: "ReturnPickup",
    ReturnAccepted: "ReturnAccepted",
    ReturnCompleted: "ReturnCompleted"
};

const OrderStatusObjet = {
    Placed: "Placed",
    Accepted: "Accepted",
    Processing: "Processing",
    Delivered: "Delivered",
    Canceled: "Canceled"
};


function Orders(props) {
    const [_Orders, setOrders] = useState([]);
    const [MsgValidate, setMsgValidate] = useState("");
    const [UpdateCounter, setUpdateCounter] = useState(0);
    const [ModalState, setModalState] = useState(false);
    const [MOrder, setMOrder] = useState(null);
    const [MItem, setMItem] = useState(null);
    const [MReplace, setMReplace] = useState(null);
    const [IsLoading, setLoading] = useState(false);
    const [ReturnType, setReturnType] = useState("N/A");

    const [HolderName, setHolderName] = useState("");
    const [BankName, setBankName] = useState("");
    const [BankBranch, setBankBranch] = useState("");
    const [BankNumber, setBankNumber] = useState("");
    const [BankIfsc, setBankIfsc] = useState("");
    const [Dummy, setDummy] = useState(null);
    const [IsClicked, setClicked] = useState(false);

    const t = props.t;
    const imgPrefix = localStorage.getItem("imgurl") + "/images/product";
    const context = useContext(Context);

    useEffect(() => {
        GetBankDetails();
        GetWebsiteDetails();
        GetOrders("All");
    }, []);

    function GetBankDetails() {
        profileclient.query({
            query: gql`
                query
                {
                    profile
                    {
                        bankdetails
                        {
                            name
                            holdername
                            ifsccode
                            branch
                            number
                        }
                    }
                }
            `
        }).then((res) => {
            const data = res.data.profile.bankdetails;
            if (data) {
                setHolderName(data.holdername);
                setBankName(data.name);
                setBankBranch(data.branch);
                setBankNumber(data.number);
                setBankIfsc(data.ifsccode);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    function GetWebsiteDetails() {
        client.query({
            query: gql`
                query
                {
                    getWebsiteDetails
                    {
                      title
                      return
                    }
                }
            `
        }).then((res) => {
            const data = res.data.getWebsiteDetails;
            setReturnType(data.return);
        }).catch(console.log);
    };

    function GetOrders(type) {
        setLoading(true);
        profileclient.query({
            query: gql`
                query($PaymentStatus: String) {
                    GetOrdersByPayment(PaymentStatus: $PaymentStatus) {
                        id
                        number
                        status
                        orderdate
                        delivereddate
						courier
                        payment
                        {
                            status
                            paymenttype
                            referenceid
                            {
                                billid
                                amount
                                PaymentDate
                            }
                            return
                            balance
                            credit
                        }

                        shipto
                        {
                            Id
                            Name
                            Line1
                            Line2
                            Area
                            Street
                            LandMark
                            City
                            State
                            PinCode
                        }

                        ordervalue {
                            subtotal
                            discount
                            shipping
                            ordertotal
                            return
                            transportcharge
                            unloadingcharge
                            refund
                        }

                        items {
                            id
                            productcode
                            productname
                            qty
                            image
                            status
                            return
                            mrpprice
                            price
                            negotiateddiscount
                        }

                        returnOrders {
                            id
                            parent
                            number
                            status
                            courier
                            ordertype
                            ordervalue {
                                subtotal
                                discount
                                shipping
                                ordertotal
                                return
                                transportcharge
                                unloadingcharge
                                refund
                            }
                            accepteddate
                            orderdate
                            processingdate
                            canceldate
                            returndate
                            pickupdate
                            rejecteddate
                            assigneddate
                            processingdate
                            returninitdate
                            returneddate
                            deliverydate
                            delivereddate
                            items
                            {
                                id
                                productcode
                                productname
                                reason
                                qty
                                mrpprice
                                price
                                negotiateddiscount
                                image
                                status
                                return
                            }
                        }


                        replaceOrders
                        {
                            id
                            number
                            status
                            orderdate
                            deliverydate
                            delivereddate
                            accepteddate
                            processingdate
                            canceldate
                            items {
                                id
                                productcode
                                productname
                                reason
                                qty
                                image
                                status
                                return
                                mrpprice
                                price
                                negotiateddiscount
                            }
                            payment
                            {
                            paymenttype
                            }
                        }

                        return
                        {
                            status
                            items
                            {
                                id
                                productcode
                                productname
                                qty
                                mrpprice
                                price
                                negotiateddiscount
                                image
                                status
                            }
                        }
                        
                    }
                }
            `,
            variables:
            {
                PaymenStatus: type
            }
        }).then((result) => {
            let orders = result.data.GetOrdersByPayment;
            orders.reverse();
            // console.log([orders[0], orders[1], orders[2], orders[3]].map(ord => ord));

            if (orders && orders.length > 0) {
                orders.map(or => {
                    // Accordion Index
                    or.activeIndex = -1;

                    // Processing Address for popupIsReturnAndItem
                    const addr = or.shipto;
                    or.address = addr.Name + " " + addr.PinCode;
                    or.popup = addr.Name + "," + addr.Line1 + (addr.Line2 ? ", " + addr.Line2 : " ") + (addr.Street ? ", " +
                        addr.Street : " ") + (addr.Area ? ", " + addr.Area : "") +
                        ", " + addr.City + ", " + addr.State + " - " + addr.PinCode;

                    // Calcualting return items with items
                    if (or.items && or.items.length > 0) {
                        or.items.map((item, index) => {
                            if (or.return && or.return.items && or.return.items.length > 0) {
                                const Return = or.return.items.find(ri => ri.productcode === item.productcode);

                                if (Return) {
                                    item.r_qty = Return.qty >= item.qty ? Return.qty - item.qty : item.qty - Return.qty;
                                }
                                else {
                                    item.r_qty = item.qty;
                                }
                            }
                            else {
                                item.r_qty = item.qty;
                            }
                        });
                    }

                    const r_replace = or.returnOrders.filter(rord => rord.ordertype === "replace" && rord.status !== "ReturnCompleted");

                    if (or.replaceOrders && or.replaceOrders.length > 0) {
                        or.replaceOrders.map(rpl => {
                            rpl.items.map(itm => itm.r_qty = itm.qty);
                        });

                        if (r_replace && r_replace.length > 0) {
                            r_replace.map(rr => {
                                rr.btnVisible = true;
                                or.replaceOrders.push(rr);
                            });
                        }
                    }
                    else if (r_replace && r_replace.length > 0) {
                        const r_Replace = r_replace.map(rr => {
                            rr.btnVisible = true;
                            return rr;
                        });

                        or.replaceOrders = r_Replace;
                    }

                });
                setOrders(orders);
            }

            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);;
        })
    };



    function IsClosedOrder(status, options) {
        for (let i = 0; i < options.length; i++) {
            if (options[i] === status) {
                return true;
            }
        }
        return false;
    };

    function CancelOrder(data) {
        profileclient.mutate({
            mutation: gql`
                mutation($ID: String)
                {
                    cancelOrder(id: $ID)
                }
            `,
            variables:
            {
                ID: data.id
            }
        }).then((res) => {
            setMsgValidate(t("order.label") + " " + data.number + " " + t("cancelled.label"));
            setTimeout(() => this.setState({ msgValidate: "" }), 3000);
            GetOrders("All");
        }).catch(err => console.log(err));
    };

    function handleInvoiceClick(url, number) {
        setClicked(true);
        fetch(url).then((resp) => resp.blob()).then((blob) => {
            DownloadFile(blob, `${number}-invoice.pdf`);
        });
        setTimeout(() => {
            setClicked(false);
        }, 3000);
    };

    function ReturnItems(orderId, paymentType, item, replace) {

        let Bank_Name = "", Bank_Holder_Name = "", Bank_Number = "", Bank_Branch = "", Bank_Ifsc = "";



        if (paymentType === "COD") {
            Bank_Holder_Name = HolderName;
            Bank_Name = BankName;
            Bank_Branch = BankBranch;
            Bank_Number = BankNumber;
            Bank_Ifsc = BankIfsc;
        }

        const items = [
            {
                productcode: item.productcode,
                qty: item.r_qty,
                mrpprice: item.mrpprice,
                price: item.price,
                negotiateddiscount: item.negotiateddiscount,
                reason: item.reason === "Other" ? item.reason + "-" + item.other_reason : item.reason,
                replace: replace !== undefined && replace !== null && replace !== false ? true : false
            }
        ];

        profileclient.mutate({
            mutation: gql`
                mutation($RETURN:ReturnRequestItem){
                    returnRequest(return:$RETURN)
                }
            `,
            variables:
            {
                RETURN: {
                    order: orderId,
                    items: items,
                    bankname: Bank_Name,
                    branch: Bank_Branch,
                    bankacnumber: Bank_Number,
                    ifsccode: Bank_Ifsc,
                    bankholdername: Bank_Holder_Name
                }
            }
        }).then((res) => {
            // console.log(res);
            GetOrders("All");
        }).catch(err => {
            let errormsg = err.graphQLErrors[0].message

            ErrorMsgProcessing(MOrder, MItem, MReplace, errormsg);

            setTimeout(() => ErrorMsgProcessing(MOrder, MItem, MReplace, null), 3000);
        });
    };

    function ErrorMsgProcessing(mOrder, mItem, mReplace, ErrorMsg) {
        let orders = [];
        if (_Orders && _Orders.length > 0) {
            if (mReplace === true) {
                let _isParent = _Orders.find(or => or.id === mOrder.id);
                if (_isParent) {
                    orders = _Orders.map(or => {
                        or.items.map(itm => {
                            if (itm.id === mItem.id) {
                                itm.error = ErrorMsg;
                                return itm;
                            }
                        })
                        return or;
                    })
                }
                else {
                    orders = _Orders.map(or => {
                        or.replaceOrders.map(rep => {
                            if (rep.id === mOrder.id)
                                rep.items[0].error = ErrorMsg;
                            return rep;
                        });
                        return or;
                    })
                }
            }
        }
        setOrders(orders);
    };

    function CalculateRemainingDays(deliveredDate, returnDays) {
        if ((deliveredDate !== undefined && deliveredDate !== null && deliveredDate !== "") && (returnDays !== undefined && returnDays !== null && returnDays !== "")) {
            const datearr = deliveredDate.split("T")[0].split("-");
            const Ddate = new Date(datearr[0], datearr[1] - 1, datearr[2]);
            const TodayDate = new Date();
            const diff = Math.trunc((TodayDate - Ddate) / (1000 * 60 * 60 * 24)) + 1;
            if (diff >= 0)
                return parseInt(returnDays) - diff;
        }

        return -1;
    };

    function IsReturnDays(deliveredDate, returnDays) {
        const remaining = CalculateRemainingDays(deliveredDate, returnDays);

        return remaining >= 0;
    };

    function AddToCart(item) {
        if (item) {
            profileclient.mutate({
                mutation: gql`
                    mutation($ITEM: CartInput)
                    {
                        insertCart(cart: $ITEM)
                    }
                `,
                variables:
                {
                    ITEM: {
                        productcode: item.productcode,
                        qty: 1.0,
                        price: parseFloat(item.price),
                        mrpprice: parseFloat(item.mrpprice)
                    }
                }
            }).then(resp => {
                context.SetCartCount(resp.data.insertCart);
            });
        }
    };

    function RenderMobileOrders(order) {
        return (
            <Grid.Row only="mobile tablet" style={{ marginBottom: "0.625rem" }}>
                <Grid.Column width={16}>
                    <div className="order_block_mobile">
                        <div className="header">
                            <div className="dtls">
                                <p>{t("orderplaced.label")}</p>
                                <span>{order.orderdate ? GetDateFormatted(order.orderdate) : "N/A"}</span>
                            </div>

                            <div className="dtls">
                                <p>{t("total.label")}</p>
                                <span>{Round(order.ordervalue.ordertotal, 2)}</span>
                            </div>

                            <div className="dtls">
                                <p>{t("status.label")}</p>
                                <span>{GetStausText(order.status)}</span>
                            </div>

                            <div className="dtls">
                                <Popup content={order.popup} trigger={
                                    <div className="pop-add">
                                        {order.address}
                                        <Icon name="angle down" />
                                    </div>
                                } />
                            </div>

                            <div className="dtls">
                                <span className="order-label">{t("order.label")} # {order.number}</span>
                            </div>

                            <div className="dtls">
                                {!IsClicked ? (
                                    (order.status === "OutForDelivery" || order.status === "Delivered" || order.status === "Returned")
                                    &&
                                    <div className="invoice" style={{ textAlign: "center", cursor: "pointer" }}>
                                        {t("invoice.label")}
                                        <Icon name='download'
                                            onClick={
                                                (e) => handleInvoiceClick(config.apiurl + "profileinvoice?token=" + localStorage.getItem("token") + "&id=" + order.id, order.number)
                                            }></Icon>
                                    </div>
                                ) : (
                                    <div className="invoice" style={{ textAlign: "center" }}>
                                        <Loader size='mini' active inline inverted />
                                    </div>
                                )}

                                {/* {
                                    (order.status === "OutForDelivery" || order.status === "Delivered" || order.status === "Returned")
                                    &&
                                    <div className="invoice" style={{ textAlign: "center" }}>
                                        Invoice
                                        <Icon name='download'
                                            onClick={
                                                (e) => handleInvoiceClick(config.apiurl + "profileinvoice?token=" + localStorage.getItem("token") + "&id=" + order.id, order.number)
                                            }></Icon>
                                    </div>
                                } */}
                            </div>
                        </div>

                        <OrderItem order={order} type="Items" cancelOrder={CancelOrder}
                            modalState={ModalFunc} returnType={ReturnType}
                        />

                    </div>
                </Grid.Column>
            </Grid.Row>
        );
    };

    function GetStausText(status) {
        if (status !== undefined && status !== null && status !== "") {
            const val = ReturnStatusOptions.find(rtn => rtn.value === status);

            return val ? val.text : "-";
        }

        return "-";
    };

    function DisplayDate(order) {
        if (order.orderdate) {
            return GetDateFormatted(order.orderdate);
        }
        else {
            return "N/A";
        }
    };

    function ModalFunc(order, item, modal, replace) {
        // console.log("Model state : ", order, item, modal, replace);
        setMOrder(order);
        setMItem(item);
        setMReplace(replace)
        setModalState(modal);
    };

    function HandleAccClick(order, titleProps) {
        const index = titleProps.index;
        order.activeIndex = order.activeIndex === index ? -1 : index;
        setDummy({});
    };

    function GetDateByStatus(order) {
        if (order.status === ReturnStatusObjet.ReturnAssigned) {
            return order.assigneddate;
        }
    }

    function GetOrderDateByStatus(order) {
        if (order.status === OrderStatusObjet.Accepted) {
            return order.accepteddate;
        }
        else if (order.status === OrderStatusObjet.Processing) {
            return order.processingdate;
        }
        else if (order.status === OrderStatusObjet.Delivered) {
            return order.delivereddate;
        }
        else if (order.status === OrderStatusObjet.Canceled) {
            return order.canceldate;
        }
        else {
            return "N/A";
        }
    }

    function RenderReturOrders(order) {
        const returnOrders = order.returnOrders;
        let renderReturn = [];

        if (returnOrders && returnOrders.length > 0) {
            returnOrders.forEach((rOrd, index) => {
                if (rOrd.ordertype === "return" && rOrd.status !== "ReturnCompleted") {
                    renderReturn.push(
                        <Grid.Row only="computer" key={index}>
                            <Grid.Column width={16}>
                                <div className="item-blocks">

                                    <div className="ib-header">
                                        <div className="dtls">
                                            <span className="order-label">{t("order.label")} # {rOrd.number}</span>
                                        </div>

                                        <div className="dtls">
                                            <p>Requested:</p>
                                            <span>{GetDateFormatted(rOrd.returninitdate)}</span>
                                        </div>

                                        <div className="dtls">
                                            <p>Date:</p>
                                            <span>{GetDateFormatted(GetDateByStatus(rOrd))}</span>
                                        </div>

                                        <div className="dtls">
                                            <p>{t("status.label")}</p>
                                            <span>{GetStausText(rOrd.status)}</span>
                                        </div>
                                    </div>

                                    <OrderItem order={rOrd} key={index} type="Return" readonly />

                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    );
                }

            });
        }
        return renderReturn;
    };

    function RenderReplaceOrders(order) {
        const replaceOrders = order.replaceOrders;
        if (replaceOrders && replaceOrders.length > 0) {
            return replaceOrders.map((rOrd, index) => {
                return (
                    <Grid.Row only="computer" key={index}>
                        <Grid.Column width={16}>
                            <div className="item-blocks">

                                <div className="ib-header">
                                    <div className="dtls">
                                        <span className="order-label">{t("order.label")} # {rOrd.number}</span>
                                    </div>

                                    <div className="dtls">
                                        <p>Date:</p>
                                        {/* <span>{GetDateFormatted(rOrd.orderdate)}</span> */}
                                        <span>{GetDateFormatted(GetDateByStatus(rOrd))}</span>
                                    </div>

                                    <div className="dtls">
                                        <p>{t("status.label")}</p>
                                        <span>{GetStausText(rOrd.status)}</span>
                                    </div>

                                    {
                                        GetOrderDateByStatus(rOrd) !== "N/A" ?
                                            <div className="dtls">
                                                <p>Date:</p>
                                                <span>{GetDateFormatted(GetOrderDateByStatus(rOrd))}</span>
                                            </div>
                                            :
                                            null
                                    }

                                </div>

                                <OrderItem order={rOrd} key={index} type="Replace" readonly={rOrd.btnVisible} modalState={ModalFunc} returnType={ReturnType} />

                            </div>
                        </Grid.Column>
                    </Grid.Row>
                );
            });
        }

        return t("noorder.label");
    };

    return (
        <div className="profile_order">
            <div style={{ marginBottom: 45, alignItems: "center" }}>
                <div className="error-message" style={{ color: "red" }}>
                    {MsgValidate}
                </div>
            </div>
            {
                IsLoading ? (
                    <Grid>
                        <Grid.Row>
                            <Loader active inline="centered" />
                        </Grid.Row>
                    </Grid>
                ) :
                    (
                        <Form>
                            <Grid>
                                {
                                    _Orders && _Orders.length > 0 ?
                                        _Orders.map(((order, index) => {

                                            return (
                                                <React.Fragment key={index}>
                                                    <>
                                                        <Grid.Row only="computer">
                                                            <Grid.Column width={16}>

                                                                <div className="order_block">
                                                                    <div className="header">
                                                                        <div className="left-block">
                                                                            <div className="dtls">
                                                                                <p>{t("orderplaced.label")}</p>
                                                                                <span>{DisplayDate(order)}</span>
                                                                            </div>

                                                                            <div className="dtls">
                                                                                <p>{t("total.label")}</p>
                                                                                <span>{Round(order.ordervalue.ordertotal, 2)}</span>
                                                                            </div>

                                                                            <div className="dtls">
                                                                                <p>{t("status.label")}</p>
                                                                                <span>{GetStausText(order.status)}</span>
                                                                            </div>

                                                                            <div className="dtls">
                                                                                <Popup content={order.popup} trigger={
                                                                                    <div className="pop-add">
                                                                                        {order.address}
                                                                                        <Icon name="angle down" />
                                                                                    </div>
                                                                                } />
                                                                            </div>

                                                                            {
                                                                                order.courier && (
                                                                                    <div className="dtls">
                                                                                        <p>{t("courier.label")}</p>
                                                                                        <span>{order.courier}</span>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>

                                                                        <div className="right-block">
                                                                            <div className="dtls">
                                                                                <span className="order-label">{t("order.label")} # {order.number}</span>
                                                                            </div>

                                                                            <div className="dtls">
                                                                                {
                                                                                    (order.status === "OutForDelivery" || order.status === "Delivered" || order.status === "Returned")
                                                                                    &&
                                                                                    <>
                                                                                        {!IsClicked ?
                                                                                            (
                                                                                                <div className="invoice" style={{ textAlign: "center", cursor: "pointer" }}>
                                                                                                    {t("invoice.label")}
                                                                                                    <Icon name='download'
                                                                                                        onClick={
                                                                                                            (e) => handleInvoiceClick(config.apiurl + "profileinvoice?token=" + localStorage.getItem("token") + "&id=" + order.id, order.number)
                                                                                                        }></Icon>
                                                                                                </div>
                                                                                            )
                                                                                            :
                                                                                            (
                                                                                                <div className="invoice" style={{ textAlign: "center" }}>
                                                                                                    <Loader size='mini' active inline inverted />
                                                                                                </div>
                                                                                            )}
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <OrderItem order={order} type="Items" cancelOrder={CancelOrder} modalState={ModalFunc}
                                                                        returnTyp={ReturnType}
                                                                    />

                                                                </div>
                                                            </Grid.Column>
                                                        </Grid.Row>


                                                        {RenderMobileOrders(order)}
                                                    </>

                                                    {/* Accordion Code */}

                                                    <Grid.Row>
                                                        <Grid.Column>

                                                            {
                                                                (order.return && order.return.items && order.return.items.length > 0) ||
                                                                    (order.returnOrders && order.returnOrders.length > 0) ||
                                                                    (order.replaceOrders && order.replaceOrders.length > 0)
                                                                    ?
                                                                    (
                                                                        <Accordion fluid styled style={{ marginBottom: "1.25rem" }}>
                                                                            {
                                                                                order.return && order.return.items && order.return.items.length > 0 &&
                                                                                (
                                                                                    <>
                                                                                        <Accordion.Title active={order.activeIndex === 0} index={0} onClick={(e, titleProps) => HandleAccClick(order, titleProps)}>
                                                                                            <Icon name="dropdown" />
                                                                                            {t("return/replace.req.label")}
                                                                                        </Accordion.Title>

                                                                                        <Accordion.Content active={order.activeIndex === 0} style={{ width: "100%" }}>

                                                                                            <Grid.Row only="computer">
                                                                                                <Grid.Column width={16}>
                                                                                                    <div className="item-blocks">

                                                                                                        <div className="ib-header">
                                                                                                            <span>{t("return/replace.req.label")}</span>
                                                                                                        </div>

                                                                                                        <OrderItem order={order} type="ReturnRequest" readonly />
                                                                                                    </div>
                                                                                                </Grid.Column>
                                                                                            </Grid.Row>

                                                                                        </Accordion.Content>

                                                                                    </>
                                                                                )
                                                                            }


                                                                            {
                                                                                order.returnOrders && order.returnOrders.length > 0 && RenderReturOrders(order).length > 0 &&
                                                                                (
                                                                                    <>

                                                                                        <Accordion.Title active={order.activeIndex === 1} index={1} onClick={(e, titleProps) => HandleAccClick(order, titleProps)}>
                                                                                            <Icon name="dropdown" />
                                                                                            {t("cancel.label")}
                                                                                        </Accordion.Title>

                                                                                        <Accordion.Content active={order.activeIndex === 1} style={{ width: "100%" }}>
                                                                                            {
                                                                                                RenderReturOrders(order)
                                                                                            }
                                                                                        </Accordion.Content>

                                                                                    </>
                                                                                )
                                                                            }

                                                                            {
                                                                                order.replaceOrders && order.replaceOrders.length > 0 &&
                                                                                (
                                                                                    <>

                                                                                        <Accordion.Title active={order.activeIndex === 2} index={2} onClick={(e, titleProps) => HandleAccClick(order, titleProps)}>
                                                                                            <Icon name="dropdown" />
                                                                                            {t("replaceitems.label")}
                                                                                        </Accordion.Title>

                                                                                        <Accordion.Content active={order.activeIndex === 2} style={{ width: "100%" }}>
                                                                                            {
                                                                                                RenderReplaceOrders(order)
                                                                                            }
                                                                                        </Accordion.Content>
                                                                                    </>
                                                                                )
                                                                            }


                                                                        </Accordion>
                                                                    )
                                                                    : null
                                                            }

                                                        </Grid.Column>
                                                    </Grid.Row>


                                                </React.Fragment>
                                            );
                                        }))
                                        :
                                        <div style={{ width: "100%", textAlign: "center" }} >{t('noorders.label')}</div>
                                }
                            </Grid>
                        </Form>
                    )
            }


            {/* Modal Code */}

            {
                ModalState && MOrder && MItem && (
                    <Modal
                        onClose={() => {
                            setModalState(false);
                            setMOrder(null);
                            setMItem(null);
                            setMReplace(null);
                            setModalState(false);
                        }}
                        open={ModalState} className="modal-design"
                    >
                        <Modal.Header>Order number: {MOrder.number}</Modal.Header>

                        <Modal.Content>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <div className="return_list">
                                            <Form>
                                                <Grid style={{ margin: 0, marginBottom: "1.5rem" }}>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <Form.Field>
                                                                <label className="return_label" >{t("name.label")}</label>
                                                                <span >{MItem.productname}</span>
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>

                                                    <Grid.Row only="computer">

                                                        <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks">
                                                            <Form.Field>
                                                                <label className="return_label" >{t("quantity.label")}</label>
                                                                <div className="item-qnt">
                                                                    <NumberInput valueType="integer" minValue={1} maxValue={MItem.qty}
                                                                        value={MItem.r_qty}
                                                                        size="mini"
                                                                        onChange={(count) => {
                                                                            MItem.r_qty = parseInt(count);
                                                                            setUpdateCounter(UpdateCounter + 1);
                                                                        }} />
                                                                </div>
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks items" >
                                                            <Form.Field>
                                                                <label className="return_label" >{t("priceperunit.label")}</label>
                                                                <p>{Round(MItem.price, 2)}</p>
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks items" >
                                                            <Form.Field>
                                                                <label className="return_label" >{t("totalprice.label")}</label>
                                                                <p>{Round(MItem.r_qty * MItem.price, 2)}</p>
                                                            </Form.Field>
                                                        </Grid.Column>

                                                        <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks items" >
                                                            <Form.Field>
                                                                <label>{t("reason.label")}</label>
                                                                <Dropdown placeholder="Select a Reason" options={ReasonOptions}
                                                                    value={MItem.reason || ""} onChange={(e, { value }) => { MItem.reason = value; setUpdateCounter(UpdateCounter + 1) }} />
                                                            </Form.Field>
                                                        </Grid.Column>

                                                    </Grid.Row>

                                                    <Grid.Row only="tablet mobile">

                                                        <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks">
                                                            <Form.Field>
                                                                <label className="return_label" >{t("quantity.label")}</label>
                                                                <div className="item-qnt">
                                                                    <NumberInput valueType="integer" minValue={1} maxValue={MItem.qty}
                                                                        value={MItem.r_qty}
                                                                        size="mini"
                                                                        onChange={(count) => {
                                                                            MItem.r_qty = parseInt(count);
                                                                            setUpdateCounter(UpdateCounter + 1);
                                                                        }} />
                                                                </div>
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks" >
                                                            <Form.Field>
                                                                <label className="return_label" >{t("priceperunit.label")}</label>
                                                                <p>{Round(MItem.price, 2)}</p>
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks" >
                                                            <Form.Field>
                                                                <label className="return_label" >{t("totalprice.label")}</label>
                                                                <p>{Round(MItem.r_qty * MItem.price, 2)}</p>
                                                            </Form.Field>
                                                        </Grid.Column>

                                                        <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks" >
                                                            <Form.Field>
                                                                <label>{t("reason.label")}</label>
                                                                <Dropdown placeholder="Select a Reason" options={ReasonOptions}
                                                                    value={MItem.reason || ""} onChange={(e, { value }) => { MItem.reason = value; setUpdateCounter(UpdateCounter + 1) }} />
                                                            </Form.Field>
                                                        </Grid.Column>

                                                    </Grid.Row>


                                                    {
                                                        MItem.reason === "Other" && (
                                                            <Grid.Row >
                                                                <Grid.Column width={16}>
                                                                    <Form.Field>
                                                                        <label>{t("otherreason.label")}</label>
                                                                        <Input type="text" value={MItem.other_reason}
                                                                            onChange={(e, { value }) => { MItem.other_reason = value; setUpdateCounter(UpdateCounter + 1) }}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        )
                                                    }




                                                    {
                                                        (MReplace === undefined || MReplace === null || MReplace === false) &&
                                                        (
                                                            MOrder.payment.paymenttype === "COD" && (
                                                                <Grid.Row>
                                                                    <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks">
                                                                        <Form.Field>
                                                                            <label className="return_label" >{t("name.label")}</label>
                                                                            <Input type="text" value={HolderName} onChange={(e, { value }) => setHolderName(value)} />
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks">
                                                                        <Form.Field>
                                                                            <label className="return_label" >{t("bankname.label")}</label>
                                                                            <Input type="text" value={BankName} onChange={(e, { value }) => setBankName(value)} />
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks">
                                                                        <Form.Field>
                                                                            <label className="return_label" >{t("accountnumber.label")}</label>
                                                                            <Input type="text" value={BankNumber} onChange={(e, { value }) => setBankNumber(value)} />
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks">
                                                                        <Form.Field>
                                                                            <label className="return_label" >{t("branch.label")}</label>
                                                                            <Input type="text" value={BankBranch} onChange={(e, { value }) => setBankBranch(value)} />
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                    <Grid.Column computer={4} tablet={16} mobile={16} className="m-blocks">
                                                                        <Form.Field>
                                                                            <label className="return_label" >{t("ifsccode.label")}</label>
                                                                            <Input type="text" value={BankIfsc} onChange={(e, { value }) => setBankIfsc(value)} />
                                                                        </Form.Field>
                                                                    </Grid.Column>
                                                                </Grid.Row>
                                                            )
                                                        )
                                                    }

                                                </Grid>
                                            </Form>
                                        </div>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Modal.Content>

                        <Modal.Actions>
                            <Button onClick={() => {
                                setMOrder(null);
                                setMItem(null);
                                setModalState(false);
                            }} >
                                {t("close.label")}
                            </Button>
                            <Button onClick={() => {
                                ReturnItems(MOrder.id, MOrder.payment.paymenttype, MItem, MReplace);
                                setModalState(false);
                                setMOrder(null);
                                setMItem(null);
                            }} >
                                {MReplace !== undefined && MReplace !== null && MReplace !== false ? t("Replace.label") : t("Return.label")}
                            </Button>
                        </Modal.Actions>
                    </Modal>
                )
            }
        </div>
    );
};

export default withTranslation()(Orders);