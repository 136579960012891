import React, { Component, Fragment } from 'react';
import { Grid, Container, Header, Segment, Image, Checkbox, Dropdown, Accordion, Card, Button, Icon, Message, Loader } from 'semantic-ui-react';
import TopHeader from '../../Home/TopHeader'
import Footer from '../../Home/Footer'
import { gql } from 'apollo-boost'
import { config, profileClientWithoutSession, client } from '../../config'
//import CategoryItems from './CategoryItems'
import CategoryItem from '../../Categories/CategoryItem';
import Navbar from '../../Home/Navbar'
import { withTranslation } from 'react-i18next'
import { Breadcrumb } from "semantic-ui-react";
import { Link, withRouter } from 'react-router-dom'

import _ from "lodash"
import CategoryMenu from '../../Categories/CategoryMenu';

class Listing extends Component {
  constructor(props) {
    super(props)
    const match = props.match
    this.t = this.props.t;

    var sortOptions = [
      //{ value: 'select', text: 'select' },
      { value: "LowToHigh", text: this.t("lowtohigh.label") },
      { value: "HighToLow", text: this.t("hightolow.label") },
    ];
    this.state = {
      matchcode: match.params.id,
      tokenId: '',
      relatedproducts: [],
      scode: '',
      mrpprice: '',
      drgcnt: 0,
      msg: '',
      isLoading: false,
      products: [],
      categories: [],
      subcategories: [],
      subcategory: {},
      allbrands: [],
      allpackages: [], // weight
      allprices: [],
      activecategory: "",
      // Filters - Selected
      filteredCategories: "All",
      filteredSubCategories: "",
      filteredBrands: "",
      filteredPackages: "",

      tempfilteredSubCategories: [],
      tempfilteredbrands: [],
      tempfilteredpackages: [],
      packnames: "",

      tokenId: "",
      relatedproducts: [],
      scode: "",
      sqty: "",
      sprice: "",
      cartCount: 0,
      isButtonDisabled: 0,
      menuItems: [],

      animation: "overlay",
      direction: "left",
      dimmed: false,
      visible: false,
      ActiveIndex: -1,
      isOpened: false,
      DDSelectedValue: "LowToHigh",
      DDoptions: sortOptions,
      selected: "LowToHigh",

    }
    this.getDrugCount = this.getDrugCount.bind(this)
    this.t = this.props.t;

    this.getCartCount = this.getCartCount.bind(this);
    this.handleClickMobile = this.handleClickMobile.bind(this);

    // console.log( match.params.id);
  }


  handleClickMobile() {
    this.setState((state) => ({ isOpened: !state.isOpened }));
  }

  componentDidMount() {
    this.setState({ filteredCategories: "All", msg: " ", loading: true });

    // console.log("Options:" + JSON.stringify(options));
    this.getCartCount();
    this.loadProductCategory();
    this.loadResults(
      this.state.filteredCategories,
      this.state.filteredSubCategories,
      this.state.filteredBrands,
      this.state.filteredPackages
    );

    this.setState({ matchcode: this.props.match.params.id })
    this.setState({ msg: ' ' })
    this.getDrugCount()

    this.GetProducts();
  }

  loadProductCategory() {
    client
      .query({
        query: gql`
          {
            getProductCategories {
              name
              subcategories {
                name
              }
            }
            GetActiveBrand {
              id
              name
            }
          }
        `,
      })
      .then((result) => {
        //console.log('resp:' + JSON.stringify(result))
        this.setState({
          categories: result.data.getProductCategories,
          allbrands: result.data.GetActiveBrand,
        });
      })
      .catch((error) => {
        console.log("error:" + error);
      });
  }

  loadResults(
    filterCategories,
    filterSubCategories,
    filterBrands,
    filterPackages
  ) {

    client
      .query({
        query: gql`
          query (
            $category: String
            $subcategory: String
            $brand: String!
            $weight: String!
          ) {
            Category: getActiveproductcategory {
              id
              name
              image
            }

            GetAllProductsByCategory(
              category: $category
              subcategory: $subcategory
              brand: $brand
              weight: $weight
            ) {
              id
              name
              image
              subcategory
              code
              discountedprice
              offerpercentage
              mrpprice
              category
              subcategory
              brand
              status
              weight
              uom
            }
          }
        `,
        variables: {
          category: filterCategories,
          subcategory: filterSubCategories,
          brand: filterBrands,
          weight: filterPackages,
        },
      })
      .then((result) => {
        this.setState({
          products: result.data.GetAllProductsByCategory,
          // categories: result.data.Category,
        });

        /* result.data.Category.map(category=>{
          this.loadSubCategory(category.name)
        }); */

        /*   if (this.state.allbrands.length == 0) {
          var brandnames = _.uniqBy(
            result.data.GetAllProductsByCategory,
            "brand"
          );
      
          console.log("Brands:",  JSON.stringify(brandnames));
          this.setState({
            allbrands: brandnames,
          });
          } */

        if (this.state.allpackages.length == 0) {
          const packname = _.uniqBy(
            result.data.GetAllProductsByCategory,
            "weight"
          );
          this.setState({
            allpackages: packname,
          });
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  loadSubCategory(parentCategory) {
    client
      .query({
        query: gql`
          query ($category: String) {
            GetChildSubCategory(category: $category) {
              id
              name
            }
          }
        `,
        variables: { category: parentCategory },
      })
      .then((result) => {
        var subcategory = this.state.subcategory;
        var catg = this.state.categories;

        subcategory[parentCategory] = result.data.GetChildSubCategory;

        //   console.log('Sub category :' + JSON.stringify(subcategory))

        this.setState({ subcategory, categories: catg });
      })
      .catch((err) => {
        console.log(
          "error : failed to load subcategory : " + parentCategory + err
        );
      });
  }
  RenderSubCategory(categoryname) {
    // console.log('categoryname' + categoryname)
    client
      .query({
        query: gql`
          query ($category: String) {
            GetChildSubCategory(category: $category) {
              id
              name
            }
          }
        `,
        variables: { category: categoryname },
      })
      .then((result) => {
        // console.log('sub' + JSON.stringify(result.data.GetChildSubCategory))
        //  this.setState({subcategories: result.data.GetChildSubCategory});
        // console.log('subcategories' + JSON.stringify(subcategories))
        {
          /* <Card>
           {result.data.GetChildSubCategory.map(subcategory => (
           <Card.Content className="">
             <Checkbox
             label={subcategory.name}
             id={subcategory.name}
             onChange={this.handleSubCategoryChange}
             />
           </Card.Content>
           ))}
         </Card> */
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handleSubCategoryChange = (e, { checked, label }) => {
    var selectedCat = this.state.tempfilteredSubCategories;
    if (checked) selectedCat.push(label);
    else {
      selectedCat = _.remove(
        this.state.tempfilteredSubCategories,
        function (n) {
          return n != label;
        }
      );
    }
    var categ = null;
    categ = selectedCat.join(",");
    this.setState({
      tempfilteredSubCategories: selectedCat,
      filteredSubCategories: categ,
    });

    this.loadResults(
      this.state.filteredCategories,
      categ,
      this.state.filteredBrands,
      this.state.filteredPackages
    );
  };


  HandleSubCatChange = (SelectedCat, categoryStr) => {
    this.setState({
      tempfilteredSubCategories: SelectedCat,
      filteredSubCategories: categoryStr
    });

    this.loadResults(
      this.state.filteredCategories,
      categoryStr,
      this.state.filteredBrands,
      this.state.filteredPackages
    );
  };


  sortbychange = (e, { value }) => {
    var prod = this.state.relatedproducts;
    var myOrderedArray;
    if (value == "HighToLow") {
      myOrderedArray = _.sortBy(prod, ["type", "mrpprice"]).reverse();
    } else myOrderedArray = _.sortBy(prod, (o) => o.mrpprice);

    this.setState({ relatedproducts: myOrderedArray });
  };
  handleBrandChange = (e, { checked, label }) => {
    var selectedBrand = this.state.tempfilteredbrands;
    if (checked) selectedBrand.push(label);
    else {
      selectedBrand = _.remove(this.state.tempfilteredbrands, function (n) {
        return n != label;
      });
    }
    var selectedBrands = selectedBrand.join(",");
    this.setState({
      tempfilteredbrands: selectedBrand,
      filteredBrands: selectedBrands,
    });

    this.loadResults(
      this.state.filteredCategories,
      this.state.tempfilteredSubCategories.join(","),
      selectedBrands,
      this.state.filteredPackages
    );
  };
  handlePackagesChange = (e, { checked, label }) => {
    var selectedPack = this.state.tempfilteredpackages;
    if (checked) selectedPack.push(label);
    else {
      selectedPack = _.remove(this.state.tempfilteredpackages, function (n) {
        return n != label;
      });
    }
    var selectedPacks = selectedPack.join(",");
    this.setState({
      tempfilteredpackages: selectedPack,
      filteredPackages: selectedPacks,
    });

    this.loadResults(
      this.state.filteredCategories,
      this.state.filteredSubCategories,
      this.state.filteredBrands,
      selectedPacks
    );
  };
  getCartCount() {
    profileClientWithoutSession
      .query({
        query: gql`
          query {
            getOrderCount
          }
        `,
      })
      .then((res) => {
        this.onCartCountChange(res.data.getOrderCount);
      });
  }

  onCartCountChange(num) {
    //console.log("numb", num);
    this.setState({
      cartCount: num,
    });
  }
  handleCategoryHeaderClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? "" : index;

    this.setState({ activecategory: newIndex });
  };

  handleDimmedChange = (e, { checked }) => this.setState({ dimmed: checked });

  handleDirectionChange = (direction) => () =>
    this.setState({ direction, visible: false });


  getDrugCount() {
    profileClientWithoutSession.query({
      query: gql`
			query{
				getOrderCount
			  }`
    }).then(res => {
      this.setState({ drgcnt: res.data.getOrderCount })
    })
  }

  GetProducts() {
    this.setState({ isLoading: true })
    //console.log('getprods')
    client.query({
      query: gql`
        query ($name : String!){
					getProductsByTag(name : $name) 
             {  
                name,
                code,
				brand,
				mrpprice,
				discountedprice,
				image,
				quantity,
				offerpercentage,
				discountedprice,
              }
            }`,
      variables: { name: this.props.match.params.id }
    }).then(result => {
      //console.log( JSON.stringify(results) )
      const results = result.data.getProductsByTag.map(ans => ({ ...ans, key: ans.code }))

      this.setState({
        isLoading: false,
        relatedproducts: results
      })
      //	console.log(cod,quantity,discpric,mrp)
    }).catch(e => {
      console.log(e)
      this.setState({
        isLoading: false,
        relatedproducts: []
      })
    })
  }

  onSubsDrgCountChange(num) {
    this.setState({
      drgcnt: num
    })
  }

  clearFilters = () => {
    this.setState({
      filteredCategories: "All",
      filteredSubCategories: "",
      filteredBrands: "",
      filteredPackages: "",
      categories: [],
      loading: true
    });


    this.loadProductCategory();

    this.loadResults(
      "All",
      "",
      "",
      ""
    );
  }

  render() {

    const { products, activecategory, isOpened } = this.state;
    const { relatedproducts, isButtonDisabled, drgcnt } = this.state
    return (
      <>
        <Grid className="no-padding-margin">
          <Grid.Row>
            <Grid.Column
              width={16}
              className="no-padding-margin"
              only=""
            >
              <div className="seven-container categorylist brandList">
                <div className="left-block">
                  <CategoryMenu
                    Categories={this.state.categories}
                    SubCatFilter={this.state.tempfilteredSubCategories}
                    HandleSubCategoryChange={this.HandleSubCatChange}
                    ClearCat={this.clearFilters}
                  />


                </div>
                <div className="right-block brandSection">
                  <Segment className="groupbox-container">
                    <Navbar menuItems={this.state.menuItems} >
                      <Container>
                        <Grid className='listing-container groupList'>
                          <Grid.Row className='one'>


                            <div className="category-width brandSort productGroups">
                              <Grid columns={2} padded>
                                <div className="sorting-drop">
                                  <span className="sort-left">
                                    <b>SORT BY </b>&nbsp;
                                    <span>

                                      <Dropdown

                                        options={this.state.DDoptions}
                                        defaultValue={this.state.selected}
                                        onChange={this.sortbychange}
                                      />
                                    </span>
                                  </span>

                                </div>

                              </Grid>
                            </div>
                          </Grid.Row>

                          <Grid.Row only="mobile">
                            <Grid.Column width={16}>
                              <div className="mobile-category">
                                <Icon size="small" name="th large" onClick={() => this.setState({ mobileCategory: !this.state.mobileCategory })} />
                              </div>
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row only="mobile">
                            <Grid.Column width={16}>
                              {
                                this.state.mobileCategory
                                &&
                                <CategoryMenu
                                  Categories={this.state.categories}
                                  SubCatFilter={this.state.tempfilteredSubCategories}
                                  HandleSubCategoryChange={this.HandleSubCatChange}
                                  ClearCat={this.clearFilters}
                                />
                              }
                            </Grid.Column>
                          </Grid.Row>

                          {
                            this.state.isLoading === true &&
                            <Grid.Row>
                              <Loader active inline='centered' />
                            </Grid.Row>
                          }

                          {
                            this.state.isLoading === false &&
                            <Grid.Row className="three products">
                              {
                                relatedproducts.length == 0 && <div className="noproduct"> <Message>
                                  <Message.Header></Message.Header>
                                  <p> {this.t("noProductAvail.label")}</p>
                                </Message>
                                </div>
                              }

                              {
                                relatedproducts.map(product =>
                                  <CategoryItem key={product.code} product={product} ></CategoryItem>
                                )
                              }

                            </Grid.Row>
                          }



                        </Grid>
                      </Container>

                    </Navbar>
                  </Segment>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>

      </>
    )
  }
}
export default withTranslation()(Listing);