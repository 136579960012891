import React, { Component } from 'react'
import { Form, Button, Grid, Segment, Header, Message } from 'semantic-ui-react'
import ApolloClient from 'apollo-boost'
import gql from 'graphql-tag'
import { Link, withRouter } from 'react-router-dom'
import TopHeader from '../../Home/TopHeader';
import Footer from '../../Home/Footer';
import { config, accountClient } from '../../config'
import { withTranslation } from 'react-i18next'

class Verification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fields: {},
      errors: {},
      msgValidate: '',
      phone: ''
    }
    this.t = this.props.t
  }
  UNSAFE_componentWillMount() {
    const num = localStorage.getItem('mobNum')
    const payload = localStorage.getItem('payload');
    this.setState({ phone: num, payload })

  }


  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["otp"] || fields["otp"].trim().length == 0) {
      formIsValid = false;
      errors["otp"] = this.t("provideotp.val");
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  OTPverify(e) {
    e.preventDefault();
    debugger;
    if (this.handleValidation()) {
      this.verifyOTPApi();
    } else {
      // alert("Form has errors.")
    }
  }

  handleResendOTP = (e) => {
    accountClient.mutate({
      mutation: gql`
      mutation($OTPResend:ResendOTPInput)
      {
       OTPResend(resend:$OTPResend)
      
     }
     
          `,
      variables: {

        "OTPResend": {
          "phonenumber": this.state.phone
        }

      }
    })
      .then(t => {
        //console.log(JSON.stringify(t))
        // this.props.history.push("/verify")
      })
    e.preventDefault();
  }

  verifyOTPApi() {

    accountClient.mutate({
      mutation: gql`
      mutation($otpverify:VerifyOTPInput)
     {
        OTPVerify(otpVerify:$otpverify)
        {
          id,
          number,
        }
      }
      
      `,
      variables: {
        "otpverify": {
          "phonenumber": this.state.phone,
          "OTP": this.state.fields.otp,
          "payload": this.state.payload
        }
      }
    })
      .then(t => {

        //console.log("OTP Verifiy:"+ JSON.stringify(t));

        localStorage.setItem("token", t.data.OTPVerify.id)
       
        localStorage.removeItem('mobNum')
        localStorage.removeItem('payload')
        this.props.history.push("/")
      }).catch(e => {

        var err = e.graphQLErrors;
        var errs = '';
        if (err != null & err.length > 0) {

            // debugger;
            for (var i = 0; i < err.length; i++) {
                errs += err[i].message
            }

        }
        this.setState({ msgValidate: errs });

    });
  }

  render() {

    return (
      <div className='OTP-verification'>
        {/* <TopHeader noheader="true"></TopHeader> */}
        <Grid centered style={{ height: '100%' }} className='account-container'>
          <Grid.Column mobile={16} tablet={8} computer={5} style={{ maxWidth: 450 }} className='section login' style={{ backgroundColor: '#ffffff' }} >
            <div className="error-message">
              {this.state.msgValidate}
            </div>

            <Form size='large' name="loginform" onSubmit={this.OTPverify.bind(this)} style={{ fontFamily: 'roboto_medium' }}>
              <Header as='h2' className='ui header' style={{ color: '#1C3448' }} > {this.t("otpverify.label")} </Header>

              <Form.Input
              label= {this.t("mobileNumber.label")}
              className="required"
                value={this.state.phone}
              />

              <span className="indiv-error-message">{this.state.errors["otp"]}</span>
              < Form.Input placeholder= {this.t("otp.label")}
                refs="otp"
                label= {this.t("otp.label")}
              className="required"
                onChange={this.handleChange.bind(this, "otp")}
                value={this.state.fields["otp"]}
              />
     {/*          <Grid className='actions'>
                <Grid.Column width={8} className='left floated'>
                  <label onClick={this.handleResendOTP}  >Resend OTP?</label> */}
                  {/*    &nbsp;<a href="/OTPResend" as={Link} to="/OTPResend">Resend OTP? &nbsp;</a> */}
              {/*   </Grid.Column>
              </Grid> */}

              <Button fluid size='large' type="submit">
               {this.t("continue.label")}
                </Button>
            </Form>
          </Grid.Column>
        </Grid>
        {/* <Footer></Footer> */}
      </div>
    )
  }
}
export default withTranslation()(withRouter(Verification))