import React, { Component } from "react";
import { gql } from "apollo-boost";
import {
	Grid,
	Breadcrumb
} from "semantic-ui-react";

import _filter from "lodash/filter";
import _map from "lodash/map";
import _each from "lodash/each";
import _remove from "lodash/remove";
import { client, profileclient } from "../config";
import Payment from '../Payment'

import { Context } from "../Context";

import { withTranslation } from "react-i18next";

import ShoppingCart from "./ShoppingCart";
import CheckOut from "./CheckOut";
import DocumentMeta from "react-document-meta";

class Cart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ActiveScreen: 1,
			OrderId: 0
		};	

		this.t = this.props.t;
		this.Meta = {};
	}

	static contextType = Context;

	componentDidMount()
	{
		this.Meta = {
			title: (localStorage.getItem("title") ? localStorage.getItem("title") : "eeasytrade") + " - Shopping Cart",
			description: "Cart Checkout Page",
			canonical: window.location.href,
			meta: {
				charset: "utf-8",
				name: {
					keywords: ""
				}
			}
		};
	};

	GetCartCount() {
		profileclient.query({
			query: gql`
				query
				{
					getOrderCount
				}
			`,
		}).then((res) => {
			this.setState({ cartCount: res.data.getOrderCount });
			this.setCartCount(res.data.getOrderCount);
		});
	};

	RenderCartScreens()
	{
		let Comp = null;
		const { ActiveScreen, cartCount } = this.state;
		
		if(ActiveScreen == 1)
		{
			Comp = <ShoppingCart ScreenEvnt={(id) => this.HandleScreenChange(id)}/>
		}
		else if(ActiveScreen == 2)
		{
			Comp = <CheckOut ScreenEvnt={(id, orderId) => this.HandleScreenChange(id, orderId)}/>
		}
		else if(ActiveScreen == 3)
		{
			Comp = (
				<div className="seven-container">
					{/* <TopHeader count={cartCount} SearchBar={false} /> */}
					<Grid>
						<Grid.Row>
							<Grid.Column width={16}>
								<Breadcrumb className="payment-breadcrumb">
									<Breadcrumb.Section><label className="bread-label">1.{this.t("shoppingcart.label")}</label></Breadcrumb.Section>
									<Breadcrumb.Divider icon="right angle"></Breadcrumb.Divider>
									<Breadcrumb.Section><label className="bread-label">2.{this.t("checkout.label")}</label></Breadcrumb.Section>
									<Breadcrumb.Divider icon="right angle"></Breadcrumb.Divider>
									<Breadcrumb.Section><label className="bread-label active">3.{this.t("ordercomplete.label")}</label></Breadcrumb.Section>
								</Breadcrumb>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column width={16}>
								<Payment orderId={this.state.OrderId}/>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			);
		}

		return Comp;
	};

	HandleScreenChange(screenId, OrderId)
	{
		// console.log(screenId);
		//console.log(OrderId);
		this.setState({ActiveScreen: screenId, OrderId: OrderId ? OrderId : 0});
	};

	render() {
		return(
			<DocumentMeta {...this.Meta} >
				<div>
					{this.RenderCartScreens()}
				</div>
			</DocumentMeta>
		);
	};
}

export default withTranslation()(Cart);
