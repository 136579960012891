import React, { Component, Fragment } from "react";
import { Grid, Segment, Image, Button, Icon, Card } from "semantic-ui-react";

import { gql } from "apollo-boost";
import { profileclient } from "../config";
import { Round } from "../Helpers";
import noimg from "resources/no-image.png";
import { withRouter } from "react-router-dom";

import { Link } from "react-router-dom";
// import NumericInput from 'react-numeric-input';

import NumberInput from "semantic-ui-react-numberinput";

import { Consumer, Context } from "../Context";
import { withTranslation } from "react-i18next";

class CategoryItem extends Component {
	static propTypes = {};

	constructor(props) {
		super(props);

		this.state = {
			msg: "",
			imgurlprefix: localStorage.getItem("imgurl") + '/images/product',
			productCount: "1",
			cartCount: 0
		};

		//console.log("url:", localStorage.getItem("imgurl"));
		this.t = this.props.t;
	}

	handleProductCount = count => {
		if (count !== null) {
			this.setState({ productCount: count });
		}
	};

	static contextType = Context;

	render() {
		const product = this.props.product;

		const offer = product.offerpercentage > 0;
		const price = product.offerpercentage > 0 ? product.discountedprice : product.mrpprice;
		// console.log(product.offerpercentage)
		return (
			<Grid.Column computer={4} tablet={8} mobile={8} style={{ margin: 0, marginBottom: 20, padding: 5 }} >

				<Card key={product.id} className="productItem">
					<Link to={"/product/" + product.code}>

						{product.image != null && (
							<div className="image-block">
								<Image
									src={this.state.imgurlprefix + product.image[0]}
									className="prd-image"
								/>
							</div>
						)}
						{product.image == null && (
							<div className="image-block">
								<Image src={noimg} width={180} heigth={180} className="prd-image" />
							</div>
						)}
						<div className="product-name">{product.name}</div>

						<div className="product-container">

							<p className="pricing">
								<span className="s-left off offer-price">{Round(price, 2)}</span> 
								{offer && <> 
									<span className="mrp-price">{Round(product.mrpprice, 2)}</span> 
									<span className="offer-percentange">({product.offerpercentage}% {this.t("off.label")})</span> 
								</>}  </p>
						</div>
					</Link>
				</Card>
			</Grid.Column>
		);
	}

	AddToCart = (e, id) => {
		e.preventDefault();
		try {
			let tokenId = localStorage.getItem("token");

			if (typeof tokenId == "undefined" || tokenId == null) {
				{
					localStorage.setItem("path", window.location.href);
					this.setState({ msg: "" });
				}
				this.props.history.push("/login");
			}
			if (tokenId) {
				//insert to table
				profileclient
					.mutate({
						mutation: gql`
              mutation($cart: CartInput) {
                insertCart(cart: $cart)
              }
            `,
						variables: {
							cart: {
								productcode: id,
								qty: this.state.productCount,
								price: 0.0,
								mrpprice: 0.0
							}
						}
					})
					.then(resp => {
						this.setState({
							isButtonDisabled: 1,
							msg: this.t("addedCartMessage.label")
						});

						const cartCount = resp.data.insertCart;
						//Consumer.SetCartCount(cartCount);
						this.context.SetCartCount(cartCount);

					});
			}

			e.preventDefault();
		} catch (err) {
			console.log(err.message);
		}
	};
}

export default withTranslation()(withRouter(CategoryItem));
