import React, { Component } from "react";
import { gql } from "apollo-boost";
import { Link, withRouter } from "react-router-dom";
import {
	Button,
	Grid,
	Segment,
	Image,
	Icon
} from "semantic-ui-react";
import NumberInput from "semantic-ui-react-numberinput";

import noimg from "resources/no-image.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, PinterestIcon, PinterestShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";

import {
	profileclient,
	client,
	profileClientWithoutSession
} from "../../config";


import { Round } from "../../Helpers";

import { withTranslation } from "react-i18next";
import { Context } from "../../Context";

import DocumentMeta from "react-document-meta";
import DOMPurify from "dompurify";
import CategoryItem from "../../Categories/CategoryItem";

class Detail extends Component {

	constructor(props) {
		super(props);

		this.state = {
			activeIndex: 0,
			code: "",
			qty: 1,
			discprice: "",
			mrprs: "",
			imgurlprefix: localStorage.getItem("imgurl") + '/images/product/',
			tokenId: "",
			isLoading: false,
			productsdetails: null,
			pincode: "",
			dtype: "",
			drgcnt: 0,
			isButtonDisabled: 0,
			msg: "",
			itemcnt: 0,
			addedTocart: false,
			dimmer: false,
			name: "",
			menuItems: [],
			SimilarProducts: []
		};

		this.t = this.props.t;
		this.ShareLocation = window.location.href;
		// console.log("Share Location", this.ShareLocation);

		this.getDrugCount = this.getDrugCount.bind(this);
	}
	handlePinChange = event => {
		this.setState({ pincode: event.target.value });
	};


	static contextType = Context;

	componentDidMount() {
		this.setState({ code: this.props.matchcode, qty: 1, msg: " " });
		this.getDrugCount();
		this.LoadProduct(this.props.matchcode);
	}

	componentDidUpdate(prevProps) {

		if (this.props.match.params.id !== this.state.code) {
			// ... write code to get new data using new prop, also update your state
			this.setState({ code: this.props.match.params.id, qty: 1, msg: " " });
			this.getDrugCount();
			//console.log("Props:", this.props.match.params.id)
			this.LoadProduct(this.props.match.params.id);
		}
	}

	setCartCount(count) {

		this.context.SetCartCount(count);
	}

	getDrugCount() {
		profileClientWithoutSession
			.query({
				query: gql`
          query {
            getOrderCount
          }
        `
			})
			.then(res => {
				//this.setState({ drgcnt: res.data.getOrderCount });
				this.setCartCount(res.data.getOrderCount)

			})
			.catch(e => {
				console.log(e);
			});
	}

	checkLocn = e => {
		e.preventDefault();

		const deliveryresult = client
			.query({
				query: gql`
          query($pin: Int!) {
            getDeliveryType(pincode: $pin) {
              id
              deliverytype
            }
          }
        `,
				variables: { pin: this.state.pincode }
			})
			.then(delyresult => {
				const res = delyresult.data.getDeliveryType[0].deliverytype;

				this.setState({
					isLoading: false,
					dtype: res
				});
			})
			.catch(e => {
				console.log(e);
			});
	};

	show = () => {
		this.setState({ addedTocart: true });
	};

	AddToCart = (e, code) => {
		e.preventDefault();
		try {
			let tokenId = localStorage.getItem("token");

			if (typeof tokenId === "undefined" || tokenId == null) {
				localStorage.setItem("path", window.location.href);
				this.props.history.push("/login");
			}
			if (tokenId) {
				//console.log("Inside Add to cart");

				profileclient
					.mutate({
						mutation: gql`
              mutation($cart: CartInput) {
                insertCart(cart: $cart)
              }
            `,
						variables: {
							cart: {
								productcode: code,
								qty: this.state.qty,
								price: this.state.discprice,
								mrpprice: this.state.mrprs
							}
						}
					})
					.then(resp => {
						this.setCartCount(resp.data.insertCart);


						this.setState({ msg: this.t("addedCartMessage.label") });
					});
			}
		} catch (err) {
			console.log(err.message);
		}
	};

	handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = this.state;
		const newIndex = activeIndex === index ? -1 : index;

		this.setState({ activeIndex: newIndex });
	};

	onSubsDrgCountChange(num) {
		this.setState({
			drgcnt: num
		});
	}

	LoadProduct(code) {

		// Product Detais
		client
			.query({
				query: gql`
        query($code: String!) {
          GetProductsDetail(Code: $code) {
            code
            category
            subcategory
            name
            quantity
            mrpprice
            discountedprice
            offerpercentage
            status
            image
            description
            weight
            ingredienttype
            countryoforigin
			brand
			SEOTitle
    		SEODescription
			return
          }
        }
      `,
				variables: { code: code }
			})
			.then(response => {
				//console.log('Response:' + JSON.stringify(response))

				var result = response.data.GetProductsDetail;

				// console.log(result);

				const discres = result.discountedprice;
				const name = result.name;
				const mrp = result.mrpprice;
				const stockstatus = result.status;

				// document.getElementById("sitetitle").innerText = result.SEOTitle !== null && result.SEOTitle !== "" ? result.SEOTitle : "Product Details";
				// document.getElementById("sitetitle").innerText = "Hello";

				if (stockstatus == "InStock" || stockstatus == "Available") {
					this.setState({ isButtonDisabled: 0 });
				} else this.setState({ isButtonDisabled: 1 });

				this.setState({
					isLoading: false,
					productsdetails: result,
					discprice: discres,
					mrprs: mrp,
					name: name,
					SEOTitle: result.SEOTitle,
				});

				this.GetSimilarProducts();
			})
			.catch(e => {
				console.log(e);
			});

	}

	/*   close = () => {
	  this.setState({ addedTocart: false });
	  this.props.history.push("/");
	};
  
	closeCart = () => {
	  this.setState({ addedTocart: false });
	  this.props.history.push("/cart");
	}; */

	//Get The List of Similar Products

	GetSimilarProducts() {
		const product = this.state.productsdetails;

		client
			.query({
				query: gql`
          query($Code: String!) {
            getSimilarProducts(code: $Code) {
              id
              name
              code
              quantity
              image
              mrpprice
              discountedprice
              offerpercentage
              status
              active
            }
          }
        `,
				variables: {
					Code: product.code
				}
			})
			.then(result => {
				const SimilarProds = result.data.getSimilarProducts;
				//console.log("Similar Products ", SimilarProds);

				this.setState({ SimilarProducts: SimilarProds });
			})
			.catch(err => console.log(err));
	}

	handleProductCount = count => {
		if (count !== null) {
			this.setState({ qty: count });
		}
	};

	render() {
		const {
			productsdetails,
			code,
			name,
			dtype,
			isButtonDisabled,
			msg,
			drgcnt
		} = this.state;

		let pd = this.state.productsdetails;

		let title = "";
		if (pd) {
			if (pd.SEOTitle !== null && pd.SEOTitle !== "") {
				title = pd.SEOTitle;
			}
			else {
				title = "eeasytrade " + pd.name;
			}
		}

		const meta = {
			// title: pd !== null && pd !== "" ? pd.SEOTitle : "Product Details",
			title: title,
			description: pd ? pd.SEODescription : "",
			canonical: window.location.href,
			meta: {
				charset: 'utf-8',
				name: {
					keywords: title
				}
			}
		};

		// console.log(meta);

		const { activeIndex } = this.state;
		const product = this.props.product;

		return (
			<DocumentMeta {...meta}>
				<div>
					{this.renderResult(
						productsdetails,
						code,
						name,
						dtype,
						isButtonDisabled,
						drgcnt
					)}
				</div>
			</DocumentMeta>
		);
	}

	renderResult(productsdetails, code, name, dtype, isButtonDisabled, drgcnt) {
		let product = productsdetails;

		return (
			<div>
				{/* <Segment> */}
				<>

					{product != null && (
						<div key={product.code}>
							<div>
								{
									<>

										<Grid className="product-container seven-container">

											<Grid.Row>
												<Grid.Column computer={8} tablet={8} mobile={16}>
													<div className="primary-image">
														{
															product.image !== null && product.image !== undefined ?
																<Carousel>
																	{
																		product.image.map((imag, index) => {
																			return (
																				<div>
																					<img className="carousel-main" src={this.state.imgurlprefix + imag} />
																				</div>
																			);
																		})
																	}
																</Carousel>
																:
																<Image src={noimg} className="big" />
														}
													</div>
												</Grid.Column>

												<Grid.Column computer={8} tablet={8} mobile={16}>
													<Grid columns="equal" className="product-attribute">
														<Grid.Row>
															<Grid.Column>
																<span className="product-name" style={{ textAlign: "left" }}>{product.name}</span>
															</Grid.Column>
														</Grid.Row>

														<Grid.Row>
															{
																product.offerpercentage != "" ?
																	<div className="pricing">
																		<div className="offer-price">
																			{Round(product.discountedprice, 2)}
																		</div>
																		<div className="mrp-price strike-out">
																			{Round(product.mrpprice, 2)}
																		</div>
																	</div>
																	:
																	<div className="pricing">
																		<div className="mrp-price">
																			{Round(product.mrpprice, 2)}
																		</div>
																	</div>
															}
														</Grid.Row>

														{
															product.offerpercentage > 0 &&
															<Grid.Row>
																<div className="offer-span">
																	You Save: {product.offerpercentage}% (Inclusive of all taxes)
																</div>
															</Grid.Row>
														}


														<Grid.Row only="computer tablet">
															<div className="share-buttons">
																<FacebookShareButton url={this.ShareLocation} >
																	<FacebookIcon size={32} round />
																</FacebookShareButton>
																<TwitterShareButton url={this.ShareLocation} >
																	<TwitterIcon size={32} round />
																</TwitterShareButton>
																<LinkedinShareButton url={this.ShareLocation} >
																	<LinkedinIcon size={32} round />
																</LinkedinShareButton>
																<WhatsappShareButton url={this.ShareLocation} >
																	<WhatsappIcon size={32} round />
																</WhatsappShareButton>
															</div>
														</Grid.Row>

														<Grid.Row only="mobile">
															<div className="mobile-share-buttons">
																<FacebookShareButton url={this.ShareLocation} >
																	<FacebookIcon size={32} round />
																</FacebookShareButton>
																<TwitterShareButton url={this.ShareLocation} >
																	<TwitterIcon size={32} round />
																</TwitterShareButton>
																<LinkedinShareButton url={this.ShareLocation} >
																	<LinkedinIcon size={32} round />
																</LinkedinShareButton>
																<WhatsappShareButton url={this.ShareLocation} >
																	<WhatsappIcon size={32} round />
																</WhatsappShareButton>
																<PinterestShareButton url={this.ShareLocation} >
																	<PinterestIcon size={32} round />
																</PinterestShareButton>
															</div>
														</Grid.Row>


														<div className="horizontal-line1"></div>

														<Grid.Row className="inpt-cartbtn">

															<Grid.Column mobile={6} computer={5} tablet={5} className="no-padding">
																<NumberInput
																	value={this.state.qty}
																	defaultValue={1}
																	minValue={1}
																	onChange={this.handleProductCount}
																	className="number-input-leftfix"
																/>
															</Grid.Column>

															<Grid.Column mobile={10} computer={10} tablet={10} className="no-padding">
																<div className="add-cart-button">
																	<Button
																		onClick={e => this.AddToCart(e, product.code)}
																	>
																		<Icon name="shopping bag" />
																		{this.t("addToCart.label")}
																	</Button>
																</div>
															</Grid.Column>

														</Grid.Row>




														<Grid.Row>

															<Grid.Column>
																<div>
																	{(product.return && product.return > 0) ? this.t("ReturnPolicy.label") + " " + product.return + " " + this.t("ReturnDays.label") + " " + this.t("ReturnInfo.label")  : this.t("ReturnNA.label")}
																</div>
															</Grid.Column>
														</Grid.Row>



														<Grid.Row className="no-padding">
															<Grid.Column>
																<div className="delivery">
																	<span className="general-message">
																		{" "}
																		{this.state.msg}{" "}
																	</span>
																</div>
															</Grid.Column>
														</Grid.Row>

														{/* <div className="horizontal-line1"></div> */}

														{/* <Grid.Row className="social-icons" only="computer tablet">
													<Icon name="facebook f" />
													<Icon name="twitter" />
													<Icon name="mail" />
													<div className="vertical-splitter"></div>
													<Icon name="heart outline" >Add to wishlist</Icon>
												</Grid.Row> */}

														<Grid.Row only="mobile" ></Grid.Row>

														<Grid.Row only="mobile" >
															<Grid.Column width="16">
																<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.description, { USE_PROFILES: { html: true } }) }} />
															</Grid.Column>
														</Grid.Row>

														{
															(this.state.SimilarProducts !== null && this.state.SimilarProducts.length > 0) &&
															<Grid.Row only="mobile">
																<Grid.Column width="16">
																	<div className="similar-products">
																		<span className="title" style={{ fontWeight: "bold" }} >{this.t("similarprod.label")}</span>

																		<div className="horizontal-line1"></div>
																	</div>
																</Grid.Column>
															</Grid.Row>
														}
														<Grid.Row only="computer tablet"></Grid.Row>
													</Grid>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row only="computer tablet">
												<Grid.Column width={16}>
													<div className="product-details">
														<span className="title">{product.name}</span>

														<div className="horizontal-line1"></div>

														<div className="about">
															<span className="head">{this.t("aboutprod.label")}</span>

															<div className="description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.description, { USE_PROFILES: { html: true } }) }} />
														</div>

														<div className="horizontal-line1"></div>

														{/*  <div className="about">
                                                    <span className="head">{this.t("composition.label")}</span>

                                                    <div className="description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.description, { USE_PROFILES: { html: true } }) }} />
                                                </div> */}

														{/* <div className="horizontal-line1"></div>

                                                <div className="about">
                                                    <span className="head">{this.t("howtouse.label")}</span>

                                                    <div className="list">
                                                        <ul>
                                                            <li>Press Side of Cap and Twist cunterclockwise to open</li>
                                                            <li>Squeeze liquid around</li>
                                                            <li>let liquid spread</li>
                                                            <li>Leave for 20 mins</li>
                                                        </ul>
                                                    </div>
                                                </div> */}

													</div>
												</Grid.Column>
											</Grid.Row>
											{(this.state.SimilarProducts !== null &&
												this.state.SimilarProducts.length > 0) && <Grid.Row only="computer tablet">
													<Grid.Column width={16}>
														<div className="similar-products">
															<span className="title">{this.t("similarprod.label")}</span>

														</div>
													</Grid.Column>
												</Grid.Row>}

										</Grid>
									</>

								}
							</div>
							<div>
								<Grid className="listing-container similarproducts">
									<Grid.Row className="three products">
										{this.state.SimilarProducts != null &&
											this.state.SimilarProducts.length > 0
											? this.state.SimilarProducts.map(prod => {
												return (
													<CategoryItem key={prod.code} product={prod} />);
											})
											: ""}
									</Grid.Row>
								</Grid>
							</div>
						</div>
					)}
				</>

			</div>
		);
	}
}
export default withTranslation()(withRouter(Detail));
