import React from "react";
import { Breadcrumb, Button, Grid, Icon, Input, Form, Radio, Dropdown } from "semantic-ui-react";

import TopHeader from "../Home/TopHeader";
import { withTranslation } from "react-i18next";
import { client, profileclient } from "../config";
import gql from "graphql-tag";

import { Context } from "../Context";

import Footer from "../Home/Footer";
import { times } from "lodash";
import moment from "moment";
import { GetDateFormatted, GetTimeFormatted, FromString } from "../Helpers/date";

import Calendar from "../Helpers/Calendar";

class CheckOut extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			CurrentPage: "CHECKOUT",
			COD: "COD",
			UPI: "UPI",
			RadioValue: "COD",
			SelectedId: null,
			isLoading: false,
			address: [],
			cartCount: 0,
			UserProfileData: null,
			UserAddress: [],
			IsNewAddress: false,
			SelectedAddress: null,
			ChckError: "",
			name: "",
			line1: "",
			line2: "",
			street: "",
			area: "",
			landmark: "",
			pincode: "",
			city: "",
			state: "",
			deliveryschedule: false,
			deliveryscheduleslots: [],
			deliverydate: null,
			deliveryselectedSlot: null
		};

		this.t = props.t;
        this.MIN = null;
        this.MAX = null;
        this.MaxDays = 30;
	};

	static contextType = Context;

	componentDidMount() {
		this.getDrugCount();
		this.GetUserProfile();
		// console.log(this.context);
        this.CalculateDate();
	}

	getDrugCount() {
		profileclient.query({
			query: gql`
                query {
                    getOrderCount
                }
            `,
		})
			.then((res) => {
				this.setState({ cartCount: res.data.getOrderCount });

				this.setCartCount(res.data.getOrderCount);
			});
	}

	GetUserProfile() {
		this.setState({ isLoading: true });

		profileclient.query({
			query: gql`
                query
                {
					checkDeliverySlots
                    profile
                    {
                        email
                        phonenumber
                        name
                        address {
                            Id
                            Name
                            Line1
                            Line2
                            Area
                            Street
                            LandMark
                            City
                            State
                            PinCode
                            default
                        }
                    }
                }
            `
		}).then((result) => {

			const profile = result.data.profile;
			const address = profile.address;
			console.log(address);

			if (address !== undefined && address.length === 0) {
				this.setState({ IsNewAddress: true });
			}
			else {
				if(address.length === 1) this.SetSelectedAddress(address[0]);

				for (let i = 0; i < address.length; i++) {
					let addr = address[i];

					if (addr.default) {
						let _address = {
							id: addr.Id,
							name: addr.Name,
							line1: addr.Line1,
							line2: addr.Line2,
							area: addr.Area,
							street: addr.Street,
							landmark: addr.LandMark,
							city: addr.City,
							state: addr.State,
							pincode: addr.PinCode,
							defaultaddress: addr.default,
						};

						this.setState({ SelectedAddress: _address });
					}
				}
			}

			var tomorrow = moment().add(1, "day");

			this.setState({
				UserProfileData: profile,
				UserAddress: address,
				deliveryschedule: result.data.checkDeliverySlots,
				deliverydate: GetDateFormatted(tomorrow)
			});

			if (result.data.checkDeliverySlots)
				this.GetDeliverySlots(this.state.deliverydate);

			this.setState({ isLoading: false });
		}).catch((err) => {
			this.setState({ isLoading: false });
		});
	};

	GetDeliverySlots(date) {
		profileclient.query({
			query: gql`
				query($date:String)
				{ 
					getDeliverySlots(date:$date) {
						id
						name
						time
					}
				}
			`,
			variables: {
				date: date
			}

		}).then(res => {
			// console.log(res);
			this.setState({ deliveryscheduleslots: res.data.getDeliverySlots });

		}).catch(err => { console.log(err) });
	};

	AddressValidation() {
		let errors = {};
		let isvalid = true;
		errors["address"] = "";
		errors.message = "";

		if (this.state.IsNewAddress) {
			if (this.state.name == "" || this.state.name.trim().length == 0) {
				this.setState({ errname: this.t("cartNameErr.label") });
				isvalid = false;
				errors.message += ", ";
			} else {
				this.setState({ errname: "" });
			}
			if (this.state.line1 == "" || this.state.line1.trim().length == 0) {
				this.setState({ errline1: this.t("cartLine1Err.label") });
				errors.message += this.t("cartLine1Err.label") + ", ";
				isvalid = false;
			} else {
				this.setState({ errline1: "" });
			}

			if (this.state.street == "" || this.state.street.trim().length == 0) {
				this.setState({ errStreet: this.t("cartStreetErr.label") });
				errors.message += ", ";
				isvalid = false;
			} else {
				this.setState({ errStreet: "" });
			}

			if (this.state.city == "" || this.state.city.trim().length == 0) {
				this.setState({ errCity: this.t("cartCityErr.label") });
				errors.message += ", ";
				isvalid = false;
			} else {
				this.setState({ errCity: "" });
			}

			if (this.state.state == "" || this.state.state.trim().length == 0) {
				this.setState({ errState: this.t("cartStateErr.label") });
				errors.message += ", ";
				isvalid = false;
			} else {
				this.setState({ errState: "" });
			}

			var regexPincode = /^[1-9][0-9]{5}$/;

			if (this.state.pincode == "") {
				this.setState({ errPincode: this.t("cartPincodeErr.label") });
				errors.message += ", " + this.t("cartPincodeErr.label");
				isvalid = false;
			} else if (!regexPincode.test(this.state.pincode)) {
				this.setState({ errPincode: this.t("cartPincodeValidErr.label") });
				errors.message += ", " + this.t("cartPincodeValidErr.label");
				isvalid = false;
			} else {
				this.setState({ errPincode: "" });
			}
		}

		this.setState({ errors: errors });

		return isvalid;
	};

	CheckOutFunc() {
		const { IsNewAddress, name, line1, line2, area, street, city, landmark, state, pincode, SelectedAddress, SelectedId } = this.state;
		let address = {};

		if (IsNewAddress) {
			address = {
				id: SelectedId,
				name: name,
				line1: line1,
				line2: line2,
				area: area,
				street: street,
				landmark: landmark,
				city: city,
				state: state,
				pincode: pincode,
				defaultaddress: false
			};
		}
		else {
			address = SelectedAddress
		}

		let orderData = this.context.CheckOutData;

		profileclient.mutate({
			mutation: gql`
                mutation($order: OrderInput)
                {
                    insertOrder(order: $order) {
                        id
                        status
                    }
                }
            `, variables:
			{
				order:
				{
					shipto: address,
					items: orderData.items,
					referralcode: orderData.referralcode,
					couponcode: orderData.couponCode,
					rewards: orderData.rewards,
					ordervalue: orderData.ordervalue,
					balance: orderData.totalCharges,
				}
			}
		}).then((res) => {
			//console.log(res);
			this.context.SetCheckOutData(null);
			this.context.SetCartCount(0);
			// this.props.history.push("/");
			const OrderId = res.data.insertOrder.id;
			this.props.ScreenEvnt(3, OrderId);

		}).catch(err => {
			console.log(err);
		});
	};

	OrderCompletePage() {
		var errMsg = '';
		this.setState({ "ChckError": errMsg });


		if (this.state.IsNewAddress) {
			if (this.AddressValidation()) {
				// this.setState({CurrentPage: "ORDERCOMPLETE"});
				this.CheckOutFunc();
			}
		}
		else
		{
			if (this.state.SelectedAddress === null) {

				errMsg += this.t("address.required");
			}
	
	
	
			if (this.state.deliveryschedule) {
				if (this.state.deliverydate === null || this.state.deliveryselectedSlot === null) {
					if (errMsg.length > 0)
						errMsg += " , ";
	
					errMsg += this.t("deliverydateslot.required");
	
				}
			}
	
			this.setState({ "ChckError": errMsg });
	
			if (errMsg.length === 0)
				this.CheckOutFunc();
		}

	};

	SetSelectedAddress(addr) {
		// console.log(addr);
		let _address = {
			id: addr.Id,
			name: addr.Name,
			line1: addr.Line1,
			line2: addr.Line2,
			area: addr.Area,
			street: addr.Street,
			landmark: addr.LandMark,
			city: addr.City,
			state: addr.State,
			pincode: addr.PinCode,
			defaultaddress: addr.default,
		};

		this.setState({ SelectedAddress: _address });
	};

	EditAddress(addr) {
		this.setState({
			SelectedAddress: null, IsNewAddress: true,
			SelectedId: addr.Id,
			name: addr.Name,
			name: addr.Name,
			line1: addr.Line1,
			line2: addr.Line2,
			area: addr.Area,
			street: addr.Street,
			landmark: addr.LandMark,
			city: addr.City,
			state: addr.State,
			pincode: addr.PinCode,
		});
	};

	DeleteAddress(addr) {
		if (addr.Id) {
			let _address = {
				id: addr.Id,
				name: addr.Name,
				line1: addr.Line1,
				line2: addr.Line2,
				area: addr.Area,
				street: addr.Street,
				landmark: addr.LandMark,
				city: addr.City,
				state: addr.State,
				pincode: addr.PinCode,
				defaultaddress: addr.default,
			};

			profileclient.mutate({
				mutation: gql`
                    mutation($ADDRESS: AddressInput)
                    {
                        deleteAddress(address: $ADDRESS)
                        {
                            Id
                            Name
                            Line1
                            Line2
                            Area
                            Street
                            LandMark
                            City
                            State
                            PinCode
                            default
                        }
                    }
                `,
				variables:
				{
					ADDRESS: _address
				}
			}).then((res) => {
				this.GetUserProfile();
			}).catch(err => {
				console.log(err);
			})
		}
	};

	PincodeHandler(pincode) {

		var regex = /^[1-9][0-9]{5}$/;

		if (!regex.test(pincode)) {
			this.setState({
				errPincode: this.t("cartPincodeValidErr.label"),
				state: "",
				city: "",
			});

			return;
		} else {
			this.setState({
				errPincode: "", state: "",
				city: "",
			});
		}

		if (!this.state.state || this.state.state.length == 0) {
			this.LoadStateName(pincode);
		}
	};

	validatePincode(code) {
		var pat1 = /^\d{6}$/;

		return pat1.test(code);
	};

	LoadStateName(pincode) {

		this.setState({
			state: "",
			city: "",
		});
		if (!this.validatePincode(pincode)) return;

		this.GetPincodeDetails(pincode);
	};


	GetPincodeDetails(value) {
		client.query({
			query: gql`
					query($PINCODE: Int)
					{
						GetPincodeDetails(Pincode: $PINCODE)
						{
							taluk
							districtname
							statename
						}
					}
				`,
			variables:
			{
				PINCODE: value
			}
		}).then((result) => {
			let details = result.data.GetPincodeDetails;
			if (details != undefined && details !== null) {
				this.setState({
					city: details.districtname,
					state: details.statename,
					errCity: "",
					errState: ""
				});

			}
		}).catch(err => {
			console.log(err);
		})
	};

	handlecalendar(key, date, format) {
		this.setState({ [key]: date });

		var dateFrm = FromString(date, format);

		this.setState({ deliveryscheduleslots: [], isLoading: true })

        // console.log(dateFrm.toString());
		this.GetDeliverySlots(dateFrm.toISOString());
	};

	handleSlotChange = (e, { value }) => {
		this.setState({
			deliveryselectedSlot: value
		});
	}

    CalculateDate = () =>
    {
        let cd = new Date();
        const endDate = new Date();
        endDate.setDate(cd.getDate() + this.MaxDays - 1);
        this.MIN = cd;
        this.MAX = endDate;
    };

	RenderAddress = () =>
	{
		const { UserAddress, SelectedAddress } = this.state;
		console.log(SelectedAddress);

		if(UserAddress && UserAddress.length > 0)
		{
			return UserAddress.map((addr, index) => {
				return (
					<Grid.Column computer={4} tablet={5}>
						<div className="address" key={index}>
							<p className="name">{addr.Name}</p>
							<p className="addr-line">{addr.Line1} {addr.Line2}</p>
							<p className="addr-line">{addr.Street}, {addr.Area}</p>
							<p className="addr-line">{addr.City}, {addr.State} {addr.Pincode}</p>

							{
								addr.Id === SelectedAddress.id ?
									<Button disabled className="delivery-button" >
										<Icon name="check" />
									</Button>
								:
									<Button className="delivery-button" onClick={() => this.SetSelectedAddress(addr)}>
										Deliver to this address
									</Button>
							}

							<Grid>
								<Grid.Row>
									<Grid.Column width={8}>
										<Button className="addr-button" onClick={() => this.EditAddress(addr)}>Edit</Button>
									</Grid.Column>
									<Grid.Column width={8}>
										<Button className="addr-button" onClick={() => this.DeleteAddress(addr)}>Delete</Button>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</div>

						{/* <div className="horizontal-line1"></div> */}
					</Grid.Column>
				);
			});
		}
	};


	render() {
		const { cartCount, UserAddress, IsNewAddress, CurrentPage } = this.state;
	
		const today = new Date();
		
		const deliverySlotOptions = [];
		this.state.deliveryscheduleslots.map(slot => deliverySlotOptions.push({ key: slot.time, text: slot.name + '-' + GetTimeFormatted(slot.time), value: slot.id }));

		return (
			<div>
				<div className="checkout-container">
					{/* <TopHeader count={cartCount} SearchBar={false} /> */}

					{/* Main Body */}
					<Grid>
						<Grid.Row>
							<Grid.Column width={16}>
								<Breadcrumb className="checkout-mobile">
									<Breadcrumb.Section><label className="bread-label">1.{this.t("shoppingcart.label")}</label></Breadcrumb.Section>
									<Breadcrumb.Divider icon="right angle"></Breadcrumb.Divider>
									<Breadcrumb.Section><label className={"bread-label active"}>2.{this.t("checkout.label")}</label></Breadcrumb.Section>
									<Breadcrumb.Divider icon="right angle"></Breadcrumb.Divider>
									<Breadcrumb.Section><label className={"bread-label"}>3.{this.t("ordercomplete.label")}</label></Breadcrumb.Section>
								</Breadcrumb>
							</Grid.Column>
						</Grid.Row>


						{/* Web and Tablet Code */}
						<div className="seven-container">

							<Grid>
								<Grid.Row only="tablet computer">
									<Grid.Column width={16}>
										{/* <Grid> */}
										<div className="checkout-block-web">
											<Grid.Row>
												<Grid.Column width={16}>
													<span className="checkout-header">{this.t("shippingdetails.label")}</span>
													<div className="horizontal-line1"></div>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row>
												<Grid.Column width={16}>
													<div className="delivery-address">
														<span className="title">{this.t("selectdeleiveryaddress.label")}</span>

														<Grid>
															<Grid.Row>
																{
																	this.RenderAddress()
																}

																<Grid.Column computer={4} tablet={5} className="add-addr-block">
																	<div className="new-address" onClick={() => this.setState({ IsNewAddress: !this.state.IsNewAddress, SelectedId: null })}>
																		<Icon name="plus" />
																	</div>
																</Grid.Column>

															</Grid.Row>
														</Grid>

													</div>
												</Grid.Column>
											</Grid.Row>

                                            {/* <div className="horizontal-line"></div> */}
                                            
                                            {/* New Address Field */}
											{
												IsNewAddress ?
													<Grid className="addr-block">
														<Grid.Row>
															<Grid.Column width={16} className="input-block">
																<label>{this.t("name.label")} *</label>
																<Input name="name" placeholder="Name" className="input-field" value={this.state.name}
																	onChange={(e, { value }) => this.setState({ name: value })}
																/>
																<span className="indiv-error-message">
																	{this.state.errname}
																</span>
															</Grid.Column>
														</Grid.Row>

														<Grid.Row>
															<Grid.Column width={8} className="input-block">
																<label>{this.t("line1.label")} *</label>
																<Input name="line1" placeholder="Line 1" className="input-field" value={this.state.line1}
																	onChange={(e, { value }) => this.setState({ line1: value })}
																/>
																<span className="indiv-error-message">
																	{this.state.errline1}
																</span>
															</Grid.Column>

															<Grid.Column width={8} className="input-block">
																<label>{this.t("line2.label")}</label>
																<Input name="line2" placeholder="Line 2" className="input-field" value={this.state.line2}
																	onChange={(e, { value }) => this.setState({ line2: value })}
																/>
															</Grid.Column>
														</Grid.Row>

														<Grid.Row>
															<Grid.Column width={8} className="input-block">
																<label>{this.t("street.label")} *</label>
																<Input name="street" placeholder="Street" className="input-field" value={this.state.street}
																	onChange={(e, { value }) => this.setState({ street: value })}
																/>
																<span className="indiv-error-message">
																	{this.state.errStreet}
																</span>
															</Grid.Column>

															<Grid.Column width={8} className="input-block">
																<label>{this.t("area.label")}</label>
																<Input name="area" placeholder="Area" className="input-field" value={this.state.area}
																	onChange={(e, { value }) => this.setState({ area: value })}
																/>
															</Grid.Column>
														</Grid.Row>

														<Grid.Row>
															<Grid.Column width={8} className="input-block">
																<label>{this.t("landmark.label")}</label>
																<Input name="landmark" placeholder="Landmark" className="input-field" value={this.state.landmark}
																	onChange={(e, { value }) => this.setState({ landmark: value })}
																/>
															</Grid.Column>
															<Grid.Column width={8} className="input-block">
																<label>{this.t("pincode.label")} *</label>
																<Input name="pincode" placeholder="Pincode" className="input-field" value={this.state.pincode}
																	onChange={(e, { value }) => {
																		this.setState({ pincode: value });
																		this.PincodeHandler(value);
																	}}
																/>
																<span className="indiv-error-message">
																	{this.state.errPincode}
																</span>
															</Grid.Column>
														</Grid.Row>

														<Grid.Row>
															<Grid.Column width={8} className="input-block">
																<label>{this.t("city.label")} *</label>
																<Input name="city" placeholder="City" className="input-field" value={this.state.city}
																	onChange={(e, { value }) => this.setState({ city: value })}
																/>
																<span className="indiv-error-message">
																	{this.state.errCity}
																</span>
															</Grid.Column>

															<Grid.Column width={8} className="input-block">
																<label>{this.t("state.label")} *</label>
																<Input name="state" placeholder="State" className="input-field" value={this.state.state}
																	onChange={(e, { value }) => this.setState({ state: value })}
																/>
																<span className="indiv-error-message">
																	{this.state.errState}
																</span>
															</Grid.Column>
														</Grid.Row>

													</Grid>
                                                :
                                                    null
											}

											{/* <div className="horizontal-line"></div> */}

											{this.state.deliveryschedule === true && <>
												<Grid.Row>
													<Grid.Column width={6}>{this.t("deliverydate.label")}</Grid.Column>
													<Grid.Column width={4}>
														<Calendar
															fluid
															name="deliverydate"
															placeholder="delivery date"
															value={this.state.deliverydate}
															inline
															onChange={this.handlecalendar.bind(this)}
															// minDate={new Date()}
															// maxDate={new Date(today.getFullYear(), today.getMonth(), today.getDay() + 30)}
                                                            minDate={this.MIN}
                                                            maxDate={this.MAX}
														/>
													</Grid.Column>
												</Grid.Row>

												<Grid.Row>
													<Grid.Column width={6}>{this.t("deliveryslot.label")}</Grid.Column>
													<Grid.Column width={4}>
														<Dropdown
															fluid
															selection
															options={deliverySlotOptions}
															onChange={this.handleSlotChange}
														/>
													</Grid.Column>
												</Grid.Row>
											</>}

											

											<Grid.Row>
												<Grid.Column width={16}>
													<span className="error-checkout">{this.state.ChckError}</span>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row>
												<Grid.Column width={16}>
													<Button className="add-address-btn" onClick={this.OrderCompletePage.bind(this)} >Next</Button>
												</Grid.Column>
											</Grid.Row>


										</div>
										{/* </Grid> */}
									</Grid.Column>
								</Grid.Row>
							</Grid>

						</div>

						{/* Mobile Code */}

						<div className="seven-container  mobile-delivery-address">
							<Grid>
								<Grid.Row only="mobile">
									<Grid.Column width={16}>
										<span className="checkout-header">this.t</span>
										<div className="horizontal-line1"></div>
									</Grid.Column>
								</Grid.Row>

								<Grid.Row only="mobile">
									<Grid.Column width={16}>
										<div className="delivery-address">
											<span className="title">SELECT A DELIVERY ADDRESS</span>

											{
												UserAddress !== null && UserAddress.length > 0 ?
													<React.Fragment>

														{
															UserAddress.map((addr, index) => {
																return (
																	<React.Fragment>
																		<div className="address" key={index}>
																			<p className="name">{addr.Name}</p>
																			<p className="addr-line">{addr.Line1} {addr.Line2}</p>
																			<p className="addr-line">{addr.Street}, {addr.Area}</p>
																			<p className="addr-line">{addr.City}, {addr.State} {addr.Pincode}</p>

																			<Button className="delivery-button" onClick={() => this.SetSelectedAddress(addr)}>
																				Deliver to this address
																			</Button>

																			<Grid>
																				<Grid.Row>
																					<Grid.Column width={8}>
																						<Button className="addr-button" onClick={() => this.EditAddress(addr)}>Edit</Button>
																					</Grid.Column>
																					<Grid.Column width={8}>
																						<Button className="addr-button" onClick={() => this.DeleteAddress(addr)}>Delete</Button>
																					</Grid.Column>
																				</Grid.Row>
																			</Grid>
																		</div>

																		<div className="horizontal-line1"></div>
																	</React.Fragment>
																);
															})
														}

													</React.Fragment>

													:
													<div className="noadd">No Address Available</div>
											}

										</div>
									</Grid.Column>
								</Grid.Row>

								<Grid.Row only="mobile addresssection1">
									<Grid.Column>
										<Button className="add-addrbtn mb-20" onClick={() => this.setState({ IsNewAddress: !this.state.IsNewAddress, SelectedId: null })}>
											<Icon name="plus" style={{ margin: 0 }} size="large" />
										</Button>
									</Grid.Column>
								</Grid.Row>

								{
									IsNewAddress ?
										<Grid style={{ marginLeft: "0.1rem", width: "100%" }}>
											<Grid.Row only="mobile">
												<Grid.Column mobile={16} className="input-block">
													<label>{this.t("name.label")} *</label>
													<Input name="name" placeholder="Name" className="input-field" value={this.state.name}
														onChange={(e, { value }) => this.setState({ name: value })}
													/>
													<span className="indiv-error-message">
														{this.state.errname}
													</span>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row only="mobile">
												<Grid.Column mobile={16} className="input-block">
													<label>{this.t("line1.label")} *</label>
													<Input name="line1" placeholder="Line 1" className="input-field" value={this.state.line1}
														onChange={(e, { value }) => this.setState({ line1: value })}
													/>
													<span className="indiv-error-message">
														{this.state.errline1}
													</span>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row only="mobile">
												<Grid.Column mobile={16} className="input-block">
													<label>{this.t("line2.label")}</label>
													<Input name="line2" placeholder="Line 2" className="input-field" value={this.state.line2}
														onChange={(e, { value }) => this.setState({ line2: value })}
													/>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row only="mobile">
												<Grid.Column mobile={16} className="input-block">
													<label>{this.t("street.label")} *</label>
													<Input name="street" placeholder="Street" className="input-field" value={this.state.street}
														onChange={(e, { value }) => this.setState({ street: value })}
													/>
													<span className="indiv-error-message">
														{this.state.errStreet}
													</span>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row only="mobile">
												<Grid.Column mobile={16} className="input-block">
													<label>{this.t("area.label")}</label>
													<Input name="area" placeholder="Area" className="input-field" value={this.state.area}
														onChange={(e, { value }) => this.setState({ area: value })}
													/>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row only="mobile">
												<Grid.Column mobile={16} className="input-block">
													<label>{this.t("landmark.label")}</label>
													<Input name="landmark" placeholder="Landmark" className="input-field" value={this.state.landmark}
														onChange={(e, { value }) => this.setState({ landmark: value })}
													/>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row only="mobile">
												<Grid.Column mobile={16} className="input-block">
													<label>{this.t("pincode.label")} *</label>
													<Input name="pincode" placeholder="Pincode" className="input-field" value={this.state.pincode}
														onChange={(e, { value }) => {
															this.setState({ pincode: value });
															this.PincodeHandler(value);
														}}
													/>
													<span className="indiv-error-message">
														{this.state.errPincode}
													</span>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row only="mobile">
												<Grid.Column mobile={16} className="input-block">
													<label>{this.t("city.label")} *</label>
													<Input name="city" placeholder="City" className="input-field" value={this.state.city}
														onChange={(e, { value }) => this.setState({ city: value })}
													/>
													<span className="indiv-error-message">
														{this.state.errCity}
													</span>
												</Grid.Column>
											</Grid.Row>

											<Grid.Row only="mobile">
												<Grid.Column mobile={16} className="input-block">
													<label>{this.t("state.label")} *</label>
													<Input name="state" placeholder="State" className="input-field" value={this.state.state}
														onChange={(e, { value }) => this.setState({ state: value })}
													/>
													<span className="indiv-error-message">
														{this.state.errState}
													</span>
												</Grid.Column>
											</Grid.Row>

										</Grid>
										:
										null
								}

								<Grid.Row only="mobile mobile-delivery-date">
									<Grid.Column width={6} mobile={16}>{this.t("deliverydate.label")}</Grid.Column>
									<Grid.Column width={8} mobile={16}>
										<Calendar
											fluid
											name="deliverydate"
											placeholder="delivery date"
											value={this.state.deliverydate}
											inline
											onChange={this.handlecalendar.bind(this)}
											minDate={new Date(today.getFullYear(), today.getMonth(), today.getDay())}
											maxDate={new Date(today.getFullYear(), today.getMonth(), today.getDay() + 30)}
										/>
									</Grid.Column>
								</Grid.Row>

								<Grid.Row only="mobile mobile-delivery-date" >
									<Grid.Column width={6} mobile={16}>{this.t("deliveryslot.label")}</Grid.Column>
									<Grid.Column width={10} mobile={16}>
										<Dropdown
											fluid
											selection
											options={deliverySlotOptions}
											onChange={this.handleSlotChange}
										/>
									</Grid.Column>
								</Grid.Row>

								<Grid.Row only="mobile addresssection1">
									<Grid.Column width={16}>
										<Button className="add-address-btn" onClick={this.OrderCompletePage.bind(this)} >Next</Button>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row only="mobile addresssection1">
									<Grid.Column width={16}>
										<span className="error-checkout mb-20">{this.state.ChckError}</span>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</div>

					</Grid>

				</div>
			</div>
		);
	};

	setCartCount(number) {
		this.context.SetCartCount(number);
	}
};

export default withTranslation()(CheckOut);