import { gql } from 'apollo-boost';
import _ from 'lodash'
import React, { Component } from 'react'
import { Search, Form,Button,Icon} from 'semantic-ui-react'

import {client} from "../config";
import { Link, withRouter } from "react-router-dom";


 class SearchProduct extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: false,
      value : '',
      code : '',
      suggestions: [],
    };
  }
  
  componentDidMount() {
    this.resetComponent()
  }
  resetComponent = () => this.setState({ isLoading: false, suggestions: [], value: '' })

  handleResultSelect = (e, { result }) => {
    //  this.setState({ value: result.name, code: result.code })
	this.setState({ value: ""});
    //this.setState({ code: result.code })
   // window.location.href = "/product/" + result.code;
	// console.log(result);
   this.props.history.push("/product/" + result.code);

  }

  handleSearchChange = (e, { value }) => {
    this.resetComponent();

    if ( value.length == 0 )
    {
      this.setState({  value })
      return;
    }
  
    this.setState({ isLoading: true, value })

    const srchQry = client.query({
      query: gql`
            query ($name : String!){
              GetProductTopSearch(name :$name) {
                  code,
                  name
                  }
                }`
      ,
      variables: {
        "name":  value
      }
    }).then
    (suggestions => {
      if (suggestions.data.GetProductTopSearch.length > 0)
      this.setState({
        isLoading: false,
        suggestions: suggestions.data.GetProductTopSearch,
         })
         else 
         this.setState({
          isLoading: false,
          suggestions: [{code:"",name:"No products found", genericvalue:"", type:""}],
           })
     
   }).catch(err=>{
      console.log("Error:"+ err);
   })
}

render() {
  const { isLoading, value, suggestions,code } = this.state
 
  return (
<div className="search">
					{<Form className='search-form'>
          <Search className='search-term' style = { {width : '100%' } }
          loading={isLoading}
          onResultSelect={this.handleResultSelect}
          onSearchChange={this.handleSearchChange}
          results={suggestions}
          value={value}
          showNoResults={false}
          noResultsDescription={"No products found"}
          placeholder="Search Products"
          resultRenderer={resultRenderer}
          {...this.props}
        />

					</Form>}
				</div>



     
  )
}
}
/* 
const resultRenderer = (code, name ,genericvalue,type) => {
  {type =="Pharma"}
  return (
    <div className='product' val={code}> <a href={'/product/'+code}> <div className='name'> {name} - {type} </div> <div className='info'> <strong>Salt: </strong>{genericvalue}</div> </a> </div>
  );
};
 */




//const resultRenderer = ({ code, name }) =><div val={code}>  {name} </div>
//  const resultRenderer = ({ code, name }) =><div val={code}> <Link to={"/product/" + code}>{name}</Link> </div>
 const resultRenderer = ({ code, name }) =><div val={code}> <Link to={"/product/" + code}>{name}</Link> </div>



export default withRouter(SearchProduct)
