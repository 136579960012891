import './i18'
import 'styling/semantic.less'
import 'styling/style.less'
import React, { useContext, useEffect } from 'react'
import TopHeader from './components/Home/TopHeader'
import Footer from './components/Home/Footer'
import { gql } from 'apollo-boost'
import AppContextProvider from './components/Context'

import LayoutHeader from './LayoutHeader'

import {
  /* webpackPreload: true */
  Home,
  Login,
  Register /* webpackPrefetch: true */,
  ProductDetail,

  Cart,
  OrderList,
  BrandListing
  // Listing,
} from './components'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'


const ForgotPassword = React.lazy(() => import( /*webpackPrefetch: true */
'./components/Account/ForgotPassword'));

// import /* webpackPrefetch: true */ ForgotPassword from './components/Account/ForgotPassword'

const ForgotPwdVerify = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Account/ForgotPasswordVerify'));
// import /* webpackPrefetch: true */ ForgotPwdVerify from './components/Account/ForgotPasswordVerify'

const OrderDetail = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Profile/OrdersOld/OrderDetail'));
// import /* webpackPrefetch: true */ OrderDetail from 
// import /* webpackPrefetch: true */ Verification from './components/Account/Verification/Verification'
// import GetAllCategories from './components/Categories';


const GetCategory = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Categories/GetCategory'));
// import /* webpackPrefetch: true */ GetCategory from './components/Categories/GetCategory'

const CategoryListing  = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Categories/CategoryListing'));

// import /* webpackPrefetch: true */ CategoryListing from './components/Categories/CategoryListing'

const CategoryBrandListing  = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Categories/CategoryBrandListing'));

// import /* webpackPrefetch: true */ CategoryBrandListing from './components/Categories/CategoryBrandListing'

const Listing = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Product/Listing/Listing'));

// import /* webpackPrefetch: true */ Listing from './components/Product/Listing/Listing'

const  Profile = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Profile'));

// import /* webpackPrefetch: true */ Profile from './components/Profile'

const  OTPResend = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Account/OTPResend/OTPResend'));

// import /* webpackPrefetch: true */ OTPResend from './components/Account/OTPResend/OTPResend'

const PrivacyPolicy  = React.lazy(() => import( /* webpackPrefetch: true */
'./components/PrivacyPolicy/PrivacyPolicy'));

// import /* webpackPrefetch: true */ PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'

const ReturnsCancelation  = React.lazy(() => import( /* webpackPrefetch: true */
'./components/PrivacyPolicy/ReturnsCancel/ReturnsCancelation'));

// import /* webpackPrefetch: true */ ReturnsCancelation from './components/PrivacyPolicy/ReturnsCancel/ReturnsCancelation'

const   Aboutus = React.lazy(() => import( /* webpackPrefetch: true */
'./components/PrivacyPolicy/Aboutus/AboutUs'));

// import /* webpackPrefetch: true */ Aboutus from './components/PrivacyPolicy/Aboutus/AboutUs'
const  TermsConditions = React.lazy(() => import( /* webpackPrefetch: true */
'./components/PrivacyPolicy/TermsCons/TermsConditions'));

// import /* webpackPrefetch: true */ TermsConditions from './components/PrivacyPolicy/TermsCons/TermsConditions'

const  FAQPage = React.lazy(() => import( /* webpackPrefetch: true */
'./components/PrivacyPolicy/FAQ/FAQPage'));

// import /* webpackPrefetch: true */ FAQPage from './components/PrivacyPolicy/FAQ/FAQPage'


const OrderPayment  = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Payment/OrderPayment'));
// import /* webpackPrefetch: true */ OrderPayment from './components/Payment/OrderPayment'

const  Notification = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Profile/Notification'));

// import Notification from './components/Profile/Notification'

import { client } from './components/config'

import { store } from '../src/store'

const LandingPage =  React.lazy(() => import( /* webpackPrefetch: true */ 
'./components/Home/Landing/Landing'));

// import LandingPage from './components/Home/Landing/Landing'

const  CheckOut = React.lazy(() => import( /* webpackPrefetch: true */
'./components/Cart/CheckOut'));

//import CheckOut from './components/Cart/CheckOut'

const App = () => {
  const { dispatch } = useContext(store)

  // var lang = navigator.language || navigator.userLanguage;

  // var websiteDetails = null;
  var sitedetailsStore = sessionStorage.getItem('sitedetails')
  if (sitedetailsStore === null) {
    client
      .query({
        query: gql`
          query {
            getWebsiteDetails {
              logo
              icon
              locale
              currency
              dateformat
              name
              title
            }
            getimagePrefixURL
          }
        `
      })
      .then((res) => {
        // console.log("website Details", JSON.stringify( res.data.getWebsiteDetails));
        // debugger;
        var imgPrefix = res.data.getimagePrefixURL

        localStorage.setItem('imgurl', imgPrefix)

        loadTheme(res.data.getWebsiteDetails)
        sessionStorage.setItem(
          'sitedetails',
          JSON.stringify(res.data.getWebsiteDetails)
        )

        //
      })
      .catch((err) => {
        console.log('error', err)
        window.location.url = '/404'
      })
  }

  function loadTheme (sitedetails) {
    /*  if (sitedetails && sitedetails.theme) {
      if (sitedetails.locale.indexOf("ar", 0) > 0)
        sitedetails.theme["direction"] = "rtl";
      else sitedetails.theme["direction"] = "ltr";

      setTheme(sitedetails.theme);
    } */

    // debugger;

    dispatch({ type: 'sitedetails', state: sitedetails })
  }

  // eslint-disable-next-line no-unused-vars
  function setTheme (theme) {
    // Object.Remove(theme)

    delete theme.__typename

    Object.keys(theme).map((key) => {
      const value = theme[key]
      document.documentElement.style.setProperty('--theme-' + key, value)
    })
  }

  function getFormat () {
    client.query({
      query: gql`
          {
            getCurrency,
            getDateFormat
          }
        `
    }).then(result => {
      localStorage.setItem('dateformat', result.data.getDateFormat)
      localStorage.setItem('currency', result.data.getCurrency)
    }).catch(e => { })
  }

  useEffect(() => {
    getFormat()
    GetSiteDetails()
  }, [])

  function GetSiteDetails () {
    client.query({
      query: gql`
				  query
				  {
				    getWebsiteDetails {
						  title
						  name
						  shortname
					  }
				  }
			`
		}).then(res => {
			const data = res.data.getWebsiteDetails;
			localStorage.setItem("title", data.title);
            document.getElementById('sitetitle').innerText = data.title
		})
	};

  return (
    <React.Suspense fallback={ <div>Loading…</div> }>
      <AppContextProvider>
        {/* <TopHeader>
      </TopHeader> */}
        {/* forceRefresh={true} */}
        <Router>
          <>
            <Switch>

              <>
                <LayoutHeader><TopHeader></TopHeader> </LayoutHeader>
                <div className="content-container">
                  <Route exact path="/">
                    <LandingPage></LandingPage>
                  </Route>
                  <Route path="/notification/:id" component={Notification} />
                  <Route path="/notification" component={Notification} />
                  <Route exact path="/detail/:id" component={ProductDetail} />
                  <Route exact path="/products/brand/:id" component={BrandListing} />
                  <Route exact path="/products/category/:id" component={CategoryListing} />
                  <Route exact path="/products/categories/:id" component={CategoryBrandListing} />
                  <Route exact path="/products/group/:id" component={Listing} />
                  <Route path="/GetCategory" component={GetCategory} />
                  <Route exact path="/product/:id" component={ProductDetail} />
                  <Route exact path="/products" component={Home} />
                  <Route path="/cart" component={Cart} />
                  <Route path="/profile" component={Profile} />
                  <Route path="/orderList" component={OrderList} />
                  <Route path="/orderdetail" component={OrderDetail} />
                  <Route path="/payment/:id" component={OrderPayment} />
                  <Route path="/checkout" component={CheckOut} />
                  <Route exact path="/termscons" component={TermsConditions} />
                </div>
                <LayoutHeader><Footer></Footer></LayoutHeader>

              </>
            </Switch>

            <Switch>
              <>
                <Route exact path="/login" component={Login} />
                <Route path="/forgotpassword" component={ForgotPassword} />
                <Route path="/forgotpasswordverify" component={ForgotPwdVerify} />
                <Route path="/OTPResend" component={OTPResend} />
                <Route exact path="/register" component={Register} />
                {/* <Route exact path="/verify" component={Verification} /> */}
                <Route path="/aboutus" component={Aboutus} />
                <Route path="/privacypolicy" component={PrivacyPolicy} />
                <Route path="/returnscancel" component={ReturnsCancelation} />
                <Route path="/faq" component={FAQPage} />{/*
              <Route path="/landing" component={LandingPage} /> */}
              </>
            </Switch>

          </>
        </Router>
      </AppContextProvider>
    </React.Suspense>
  )
}

export default App
