import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Container, Header, Segment, Image, Button, Icon } from 'semantic-ui-react'
import { gql } from 'apollo-boost';
import { config, profileclient } from '../../config'
import { Link, withRouter } from 'react-router-dom'
import { Round } from '../../Helpers';
import noimg from 'resources/no-image.png';
import { withTranslation } from 'react-i18next';

class BrandItem extends Component {


  static propTypes = {
  }

  constructor(props) {
    super(props);

    this.state = {
      msg: '',
      imgurlprefix: localStorage.getItem("imgurl"),
      drgcnt: 0
    }
    this.t = this.props.t;
    // console.log("props:"+  JSON.stringify( this.props.drug));
  }


  render() {

    const drg = this.props.drug;

    return (

      <Grid.Column mobile={16} computer={5} className='item'>
        <Segment className='info'>

          <div key={drg.code}>
            {drg.image != null && <Image src={this.state.imgurlprefix + drg.image[0]} className='' />}
            {drg.image == null && <Image src={noimg} width={200} heigth={200} />}
            {/* <Image src={productImageOne} /> */}

            {/* <a href={'/Product/' + drg.code}> */}
            <Link to={'/product/' + drg.code}>
              <div className='product-name'>{drg.name} {/* {drg.code} */}</div>
            </Link>
            {/* </a> */}
            <div className='product-pack'>{drg.unit}</div>
            <div className='price'>
              <span className='item-price'> {Round(drg.discountedprice, 2)}</span>
              <span className='mrp-value'>{Round(drg.mrpprice, 2)}</span>
              <span className='off'> {drg.offerpercentage}% off </span>
            </div>
            <div>

            </div>
            <div className='add-cart-button'>


              <a href='#' role="button" onClick={(e) => this.AddToCart(e, drg.code)}>
                <Icon name='cart' />
                <span> {this.t("addToCart.label")}</span>
              </a>

              <div className='delivery'>
                <span className='general-message'> {this.state.msg} </span>
              </div>
            </div>


          </div>

        </Segment>
      </Grid.Column>
    );

  }


  AddToCart = (e, id) => {

    e.preventDefault();
    try {

      let tokenId = localStorage.getItem("token");

      if (typeof (tokenId) == 'undefined' || tokenId == null) {
        localStorage.setItem("path", window.location.href);

        this.props.history.push("/login");
      }
      if (tokenId) {  //insert to table

        const result = profileclient.mutate({
          mutation: gql`
                   mutation($cart:CartInput)
                   {
                     insertCart(cart:$cart)
                   }
                   `,
          variables: {
            "cart": {
              "productcode": id,
              "qty": 1,
              "price": 0.00,
              "mrpprice": 0.00,
            }
          }
        }).then(resp => {
          this.setState({
            isButtonDisabled: 1,
            msg: 'Added to Cart',
          });
          this.props.drugCount(resp.data.insertCart)
        })
      }

      e.preventDefault();


    } catch (err) {
      console.log(err.message)
    }
  }

}
export default withTranslation()(withRouter(BrandItem));