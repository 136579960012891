import React, { useState, useEffect, useContext } from "react";
import { Grid, Image, Button } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GetDateFormatted } from "../../Helpers/date";

import noimg from "resources/no-image.png";
import { Context } from "../../Context";
import { Round } from "../../Helpers";
import { profileclient, client } from "../../config";

import gql from "graphql-tag";

const ReturnOrderFlags = ["OutForDelivery", "Delivered", "Cancelled", "Returned", "ReturnInit", "ReturnAssigned", "ReturnPickup", "ReturnCompleted", "ReturnAccepted"];


function OrderItem(props) {

    const [Order, setOrder] = useState([]);
    const [ReturnType, setReturnType] = useState("N/A");
    const [Items, setItems] = useState([]);

    const t = props.t;
    const imgPrefix = localStorage.getItem("imgurl") + "/images/product";
    const context = useContext(Context);

    useEffect(() => {
        if (props.order !== undefined && props.order !== null)
            setOrder(props.order);

        if (props.returnType !== undefined && props.returnType !== null)
            setReturnType(props.returnType);

        if (props.type !== undefined && props.type !== null) {
            const type = props.type;

            if (type === "ReturnRequest") {
                setItems(props.order.return.items);
            }
            // else if(type === "Return")
            // {
            //     setItems(props.order.returnOrders.items);
            // }
            // else if(type === "Replace")
            // {
            //     setItems(props.order.replaceOrders.items);
            // }
            else {
                setItems(props.order.items);
            }

            // console.log(props.order);
        }

        GetWebsiteDetails();

    }, []);

    function GetWebsiteDetails() {
        client.query({
            query: gql`
                query
                {
                    getWebsiteDetails
                    {
                      title
                      return
                    }
                }
            `
        }).then((res) => {
            const data = res.data.getWebsiteDetails;
            // console.log(data);
            setReturnType(data.return);
        }).catch(console.log);
    };

    function IsClosedOrder(status, options) {
        for (let i = 0; i < options.length; i++) {
            if (options[i] === status) {
                return true;
            }
        }
        return false;
    };

    function CalculateRemainingDays(deliveredDate, returnDays) {
        if ((deliveredDate !== undefined && deliveredDate !== null && deliveredDate !== "") && (returnDays !== undefined && returnDays !== null && returnDays !== "")) {
            const datearr = deliveredDate.split("T")[0].split("-");
            const Ddate = new Date(datearr[0], datearr[1] - 1, datearr[2]);
            const TodayDate = new Date();
            const diff = Math.trunc((TodayDate - Ddate) / (1000 * 60 * 60 * 24)) + 1;
            if (diff >= 0)
                return parseInt(returnDays) - diff;
        }

        return -1;
    };

    function IsReturnDays(deliveredDate, returnDays) {
        const remaining = CalculateRemainingDays(deliveredDate, returnDays);

        return remaining >= 0;
    };

    function GenerateDate(deliveredDate, returnDays) {
        const days = CalculateRemainingDays(deliveredDate, returnDays);

        if (days >= 0) {
            const _calDate = new Date(new Date().getTime() + (days * 24 * 60 * 60 * 1000));
            return _calDate;
        }

        return null;
    };

    function CancelOrder(order) {
        if (props.cancelOrder) {
            props.cancelOrder(order);
        }
    }

    function ModalFunc(order, item, flag, replace) {
        if (props.modalState) {
            props.modalState(order, item, flag, replace);
        }
    };

    function AddToCart(item) {
        if (item) {
            profileclient.mutate({
                mutation: gql`
                    mutation($ITEM: CartInput)
                    {
                        insertCart(cart: $ITEM)
                    }
                `,
                variables:
                {
                    ITEM: {
                        productcode: item.productcode,
                        qty: 1.0,
                        price: parseFloat(item.price),
                        mrpprice: parseFloat(item.mrpprice)
                    }
                }
            }).then(resp => {
                context.SetCartCount(resp.data.insertCart);
            });
        }
    };

    function RenderItems() {
        let _Items = [];
        if (Order !== undefined && Items !== undefined && Items !== null && Items.length > 0) {

            return Items.map((item, index) => {
                return (
                    <Grid key={index} style={{ margin: 0, marginBottom: "1.5rem", marginTop: "1.5rem" }}>
                        <Grid.Row>
                            <Grid.Column computer={3} tablet={3} mobile={8} >
                                <Link to={"/product/" + item.productcode} target="_blank">
                                    {
                                        item.image && item.image !== "" ?
                                            <img src={imgPrefix + item.image} computer="100" height="100" />
                                            :
                                            <Image src={noimg} className="big" style={{ width: 100, height: 100 }} />
                                    }
                                </Link>
                            </Grid.Column>

                            <Grid.Column computer={4} tablet={4} mobile={8} style={{ paddingLeft: "1.5rem" }}>
                                <div className="left-col">
                                    <div className="p_name">
                                        <Link to={"/product/" + item.productcode} target="_blank" style={{ color: "#000000" }} >
                                            {item.productname}
                                        </Link>
                                    </div>
                                    <div className="return_txt">
                                        {
                                            IsReturnDays(Order.delivereddate, item.return) && (
                                                t("returnwindowcloseon.label") + " : " + GetDateFormatted(GenerateDate(Order.delivereddate, item.return))
                                            )
                                        }
                                    </div>
                                    <div className="indiv-error-message">
                                        {item.error}
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column computer={1} tablet={3} mobile={5} style={{ paddingBottom: "0.625rem" }}>
                                <div style={{ textAlign: "right" }}>{item.qty}</div>
                            </Grid.Column>

                            <Grid.Column computer={2} tablet={3} mobile={5} style={{ paddingBottom: "0.625rem" }}>
                                <div style={{ textAlign: "right" }}>{Round(item.price, 2)}</div>
                            </Grid.Column>

                            <Grid.Column computer={2} tablet={3} mobile={5} style={{ paddingBottom: "0.625rem" }}>
                                <div style={{ textAlign: "right" }}>{Round(item.qty * item.price)}</div>
                            </Grid.Column>

                            {
                                !props.readonly && (
                                    <Grid.Column computer={4} tablet={16} mobile={16}>
                                        <div className="right-col">
                                            {
                                                !IsClosedOrder(Order.status, ReturnOrderFlags) && (
                                                    <Button className="ord_btn"
                                                        size="mini"
                                                        onClick={() => CancelOrder(Order)}
                                                    >
                                                        {t("cancel.label")}
                                                    </Button>
                                                )
                                            }

                                            {
                                                (ReturnType !== "N/A" && IsReturnDays(Order.delivereddate, item.return)) ?
                                                    (Order.status === "Delivered" || Order.status === "ReturnInit") &&

                                                    <Button.Group style={{ width: "100%" }} >
                                                        <Button
                                                            onClick={() => ModalFunc(Order, item, true)}
                                                            style={{ backgroundColor: "#e0e1e2", color: "rgba(0,0,0,.6)" }}
                                                        >
                                                            {t("cancel.label")}
                                                        </Button>

                                                        <Button.Or />

                                                        <Button
                                                            onClick={() => ModalFunc(Order, item, true, true)}
                                                        >
                                                            {t("Replace.label")}
                                                        </Button>
                                                    </Button.Group>
                                                    :
                                                    null
                                            }

                                            <Button type="button" className="btn buy_again ord_btn" onClick={() => AddToCart(item)}>
                                                {t("buyagain.label")}
                                            </Button>
                                        </div>
                                    </Grid.Column>
                                )
                            }
                        </Grid.Row>
                    </Grid>
                );
            });

        }
        return _Items;
    };

    return (
        <>
            {RenderItems()}
        </>
    );
};

export default withTranslation()(OrderItem);