import React, { Component } from 'react'
import { Grid, Container, Header, Segment, Icon, Menu, Table, Image } from 'semantic-ui-react'
import gql from 'graphql-tag';
import _map from 'lodash/map';
import { profileclient } from '../../config'

import momment from 'moment';

import { withTranslation } from "react-i18next";

import OrderDetail from './OrderDetail';
import { Round } from '../../Helpers';
import { GetDateFormatted } from "../../Helpers/date";

class OrderListOld extends Component {
	constructor(props) {
		super(props);

		this.state = {
			orders: [],
			selectedOrder: null
		}

		this.t = this.props.t;
	}

	componentDidMount() {

		profileclient.query({
			query: gql`
				  query {
					  getFinalOrders {
						id,
						number,
						status,
						
						items {
						  productcode,
						  productname,
						  qty
						  mrpprice
						  price
						},
						ordervalue {
						subtotal
						discount
						shipping
						ordertotal
						}
						shipto {
						Id
						Line1
						Line2
						Area
						Street
						LandMark
						City
						State
						PinCode
						},
						courier,
						
						deliverydate,
						rewards{
							name,
							value
						}
					}
				}
				`,
		}).then(res => {
			if (res.data) {
				console.log(res)
				this.setState({
					orders: res.data.getFinalOrders,
					totalAmount: 0,
					totalQty: 0,
				})
			}
		})
	}

	showOrderDetail = (item = null) => {
		//console.log(history,'===')
		this.setState({
			isOrderDetail: !this.state.isOrderDetail,
			selectedOrder: item
		})
	}

	renderOrderList() {

		if (this.state.orders.length > 0) {


			const orderList = _map(this.state.orders, item => {
				return <Table.Row>
					<Table.Cell textAlign='center'><span className="productDetail" onClick={this.showOrderDetail.bind(this, item)}>{item.number}</span>
						{/* <Image src={ productSmallImage } className='tiny' /> */}</Table.Cell>

					<Table.Cell textAlign='center'>
						{
				/* <p>Subtotal: Rs. {item.ordervalue.subtotal}</p>
			<p>Discount: Rs. {item.ordervalue.discount}</p>
			<p>Shipping Charges: Rs. {item.ordervalue.shipping}</p> */}

						{/* {momment(item.deliverydate).format("DD-MM-YYYY")} */}
						{GetDateFormatted(row.original.deliverydate)}
					</Table.Cell>
					<Table.Cell textAlign='center'>{item.items.length}</Table.Cell>
					<Table.Cell textAlign='center'><span className="item-total">Rs. {Round(item.ordervalue.ordertotal, 2)}</span></Table.Cell>
					<Table.Cell textAlign='center'>
						<p className="company">{item.status}</p>
						{item.status == "Payment" && <a className="ui positive button" href={'/Payment/' + item.id}>Make Payment</a>}

					</Table.Cell>
				</Table.Row>
			});

			return orderList;
		}
		else {
			return <Table.Row>
				<Table.Cell colSpan={6} textAlign='center' >
					<p>{this.t("noorders.label")}</p>
				</Table.Cell>
			</Table.Row>
		}

	}
	render() {

		return (
			<div>
				{!this.state.isOrderDetail ?

					<Segment>
						<Grid>
							<Grid.Row>

							</Grid.Row>
							<Grid.Row>
								<Grid.Column>
									<Header as='h6'>{this.t("yourOrders.label")}</Header>
								</Grid.Column>

							</Grid.Row>
							<Grid.Row>

								<Grid.Column>
									<Table striped>
										<Table.Header>
											<Table.Row textAlign='center'>
												<Table.HeaderCell>{this.t("orderno.label")}</Table.HeaderCell>
												<Table.HeaderCell>{this.t("deliveryDate.label")}</Table.HeaderCell>
												<Table.HeaderCell>{this.t("noofItems.label")}</Table.HeaderCell>
												<Table.HeaderCell>{this.t("amount.label")}</Table.HeaderCell>
												<Table.HeaderCell>{this.t("status.label")}</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{this.renderOrderList()}
										</Table.Body>
									</Table>
								</Grid.Column>
							</Grid.Row>

						</Grid>
					</Segment> :
					<OrderDetail
						item={this.state.selectedOrder}
						showOrderDetail={this.showOrderDetail.bind(this)} />}
			</div>

		)
	}
}

export default withTranslation()(OrderListOld);