function urlBase64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

function initialize () {
  // debugger;
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/sw.js').then(registration => {
        registration.update()

        // console.log('SW registered: ', registration)

        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array('BM8JnNGsrtCH0dlyEJfSepVEe7ckg8dBRoMtRGofgsNHx64F2bKiN14Kx-1mAt_Umb1lQ0FPIl-RUa-lc1p1r4o')
        }

        registration.pushManager.subscribe(subscribeOptions).then(function (pushSubscription) {
          if (sessionStorage) {
            sessionStorage.setItem('sub', JSON.stringify(pushSubscription))
          }
          // console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
          return pushSubscription
        })

        // attachNotificationEvent( registration);
      }).catch(registrationError => {
        console.log('SW registration failed: ', registrationError)
      })
    })
  }
}

function askPermission () {
  return new Promise(function (resolve, reject) {
    const permissionResult = Notification.requestPermission(function (result) {
      resolve(result)
    })

    if (permissionResult) {
      permissionResult.then(resolve, reject)
    }
  }).then(function (permissionResult) {
    if (permissionResult !== 'granted') {
      console.log('We weren\'t granted permission.')
    }
  })
}

if (('PushManager' in window || 'Notification' in window)) {
  askPermission()
}
exports.init = initialize
