import React, {Component, Fragment} from "react";
import _ from "lodash";
import { Icon, Accordion, Checkbox, Card } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

class CategoryMenu extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            ActiveCategory: ""
        };

        this.t = props.t;

        const {Categories, SubCatFilter, HandleSubCategoryChange} = this.props;

        this.HandleCategoryHeaderClick = this.HandleCategoryHeaderClick.bind(this);
        this.HandleSubCategory = this.HandleSubCategory.bind(this);
    }

    HandleCategoryHeaderClick(e, titleProps)
    {
        const {index} = titleProps;
        const newIndex = this.ActiveCategory === index ? "" : index;

        this.setState({ActiveCategory: newIndex});
    };

    HandleSubCategory(e, { checked, label })
    {
        const { SubCatFilter, HandleSubCategoryChange } = this.props;

        let SelectedCat = SubCatFilter;
        if(checked)
        {
            SelectedCat.push(label);
        }
        else
        {
            SelectedCat = _.remove(SubCatFilter, (n) => n != label);
        }

        let category = null;
        category = SelectedCat.join(",");

        HandleSubCategoryChange(SelectedCat, category);

    };

    clearFilters = () =>
    {
        this.props.ClearCat();
    }

    RenderMenu()
    {
        const { Categories } = this.props;
        const { ActiveCategory } = this.state;
        let catRender = [];

        if(Categories !== undefined && Categories !== null && Categories.length > 0)
        {
            catRender = Categories.map((category, index) => {
                return (
                    <Fragment key={category.name}>
                        <Accordion.Title
                            active={ActiveCategory === category.name}
                            index={category.name}
                            onClick={this.HandleCategoryHeaderClick}
                        >
                            <span className="cat-text">{category.name}</span>
                            <Icon
                                name={
                                    ActiveCategory === category.name
                                        ? "minus"
                                        : "plus"
                                }
                                className="cat_icon"
                            />
                        </Accordion.Title>

                        <Accordion.Content
                            active={ActiveCategory === category.name}
                        >
                            {
                                category.subcategories !== null && category.subcategories.length > 0 &&
                                category.subcategories.map((subcat, index) => {
                                    return (
                                        <Card.Content key={subcat.name}>
                                            <Checkbox
                                                label={subcat.name}
                                                id={subcat.name}
                                                onChange={this.HandleSubCategory}
                                            />
                                        </Card.Content>
                                    );
                                })
                            }
                        </Accordion.Content>
                    </Fragment>
                );
            });
        }
        return catRender;
    };

    render()
    {
        return (
            <div className="category-accordian">
                <p className="title">
                    {this.t("categories.label")} 
                    <span className="categoryclear"> 
                        <Icon name="delete" onClick={(e) => {this.clearFilters() }}></Icon> 
                    </span> 
                </p>
    
                <div className="accord-block">
                    <Accordion fluid styled>
                        {this.RenderMenu()}
                    </Accordion>
                </div>
            </div>
        );
    };
};

export default withTranslation()(CategoryMenu);