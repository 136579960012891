import React from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { withTranslation } from "react-i18next";

import '../../../node_modules/semantic-ui-calendar/dist/calendar.min.css'

class Calendar extends React.Component {
    constructor(props) {
        super(props);


        var today = new Date();
        this.state = {
            date: "",
            time: "",
            dateTime: "",
            datesRange: "",
            value: this.props.value,
            dateformat : localStorage.getItem('dateformat'),
            props : {
                
            }
        };
        this.t = this.props.t;
    }

    handleChange = (e, data) => {
        
        //console.log("calendar :", data);
        this.props.onChange( data.name,  data.value, data.dateFormat);
    }

    render() {
        let { name, placeholder, value } = this.props;
        const {dateformat} = this.state;
        return (
            <React.Fragment>
                <DateInput {...this.props}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    iconPosition="left"
                    
                    dateformat={dateformat}
                    onChange={this.handleChange.bind(this)}

                />
            </React.Fragment>
        )
    }
};

export default withTranslation()(Calendar);
