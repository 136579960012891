import React from 'react'
import {
    Grid,
    Header,
    Button,
    Form,
    Image,
    DropDown,
    Checkbox
} from 'semantic-ui-react'

import gql from 'graphql-tag'
import { Link, withRouter } from 'react-router-dom'
import { accountClient, config } from '../../config'
import { withTranslation } from 'react-i18next'

const TypeOptions = [
    {
        key: 'Shop',
        text: 'Shop',
        value: 'Shop'
    }, {
        key: 'Hotel',
        text: 'Hotel',
        value: 'Hotel'
    }, {
        key: 'Catering',
        text: 'Catering',
        value: 'Catering'
    }, {
        key: 'Others',
        text: 'Others',
        value: 'Others'
    }
]

class Register extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {},
            errors: {},
            business: false,
            msgValidate: '',
            Page: "LOGIN"
        }

        // eslint-disable-next-line react/prop-types
        this.t = this.props.t
    }

    handleValidation() { // debugger;
        const fields = this.state.fields
        const errors = {}
        let formIsValid = true

        if (!fields.userName || fields.userName.trim().length === 0) {
            formIsValid = false
            errors.userName = this.t('regUserNameError.label')
        } else {
            var alphanumeric = /^[A-Za-z\'\s\.\,]+$/
            if (!alphanumeric.test(fields.userName)) {
                formIsValid = false
                errors.userName = this.t('regUserNameInvalidError.label')
            }
        }

        if (!fields.phone || fields.phone.trim().length === 0) {
            formIsValid = false
            errors.phone = this.t('error.login.mobileNumber')
        } else {
            var phoneno = /^\d{10}$/
            if (!phoneno.test(fields.phone)) {
                formIsValid = false
                errors.phone = this.t('error.login.mobileNumberInvalid')
            }
        }
        if (!fields.email || fields.email.trim().length === 0) {
            formIsValid = false
            errors.email = this.t('regEmailError.label')
        } else {
            var regemail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (!regemail.test(fields.email)) {
                formIsValid = false
                errors.email = this.t('regEmailErrorInvalid.label')
            }
        }

        /*  if (!fields["email"] || fields["email"].trim().length == 0) {
            formIsValid = false;
            errors["email"] = "Provide EmailId";
        } else{

            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Invalid EmailId";
            }
        } */
        if (!fields.password || fields.password.trim().length === 0) {
            formIsValid = false
            errors.password = this.t('error.login.password')
        } else if (fields.password.trim().length < 6) {
            formIsValid = false
            errors.password = this.t('error.login.passwordLength')
        }

        if (!fields.confirmPassword || fields.confirmPassword.trim().length === 0) {
            formIsValid = false
            errors.confirmPassword = this.t('regConfirmPasswordError.label')
        }

        if (fields.confirmPassword !== fields.password) {
            formIsValid = false
            const matches = fields.password === fields.confirmPassword

            if (!matches) {
                errors.confirmPassword = this.t('regConfirmPasswordErrorInvalid.label')
            }
        }

        if (this.state.business) {

            debugger


            if (!fields.businessName || fields.businessName === null || fields.businessName.trim().length === 0) {
                formIsValid = false
                errors.businessName = this.t('regBusinessNameError.label')
            }

            if (!fields.type || fields.type.trim().length === 0) {
                formIsValid = false
                errors.type = this.t('regTypeError.label')
            }
        }


        this.setState({ errors: errors })
        return formIsValid
    }

    handleChange(field, e) {
        const fields = this.state.fields
        fields[field] = e.target.value
        this.setState({ fields })
    }

    handleDropDownChange(val, key) {
        const fields = this.state.fields
        fields[key] = val
        this.setState({ fields })
    }

    signupForm(e) {
        e.preventDefault()
        if (this.handleValidation()) {
            this.signUpVerification()
        } else { // console.log("Form has errors.")
        }
    }

    signUpVerification = () => {
        accountClient.mutate({
            mutation: gql`
      mutation($signup:SignUpInput)
      {
      signUp(customer:$signup)
     
     }
      `,
            variables: {
                signup: {
                    phonenumber: this.state.fields.phone,
                    password: this.state.fields.password,
                    email: this.state.fields.email,
                    name: this.state.fields.userName,
                    businessName: this.state.fields.businessName ? this.state.fields.businessName : '',
                    gstNo: this.state.fields.gstNo,
                    type: this.state.fields.type
                }
            }
        }).then(t => {
            const phoneNum = this.state.fields.phone
            // localStorage.setItem('payload', t.data.signUp)
            // localStorage.setItem('mobNum', phoneNum)
            let _fields = this.state.fields;
            _fields.otp = "";
            this.setState({ Payload: t.data.signUp, Page: "VERIFY", fields: {..._fields} });
            // this.props.history.push('/verify');
        }).catch(e => {
            var err = e.graphQLErrors
            if (err[0].message === "Customer exists") {
                var errs = ''
                if (err != null & err.length > 0) { // debugger;
                    for (var i = 0; i < err.length; i++) {
                        errs += err[i].message
                    }
                }

                this.setState({ msgValidate: errs });
            }
            else {
                this.setState({ msgValidate: this.t("somethingWrong.error") });
            }
        })
    }

    toggle = () => this.setState({
        business: !this.state.business
    });


    // VERIFY Logics

    HandleOtpValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields["otp"] || fields["otp"].trim().length == 0) {
            formIsValid = false;
            errors["otp"] = this.t("provideotp.val");
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    OTPverify(e) {
        e.preventDefault();
        if (this.handleValidation()) {
            this.VerifyOtp();
        } else {
        }
    }

    VerifyOtp() {
        accountClient.mutate({
            mutation: gql`
                mutation($otpverify:VerifyOTPInput)
                {
                    OTPVerify(otpVerify:$otpverify)
                    {
                        id,
                        number,
                    }
                }
            `,
            variables:
            {
                "otpverify": {
                    "phonenumber": this.state.fields.phone,
                    "OTP": this.state.fields.otp,
                    "payload": this.state.Payload
                }
            }
        }).then(t => {
            localStorage.setItem("token", t.data.OTPVerify.id);
            this.props.history.push("/");
        }).catch(e => {
            var err = e.graphQLErrors;
            var errs = '';
            if (err != null & err.length > 0) {
                for (var i = 0; i < err.length; i++) {
                    errs += err[i].message
                }
            }
            this.setState({ msgValidate: errs });
        });
    };

    ResendOtp = () => {
        accountClient.mutate({
            mutation: gql`
                mutation($RESEND:ResendOTPInput)
                {
                    OTPResend(resend: $RESEND)
                }
            `,
            variables:
            {
                RESEND:
                {
                    phonenumber: this.state.fields.phone
                }
            }
        }).then(res => {
            const payload = res.data.OTPResend;
            this.setState({ Payload: payload });
        }).catch(err => {
            console.log(err);
        })
    };


    RenderOtpVerify() {
        return (
            <Form size="large" style={{ fontFamily: "roboto_medium", marginTop: "12rem" }} onSubmit={this.OTPverify.bind(this)}>
                <Header as="h2" style={{ color: "#1C3448", textAlign: "center" }}>{this.t("otpverify.label")}</Header>

                <Form.Input placeholder={this.t("mobileNumber.label")} className="required" value={this.state.fields.phone} />

                <span className="indiv-error-message"> {this.state.errors["otp"]} </span>
                <Form.Input placeholder={this.t("otp.label")} refs="otp" className="required" value={this.state.fields.otp}
                    onChange={this.handleChange.bind(this, "otp")}
                />

                <div onClick={this.ResendOtp} className="resend-otp">{this.t("resendOtp.label")} ?</div>

                <div style={{ display: "flex", width: "100%", justifyContent: 'center' }}>
                    <Button fluid size='large' type="submit">
                        {this.t("continue.label")}
                    </Button>
                </div>
            </Form>
        );
    };

    RenderLogin() {
        return (
            <Form size='large'
                onSubmit={this.signupForm.bind(this)}
                style={{ fontFamily: 'roboto_medium' }
                }>
                <Header as='h2'
                    style={
                        { color: '#1C3448' }
                    }
                    textAlign='center'>
                    {
                        this.t('register.label')
                    }</Header>




                <Form.Input refs="userName"
                    label={
                        this.t('name.label')
                    }
                    placeholder={
                        this.t('userName.label')
                    }
                    className='required'
                    onChange={
                        this.handleChange.bind(this, 'userName')
                    }
                    value={
                        this.state.fields.userName
                    } />
                <span className="indiv-error-message">
                    {
                        this.state.errors.userName
                    }</span>

                <Form.Input
                    label={
                        this.t('mobileNumber.label')
                    }
                    placeholder={
                        this.t('mobileNumber.label')
                    }
                    refs="phone"
                    className='required'
                    onChange={
                        this.handleChange.bind(this, 'phone')
                    }
                    value={
                        this.state.fields.phone
                    } />
                <span className="indiv-error-message">
                    {
                        this.state.errors.phone
                    }</span>

                <Form.Input
                    label={
                        this.t('email.label')
                    }
                    refs="email"
                    className='required'
                    placeholder={
                        this.t('emailAddress.label')
                    }
                    onChange={
                        this.handleChange.bind(this, 'email')
                    }
                    value={
                        this.state.fields.email
                    } />
                <span className="indiv-error-message">
                    {
                        this.state.errors.email
                    }</span>


                <Form.Input
                    label={
                        this.t('password.label')
                    }
                    type='password'
                    refs="password"
                    placeholder={
                        this.t('password.label')
                    }
                    className='required'
                    onChange={
                        this.handleChange.bind(this, 'password')
                    }
                    value={
                        this.state.fields.password
                    } />
                <span className="indiv-error-message">
                    {
                        this.state.errors.password
                    }</span>


                <Form.Input
                    label={
                        this.t('confirmPassword.label')
                    }
                    type='password'
                    refs="confirmPassword"
                    className='required'
                    placeholder={
                        this.t('confirmPassword.label')
                    }
                    onChange={
                        this.handleChange.bind(this, 'confirmPassword')
                    }
                    value={
                        this.state.fields.confirmPassword
                    } /> {/* <span className="indiv-error-message">{this.state.errors["gst"]}</span> */}
                <span className="indiv-error-message">
                    {
                        this.state.errors.confirmPassword
                    }</span>
                <Grid.Column>
                    <Checkbox label={this.t("businesssignup.label")}
                        onChange={
                            this.toggle
                        }
                        checked={
                            this.state.business
                        } />
                </Grid.Column>
                {
                    this.state.business && <>


                        <Form.Input refs="businessName"
                            label={
                                this.t('businessName.label')
                            }
                            placeholder={
                                this.t('businessName.label')
                            }
                            className='required'
                            onChange={
                                this.handleChange.bind(this, 'businessName')
                            }
                            value={
                                this.state.fields.businessName
                            } />
                        <span className="indiv-error-message">
                            {
                                this.state.errors.businessName
                            }</span>


                        <Form.Input
                             label={
                                    this.t('gstNo.label')
                                }
                            type='text'
                            refs="gstNo"
                            className=''
                            placeholder={
                                this.t('gstNo.label')
                            }
                            onChange={
                                this.handleChange.bind(this, 'gstNo')
                            }
                            value={
                                this.state.fields.gstNo
                            } />


                        <label style={
                            {
                                fontSize: '0.92857143em',
                                fontWeight: 'bold'
                            }
                        }>
                            {
                                this.t('regType.label')
                            }
                            <span style={
                                { color: 'red' }
                            }>*</span>
                        </label>
                        <DropDown style={
                            {
                                marginTop: 5,
                                marginBottom: 16
                            }
                        }
                            // label="Type"
                            selection
                            fluid
                            placeholder="---- Select Type----"
                            className="signdd"
                            onChange={
                                (e, data) => this.handleDropDownChange(data.value, 'type')
                            }
                            value={
                                this.state.fields.type
                            }
                            options={TypeOptions} />
                        <span className="indiv-error-message">
                            {
                                this.state.errors.type
                            }</span>

                    </>
                }



                <div> <Button fluid size='large' type="submit">
                    {
                        this.t('register.label')
                    }</Button></div>
                <p className="register-para-1">{this.t('t&c1.label')} {" "}
                    {/* <a href="/TermsCons" target="_blank">{this.t('t&c2.label')}</a> */}
                    <Link to={'/termscons'}
                        target="_blank">
                        {
                            this.t('t&c2.label')
                        }</Link>
                </p>
                <div className='text center aligned register-para-2'>
                    {
                        this.t('alreadyRegistered.label')
                    }? {/* <a href='/Login'>{this.t('login.label')}</a> */}
                    <Link to={'/Login'}>
                        {
                            this.t('login.label')
                        }</Link>
                </div>

            </Form>
        );
    };

    render() {
        return (
            <div>
                <Grid centered
                    style={
                        { height: '100%' }
                    }
                    className='account-container register-section'>
                    <Grid.Row className="login-left-right">
                        <Grid.Column columns={8} className="loginDiv">
                            <Link to="/">
                                <Image src={
                                    config.apiurl + 'logo'
                                } className="login-logo" />
                            </Link>
                        </Grid.Column>
                        <Grid.Column
                            className='section login loginDiv  login-border'
                        >
                            <div className="error-message"> {this.state.msgValidate} </div>

                            {
                                this.state.Page === "LOGIN"
                                    ?
                                    this.RenderLogin()
                                    :
                                    this.RenderOtpVerify()
                            }

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Register))
